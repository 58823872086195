import { Box, Typography, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext, useEffect, useRef } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { WhiteInput, WhiteLink } from './AppModals';
import FirebaseContext from '../../context/firebase';
import firebase from 'firebase/app';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UserContext from '../../context/user';
import SnackBarContext from '../../context/snackbar';
import ModalContext, { ConfirmationServiceContext } from '../../context/modal';
import { useHistory } from 'react-router-dom';
import useVerifyPhoneNumber from '../../hooks/useVerifyPhoneNumber';
import { SubmitButton } from '../Form';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';
import useHandleError from '../../hooks/useHandleError';

type Props = {
  onVerify?: (credential: firebase.auth.AuthCredential | null) => void;
};
interface MyFormValues {
  number1: string;
  number2: string;
  number3: string;
  number4: string;
  number5: string;
  number6: string;
}

const VerifyMobileModal = (props: Props) => {
  const { setDrawerOpen } = useContext(ModalContext);
  const { firebaseApp, currentUser, reloadCurrentUser } = useContext(FirebaseContext);
  const { user, saveUser } = useContext(UserContext);
  const history = useHistory();
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const { updateUser } = useUserApiRoutes();
  const handleError = useHandleError();

  const handleVerify = async (credential: firebase.auth.AuthCredential | null) => {
    try {
      if (firebaseApp) {
        if (credential) {
          if (currentUser) {
            // we are already signed in so just update the number for the current user
            await currentUser?.updatePhoneNumber(credential);
            await reloadCurrentUser();
          } else {
            await firebaseApp.auth().signInWithCredential(credential);
          }
        }
        const currentFirebaseEmail = currentUser?.email;
        if (user && user.email && user.email !== currentFirebaseEmail) {
          if (currentFirebaseEmail) {
            const res = await showConfirmationModal({
              title: 'Update your email',
              contentText: `You have previously signed up with another email (${currentFirebaseEmail}). Do you wish to update your email to ${user.email}?`,
            });
            if (res && res.confirmed === true) {
              // must pass the idToken to the route as we may have signed in with a new credential
              const idToken = await currentUser?.getIdToken();
              await updateUser({ email: user.email, uid: user?.uid }, idToken);
              saveUser({ email: user.email });
              setOpenId(undefined);
            }
          }
        }
      }
      setDrawerOpen(undefined);
      history.replace('/settings/edit-account');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Box>
      <Typography variant="h4">Verify Mobile #</Typography>
      <VerifyMobileForm onVerify={handleVerify} />
    </Box>
  );
};

export const VerifyMobileForm = (props: Props) => {
  const classes = useStyles();
  const { firebaseApp } = useContext(FirebaseContext);
  const { showSnack } = useContext(SnackBarContext);
  const { user } = useContext(UserContext);
  const { verifyPhoneNumber } = useVerifyPhoneNumber();
  const { onVerify } = props;

  useEffect(() => {
    // resendCode on for open
    if (firebaseApp && user?.phoneNumber) {
      verifyPhoneNumber(user?.phoneNumber, user?.email || undefined).then(() => {
        showSnack('Please check your mobile for your verification code', 'success');
      });
    }
  }, [firebaseApp, showSnack, user?.email, user?.phoneNumber, verifyPhoneNumber]);

  const initialValues: MyFormValues = {
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    number5: '',
    number6: '',
  };
  const inputRefs = useRef<any[]>(Object.keys(initialValues).map(() => undefined)); //init to an array of undefineds

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
      if (!values[k]) {
        errors[k] = 'Required';
      }
    });
    return errors;
  };
  const verify = (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
    if (user?.firebase?.verificationId) {
      const fullCode = Object.values(values).join('');
      const credential = firebase.auth.PhoneAuthProvider.credential(user.firebase.verificationId, fullCode);
      if (onVerify) {
        onVerify(credential);
      }
      resetForm();
      setSubmitting(false);
    }
    // if (user?.firebase?.confirmationResult) {
    //   const fullCode = Object.values(values).join('');
    //   // return firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);

    //   user.firebase.confirmationResult
    //     .confirm(fullCode)
    //     .then(async (result) => {
    //       // User signed in successfully which will trigger the onAuthStateChanged listener at the App.tsx level I.e. no need to do anything with the response
    //       // const user = result.user;
    //       setSubmitting(false);
    //       if (onVerify) {
    //         onVerify(result.credential);
    //       }
    //     })
    //     .catch((error) => {
    //       setSubmitting(false);
    //       showSnack(error, 'error');
    //     });
    // }
  };

  const focusChange = (index: number) => {
    if (index < Object.keys(initialValues).length - 1) {
      if (inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const resendCode = (setSubmitting: (isSubmitting: boolean) => void) => {
    if (firebaseApp && user?.phoneNumber) {
      setSubmitting(true);
      verifyPhoneNumber(user?.phoneNumber, user?.email || undefined)
        .then(() => {
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      showSnack('No mobile number defined', 'error');
    }
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={verify}>
      {({ handleSubmit, isSubmitting, setSubmitting }) => (
        <form onSubmit={handleSubmit}>
          {(Object.keys(initialValues) as Array<keyof typeof initialValues>).map((k, index) => {
            return (
              <WhiteInput
                key={k}
                inputRef={(input) => inputRefs.current.splice(index, 1, input)}
                type="password"
                maxLength={1}
                name={k}
                placeholder="*"
                onInput={() => focusChange(index)}
              />
            );
          })}
          <Box display="flex" marginTop={3}>
            <SubmitButton classes={{ root: classes.marginRight }}>
              {isSubmitting ? null : <ArrowForwardIcon />}
            </SubmitButton>
          </Box>
          <Box marginTop={4} marginBottom={1}>
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <WhiteLink href="#" onClick={() => resendCode(setSubmitting)}>
                Resend VerificationCode
              </WhiteLink>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default VerifyMobileModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: theme.spacing(5),
    },
    marginRight: {
      marginRight: theme.spacing(2.25),
    },
  })
);
