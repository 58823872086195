import { Box, Theme, Divider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ContainerType } from './ContainerPreferencesComponents/ContainerType';
import { ShippingLines } from './ContainerPreferencesComponents/ShippingLines';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
  })
);

const ContainerPreferences = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <ContainerType />
      <Divider />
      <ShippingLines />
    </Box>
  );
};

export default ContainerPreferences;
