import { useContext } from 'react';
import UserContext from '../context/user';
import AppRouterSuperAdmin from './super-admin/AppRouterSuperAdmin';
import AppRouterClient from './client/AppRouterClient'

const AppRouter = () => {
  const { isLoggedIn, user } = useContext(UserContext);
  return (
    (isLoggedIn && user && user.roles?.includes("ADMIN")) 
    ? <AppRouterSuperAdmin /> : <AppRouterClient />);
};

export default AppRouter;
