import { Typography, Box, BoxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

const badgeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 10,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);
export const Badge = ({ text, className, ...otherProps }: { text: string } & BoxProps) => {
  const classes = badgeStyles();
  return (
    <Box className={`${classes.root} ${className}`} {...otherProps}>
      <Typography variant="caption" color="white" fontWeight={500} noWrap>
        {text}
      </Typography>
    </Box>
  );
};
