import { useCallback } from 'react';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';

export default function useAccountsApiRoutes() {
  const publicRoute = usePublicRoute();

  const passwordReset = useCallback(
    async (email: string): Promise<any> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/account/password-reset`,
        data: { email },
      });
      return response;
    },
    [publicRoute]
  );

  const emailVerification = useCallback(
    async (email: string): Promise<any> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/account/email-verification`,
        data: { email },
      });
      return response;
    },
    [publicRoute]
  );

  return { passwordReset, emailVerification };
}
