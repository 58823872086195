import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { InfoField } from './../../components/Containers/InfoField';
import { AccountTypeBadge } from './AccountTypeBadge';
import { BlackButton } from '../Form';
import { useContext } from 'react';
import ModalContext, { ConfirmationServiceContext } from '../../context/modal';
import SnackBarContext from '../../context/snackbar';
import { User } from '../../../types';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      color: theme.palette.info.main,
    },
  })
);

export const ClientInfo = ({ color, user, teammateTotal }: { color?: string; user: User; teammateTotal: number }) => {
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const { setDrawerOpen, setAccountInfo, accountInfo } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const classes = useStyles();
  const { updateUserStatus } = useUserApiRoutes();
  const handleFreezeAccount = async () => {
    const res = await showConfirmationModal({
      title: 'Freeze Account – The Warehouse',
      contentText: 'By freezing this account, the user will be locked from accessing all Virtual Stack member features. Do you wish to proceed ? \n \n (Please remember to send a follow up email to this user ? explaining the circumstances of their freeze)',
    });
    if (res && res.confirmed === true) {
      updateUserStatus('frozen', user._id)
        .then((_user) => {
          const _accountInfo = {
            teammateTotal: accountInfo?.teammateTotal || 0,
            user: _user,
          };
          setAccountInfo(_accountInfo);
          showSnack(`Account ${user.fullName} has been frozen`, 'success');
          setDrawerOpen(undefined);
          setOpenId(undefined);
        })
    }

  };

  return (
    <>
      <Grid columns={16} container justifyContent="space-between" spacing={2} alignItems="center" mb={3}>
        <Grid item xs={9}>
          <Typography className={classes.info} variant="h4">
            {user.companyInformation?.companyName || '-'}
          </Typography>
        </Grid>

        <Grid item xs={7}>

          <Grid container spacing={1} justifyContent="flex-end">
            {user?.accountTypes?.map((accountType, index) => (
              <Grid key={index} item>
                <AccountTypeBadge accountType={accountType} />
              </Grid>
            ))}
          </Grid>

        </Grid>

      </Grid>



      <Grid container spacing={2} color="unset" mb={4}>
        <Grid item xs={4}>
          <InfoField heading="Billing Name" detail={user.companyInformation?.billingName || '-'} />
        </Grid>
        <Grid item xs={4}>
          <InfoField heading="Billing email" detail={user.companyInformation?.billingEmail || '-'} />
        </Grid>
        <Grid item xs={4}>
          <InfoField heading="teammate total" detail={teammateTotal + ' Admin Accounts'} />
        </Grid>
        <Grid item xs={12}>
          <InfoField heading="company address" detail={user.companyInformation?.address || '-'} />
        </Grid>
        <Grid item xs={4}>
          <InfoField heading="Account Owner Name" detail={user.fullName || '-'} />
        </Grid>
        <Grid item xs={4}>
          <InfoField heading="Account email" detail={user.email || '-'} />
        </Grid>
        <Grid item xs={4}>
          <InfoField heading="account Mobile #" detail={user.phoneNumber || '-'} />
        </Grid>
      </Grid>



      <Box display="flex">
        <Button size="large" variant="contained" color="info" sx={{ mr: 2, color: 'white' }}>
          Run Report
        </Button>
        <BlackButton onClick={handleFreezeAccount}>Freeze Account</BlackButton>
      </Box>
    </>
  );
};
