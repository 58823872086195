import { useCallback, useContext } from 'react';
import firebase from 'firebase/app';
import UserContext from '../context/user';
import useHandleError from './useHandleError';
import FirebaseContext from '../context/firebase';

export default function useVerifyPhoneNumber() {
  const { saveUser } = useContext(UserContext);
  const { verifier } = useContext(FirebaseContext);

  const handleError = useHandleError();

  const verifyPhoneNumber = useCallback(
    async (phoneNumber: string, email: string | undefined) => {
      try {
        if (verifier) {
          var provider = new firebase.auth.PhoneAuthProvider();
          const verificationId = await provider.verifyPhoneNumber(phoneNumber, verifier);
          saveUser({
            firebase: { verificationId: verificationId },
            phoneNumber: phoneNumber,
            email: email,
          });
        }
      } catch (error) {
        handleError(error);
        throw error;
      }
    },
    [handleError, saveUser, verifier]
  );
  return { verifyPhoneNumber };
}
