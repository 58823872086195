import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Box,
  TablePagination,
  TableCellProps,
  TableSortLabel,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, ContainerStatus } from '../../../types';
import UserContext from '../../context/user';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import { LocationOption } from '../../pages/Landing/LocationSelector';
import ModalContext from '../../context/modal';
import FirebaseContext from '../../context/firebase';
export type ContainerFilters = {
  assignedUserId?: string;
  userId?: any;
  clientId: string | undefined;
  shippingLineCodes?: string[];
  containerTypeCodes?: string[];
  searchText?: string;
  statuses?: ContainerStatus[];
  availableTo?: { $gt: string };
};

export type SortDirection = 'desc' | 'asc' | undefined;
export type SortOption = { sortField: string; direction: SortDirection };

const ContainerTableContext = React.createContext<{
  sortOptions: SortOption[];
  setSortOptions: React.Dispatch<React.SetStateAction<SortOption[]>>;
}>({
  sortOptions: [],
  setSortOptions: (options) => {},
});

export interface MyFormValues {
  containers: Container[];
}

export const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablePagination: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      borderRadius: 24,
    },
    rowRoot: {
      '& td': {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      '&:nth-of-type(odd)': {
        '& td': {
          backgroundColor: theme.palette.grey[100],
        },
        '& td:first-child': {
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
        },
        '& td:last-child': {
          borderTopRightRadius: 24,
          borderBottomRightRadius: 24,
        },
      },
    },
    textBlur: {
      filter: 'blur(3px)',
    },
  })
);

type ContainerTableProps = ContainerFilters & {
  tableHead: React.ReactNode;
  ContainerRow: React.FunctionComponent<{ container: Container; numberRow?: number }>;
  lastRefeshedAt: string;
  goingTo?: LocationOption;
  initialSortOptions?: SortOption[];
};
const ContainerTable = ({
  assignedUserId,
  userId,
  shippingLineCodes,
  containerTypeCodes,
  statuses,
  clientId,
  searchText,
  tableHead,
  ContainerRow,
  lastRefeshedAt,
  goingTo,
  initialSortOptions,
  availableTo,
}: ContainerTableProps) => {
  const classes = containerStyles();
  const [sortOptions, setSortOptions] = useState<SortOption[]>(initialSortOptions || []);
  const [containers, setContainers] = useState<Container[]>([]);
  const { isLoggedIn } = useContext(UserContext);
  const { firebaseLoaded } = useContext(FirebaseContext);
  const { getContainers } = useContainerApiRoutes();
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { setDrawerOpen } = useContext(ModalContext);

  const loadContainers = useCallback(() => {
    let orFilter: any[] = [];
    if (searchText && searchText.length > 0) {
      orFilter.push({ 'client.address': { $regex: searchText, $options: 'i' } });
      orFilter.push({ referenceId: { $regex: searchText, $options: 'i' } });
      orFilter.push({ containerCode: { $regex: searchText, $options: 'i' } });
    }

    getContainers(
      page,
      itemsPerPage,
      {
        shippingLineCode: shippingLineCodes && shippingLineCodes.length > 0 ? { $in: shippingLineCodes } : undefined,
        containerTypeCode:
          containerTypeCodes && containerTypeCodes.length > 0 ? { $in: containerTypeCodes } : undefined,
        client_id: clientId?.length ? clientId : undefined,
        status: statuses ? { $in: statuses } : undefined,
        assigned_to_user_id: assignedUserId ? assignedUserId : undefined,
        'client.user_id': userId ? userId : undefined,
        availableTo: availableTo || undefined,
        $or: searchText && searchText.length ? orFilter : undefined,
      },
      sortOptions,
      goingTo?.location
    ).then((response) => {
      if (response) {
        setContainers(response.containers);
        setTotalItems(response.totalItems);
      }
    });
  }, [
    getContainers,
    page,
    itemsPerPage,
    shippingLineCodes,
    containerTypeCodes,
    clientId,
    statuses,
    assignedUserId,
    userId,
    searchText,
    sortOptions,
    goingTo?.location,
    availableTo,
  ]);

  useEffect(() => {
    if (firebaseLoaded && !isLoggedIn) {
      setDrawerOpen('login');
    }
    loadContainers();
  }, [firebaseLoaded, isLoggedIn, loadContainers, setDrawerOpen]);

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0);
    setItemsPerPage(Number(value));
  };

  return (
    <ContainerTableContext.Provider value={{ sortOptions, setSortOptions }}>
      <Box display="flex" flexDirection="column" flex={1} minHeight={0}>
        <TableContainer sx={{ flex: 1, minHeight: 0 }}>
          <Table stickyHeader size="small">
            <TableHead>{tableHead}</TableHead>
            <TableBody>
              {containers.map((container, index) => (
                <ContainerRow numberRow={index} key={index} container={container} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{ root: classes.tablePagination }}
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </ContainerTableContext.Provider>
  );
};

export const TableHeader = ({
  label,
  startIcon,
  endIcon,
  sortField,
  children,
  ...otherProps
}: {
  sortField?: string;
} & TableCellContentsProps &
  TableCellProps) => {
  const { sortOptions, setSortOptions } = useContext(ContainerTableContext);
  const sortDirection = sortOptions.find((s) => s.sortField === sortField)?.direction;
  const handleSort = () => {
    if (sortField) {
      setSortOptions(([...curSortOptions]) => {
        const sortOptionIndex = curSortOptions.findIndex((s) => s.sortField === sortField);
        // see why making the copy is necessary here: https://stackoverflow.com/a/49502115/4553162
        const sortOption = sortOptionIndex !== -1 ? { ...curSortOptions[sortOptionIndex] } : undefined;
        if (sortOption) {
          if (sortOption.direction === undefined) {
            sortOption.direction = 'desc';
          } else if (sortOption.direction === 'desc') {
            sortOption.direction = 'asc';
          } else {
            sortOption.direction = undefined;
          }
          // replace with the modified element
          curSortOptions[sortOptionIndex] = sortOption;
        } else {
          // curSortOptions.push({ sortField: sortField, direction: 'desc' });
          // reste the entire sort options rathe than make it  acomplex sort
          curSortOptions = [{ sortField: sortField, direction: 'desc' }];
        }
        // filter out any with an undefined dirction
        return curSortOptions.filter((s) => s.direction);
      });
    }
  };
  return (
    <TableCell sx={{ minWidth: 50 }} size="medium" {...otherProps}>
      {sortField ? (
        <TableSortLabel
          sx={{ display: 'flex' }}
          active={!!sortDirection}
          direction={sortDirection}
          onClick={handleSort}
        >
          <TableCellContents label={label} startIcon={startIcon} endIcon={endIcon}>
            {children}
          </TableCellContents>
        </TableSortLabel>
      ) : (
        <TableCellContents label={label} startIcon={startIcon} endIcon={endIcon}>
          {children}
        </TableCellContents>
      )}
    </TableCell>
  );
};

type TableCellContentsProps = {
  label: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: React.ReactNode;
};
const TableCellContents = ({ startIcon, label, endIcon, children }: TableCellContentsProps) => {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        {startIcon}
        <Typography variant="body2" color="textPrimary" fontWeight={600}>
          {label}
        </Typography>
        {endIcon}
      </Box>
      {children}
    </Box>
  );
};

export default ContainerTable;
