import { Box, Button, Theme, Typography, IconButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import AddIcon from '@mui/icons-material/Add';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useRef } from 'react';
import { AttachedFile, AttachedImage } from '../../types';

type TypeColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;

const squareIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      color: theme.palette.common.white,
      padding: theme.spacing(0.5),
      borderRadius: '6px',
      marginRight: '11px',

      '&.bg-secondary': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&.bg-primary': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);
export const SquareIcon = ({ children, color }: { children: React.ReactNode; color?: string }) => {
  const classes = squareIconStyles();
  return <div className={`${classes.container} ${color ? `bg-${color}` : ''}`}>{children}</div>;
};

const fileUploadStyles = makeStyles((theme: Theme) =>
  createStyles({
    underlined: {
      textDecoration: 'underline',
    },
    imageBox: {
      position: 'relative',
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      display: 'inline-flex',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    iconButton: {
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
    },
    deleteButton: {
      left: theme.spacing(-2.6),
      bottom: 0,
    },
  })
);
type FileUploadProps = {
  text: string;
  name: string;
  color?: TypeColor;
  disabled?: boolean;
};

type FileOrImage = File | AttachedFile | AttachedImage;
function isAttachedImage(toBeDetermined: FileOrImage): toBeDetermined is AttachedImage {
  if ((toBeDetermined as AttachedImage).size) {
    return true;
  }
  return false;
}

const useUploadMethods = (name: string) => {
  const { values, setFieldValue } = useFormikContext();
  const tempFilesAttached: FileOrImage[] = get(values, name, []).filter((f: FileOrImage) => {
    if (!(f instanceof File) && isAttachedImage(f)) {
      return f.size === 'logo';
    } else {
      return true;
    }
  });

  const handleFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const curFiles = get(values, name, []);
    setFieldValue(name, e.target.files ? [...curFiles, ...Array.from(e.target.files)] : curFiles);
  };

  const handleRemove = (index: number, inputRef: React.MutableRefObject<HTMLInputElement | null>) => {
    const tempFilesAttachedId = (tempFilesAttached[index] as any)._id;
    if (tempFilesAttachedId) {
      // this was an uploaded file, so set the deleted flag
      const files = get(values, name, []);
      const fileToDelete = files.find((f: any) => f._id === tempFilesAttachedId);
      if (fileToDelete) {
        const indexesToMarkAsDeleted = files
          .map((f: any, index: number) => (f.origFileName === fileToDelete.origFileName ? index : undefined))
          .filter((o: any) => o !== undefined);
        for (const i of indexesToMarkAsDeleted) {
          setFieldValue(`${name}.${i}._deleted`, true);
        }
      }
    } else {
      // this file has not been uploaded yet, so just remove
      (tempFilesAttached as Array<File | AttachedFile>).splice(index, 1);
      setFieldValue(name, tempFilesAttached);
    }
    if (inputRef.current) {
      // clear out the inputRef
      inputRef.current.value = '';
    }
  };
  return { tempFilesAttached, handleFileUploadChange, handleRemove };
};

export const ImageUpload = ({ name, disabled = false }: Pick<FileUploadProps, 'name' | 'disabled'>) => {
  const { tempFilesAttached, handleFileUploadChange, handleRemove } = useUploadMethods(name);
  const inputRef = useRef(null);

  return (
    <>
      <Button variant="text" color="primary" component="label" disabled={disabled}>
        <input ref={inputRef} style={{ display: 'none' }} type="file" multiple onChange={handleFileUploadChange} />
        <AddToPhotosIcon />
      </Button>
      {tempFilesAttached.map((file: any, index) => {
        const previewUrl = file instanceof File ? URL.createObjectURL(file) : file.signedUrl;
        return file._deleted !== true ? (
          <ImageBox key={index} previewUrl={previewUrl} onRemove={() => handleRemove(index, inputRef)} />
        ) : null;
      })}
    </>
  );
};

export const ImageBox = ({ previewUrl, onRemove }: { previewUrl: string; onRemove: () => void }) => {
  const classes = fileUploadStyles();

  return (
    <Box className={classes.imageBox}>
      <img src={previewUrl} alt="Container" className={classes.image} />
      <IconButton
        size="small"
        color="primary"
        className={`${classes.iconButton} ${classes.deleteButton}`}
        onClick={onRemove}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export const FileUpload = ({ text, name, color }: FileUploadProps) => {
  const classes = fileUploadStyles();
  const { tempFilesAttached, handleFileUploadChange, handleRemove } = useUploadMethods(name);
  const inputRef = useRef(null);

  return (
    <>
      <Button variant="text" color={color ? color : 'primary'} component="label">
        <input ref={inputRef} style={{ display: 'none' }} type="file" multiple onChange={handleFileUploadChange} />
        <SquareIcon color={color ? color : 'primary'}>
          <AddIcon />
        </SquareIcon>
        <Typography py={2} color={color ? color : 'primary'} className={classes.underlined}>
          {text}
        </Typography>
      </Button>
      {tempFilesAttached.map((file: any, index) => {
        return file._deleted !== true ? (
          <RemoveFile
            key={index}
            text={file.name || file.origFileName}
            onRemove={() => handleRemove(index, inputRef)}
          />
        ) : null;
      })}
    </>
  );
};

export const RemoveFile = ({ text, onRemove }: { text: string; onRemove: () => void }) => {
  return (
    <>
      <Box display="flex" flexDirection="row" px={1} alignItems="center">
        <SquareIcon color="primary">
          <DescriptionIcon />
        </SquareIcon>
        <Typography variant="body2" color="textSecondary">
          {text}
        </Typography>
      </Box>
      <Button variant="text" color="primary" onClick={onRemove}>
        <Typography color="error">Remove</Typography>
      </Button>
    </>
  );
};
