import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import startCase from 'lodash/startCase';
import { Badge } from './../Containers/Badge';
import { AccountType } from '../../../types';

export const AccountTypeBadge = ({ accountType = 'importer' }: { accountType: AccountType }) => {
  const theme = useTheme();
  const accountTypeMap = useRef<{ [K in AccountType]: string }>({
    importer: theme.palette.info.dark,
    exporter: theme.palette.primary.dark,
    "freight-forwarder": theme.palette.success.dark
  }).current;
  return (
    <Box display="flex">
      <Badge style={{ backgroundColor: accountTypeMap[accountType] }} text={startCase(accountType)} />
    </Box>
  );
};
