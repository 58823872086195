import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { ThemedTextField } from '../../../components/Form';
import PlacesAutoComplete from '../../../components/PlacesAutoComplete';
import UserContext from '../../../context/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
  })
);

export const CompanyInformation = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const disabled = user?.isTeammate;

  return (
    <>
      <Typography variant="subtitle1" color="primary">
        COMPANY INFORMATION
      </Typography>
      <Box mt={6} mb={3}>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs>
            <ThemedTextField
              fullWidth
              disabled={disabled}
              name="companyInformation.companyName"
              label="Company Name"
            ></ThemedTextField>
          </Grid>
          <Grid item xs>
            <ThemedTextField
              fullWidth
              disabled={disabled}
              name="companyInformation.billingName"
              label="Billing Name"
            ></ThemedTextField>
          </Grid>
          <Grid item xs>
            <ThemedTextField
              fullWidth
              disabled={disabled}
              name="companyInformation.billingEmail"
              type="email"
              label="Billing Email"
            ></ThemedTextField>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={4}>
            <ThemedTextField
              fullWidth
              disabled={disabled}
              name="companyInformation.companyPhone"
              label="Company Phone #"
              placeholder="+64"
            ></ThemedTextField>
          </Grid>
          <Grid item xs={8}>
            <PlacesAutoComplete
              disabled={disabled}
              name="companyInformation.address"
              label="Company Address"
              pointSchemaName="companyInformation.location"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
