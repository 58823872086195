import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ThemedTextField } from '../../../components/Form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
  })
);

export const UserProfile = () => {
  const classes = useStyles();

  return (
    <>
      <Box mb={1}>
        <Typography variant="subtitle1" color="primary">
          YOUR USER PROFILE
        </Typography>
      </Box>
      <Box mt={6} mb={3}>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={4}>
            <ThemedTextField fullWidth name="fullName" label="Full Name"></ThemedTextField>
          </Grid>
          <Grid item xs={4}>
            <ThemedTextField fullWidth name="email" type="email" label="Email"></ThemedTextField>
          </Grid>
          <Grid item xs={4}>
            <ThemedTextField fullWidth name="phoneNumber" label="Mobile #"></ThemedTextField>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={4}>
            <ThemedTextField
              autoComplete="new-password"
              fullWidth
              type="password"
              name="password"
              label="Password"
              helperText="Minimum 8 characters"
            ></ThemedTextField>
          </Grid>
          <Grid item xs={4}>
            <ThemedTextField
              autoComplete="new-password"
              fullWidth
              type="password"
              name="passwordConfirm"
              label="Confirm Password"
            ></ThemedTextField>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
