import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import UserContext from '../../../context/user';
import { User } from '../../../../types';
import { ChipSelector } from './ChipSelector';

export interface MyFormValues extends Required<Pick<User, 'settings'>> {}

export const ContainerType = () => {
  const {
    constants: { containerTypes },
  } = useContext(UserContext);

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography variant="subtitle1" color="primary">
          CONTAINER TYPE
        </Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
        Select your preferred container type for search results.
      </Typography>
      <ChipSelector fieldName="settings.searchPreferences.containerTypeCodes" chips={containerTypes} />
    </Box>
  );
};
