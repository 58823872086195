import { Box, Link, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(2.25),
      paddingBottom: theme.spacing(2.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
      backgroundColor: theme.palette.grey[100],
      justifyContent: 'space-between',
    },
    textBase: {
      fontSize: 10,
      fontFamily: 'Oxygen',
      letterSpacing: '1.5px',
    },
    link: {
      marginLeft: theme.spacing(5),
      color: theme.palette.info.main,
    },
  })
);

const Footer = () => {
  const classes = useStyles();
  const thisYear = new Date().getFullYear();
  return (
    <Box display="flex" flexDirection="row" className={classes.container} alignItems="center">
      <Typography color="textSecondary" className={classes.textBase}>
        © {thisYear} VIRTUAL FREIGHT TECHNOLOGIES | V1.0
      </Typography>
      <Box>
        <Link href="#" underline="hover" className={`${classes.link} ${classes.textBase}`}>
          CONTACT
        </Link>
        <Link href="#" underline="hover" className={`${classes.link} ${classes.textBase}`}>
          PRIVACY
        </Link>
        <Link href="#" underline="hover" className={`${classes.link} ${classes.textBase}`}>
          TERMS & CONDITIONS
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
