import { Box, Grid, IconButton, Link, MenuItem, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';
import React from 'react';
import { ThemedSelect, ThemedTextField } from '../../../components/Form';
import { MyFormValues } from './AddSite';
import { FileUpload } from '../../../components/FileUploads';
import PlacesAutoComplete from '../../../components/PlacesAutoComplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginRight: theme.spacing(2),
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  })
);

const clientTypes = [
  {
    key: 'importer',
    label: 'Importer',
  },
  { key: 'exporter', label: 'Exporter' },
  { key: 'both', label: 'Both' },
];

const Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const ClientForm = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<MyFormValues>();
  const addGridLocation = () => {
    setFieldValue('gridLocations', [...values.gridLocations, { name: '' }]);
  };
  const removeGridLocation = (index: number) => {
    const gridLocations = [...values.gridLocations];
    gridLocations.splice(index, 1);
    setFieldValue('gridLocations', gridLocations);
  };

  return (
    <Box mt={6} mb={3}>
      {values.clientType !== 'user-fineprint' ? (
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={4}>
            <ThemedTextField fullWidth name="companyName" label="Company Name"></ThemedTextField>
          </Grid>
          <Grid item xs={3}>
            <ThemedSelect name="clientType" variant="outlined" label="Site Type">
              {clientTypes.map((clientType) => (
                <MenuItem key={clientType.key} value={clientType.key}>
                  {clientType.label}
                </MenuItem>
              ))}
            </ThemedSelect>
          </Grid>
          <Grid item xs={5}>
            <PlacesAutoComplete name="address" label="Company Pick-up / Drop-off Address" pointSchemaName="location" />
          </Grid>
        </Grid>
      ) : null}

      {values.gridLocations.map((gridLocation, index) => (
        <Grid key={index} container spacing={4} className={classes.container}>
          <Grid item>
            <ThemedTextField
              placeholder="e.g. Gate Name"
              name={`gridLocations.${index}.name`}
              label="Grid Location"
            ></ThemedTextField>
          </Grid>
          <Grid item xs>
            {index === 0 ? (
              <Box display="flex" alignItems="center">
                <IconButton classes={{ root: classes.iconButton }} onClick={addGridLocation} size="large">
                  <AddIcon />
                </IconButton>
                <Link component="button" variant="body1" type="button" onClick={addGridLocation} underline="always">
                  Add Another Grid Location
                </Link>
              </Box>
            ) : (
              <IconButton
                classes={{ root: classes.deleteButton }}
                onClick={() => removeGridLocation(index)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}

      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={4}>
          <ThemedSelect multiple name="daysOperating" variant="outlined" label="Days of Week Site Operates">
            {Days.map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </ThemedSelect>
        </Grid>
        <Grid item xs={2}>
          <ThemedTextField fullWidth name="accessHoursFrom" label="Access Hours From"></ThemedTextField>
        </Grid>
        <Grid item xs={2}>
          <ThemedTextField fullWidth name="accessHoursTo" label="Access Hours To"></ThemedTextField>
        </Grid>
        <Grid item xs={4}>
          <ThemedTextField fullWidth name="containerDoorOptions" label="Container Door Options"></ThemedTextField>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={4}>
          <ThemedTextField
            fullWidth
            name="siteContact"
            label="Site Contact"
            placeholder="e.g Foreman’s Name"
          ></ThemedTextField>
        </Grid>
        <Grid item xs={8}>
          <ThemedTextField
            fullWidth
            name="additionalSiteInstructions"
            label="Additional Site Instructions"
            placeholder="Anything Important We Should Know?"
          ></ThemedTextField>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12}>
          <ThemedTextField
            fullWidth
            name="minimumHealthSafetyRequirements"
            label="Minimum Health And Safety Requirements"
            placeholder="Visitors to the site must meet these minumum requirements"
            multiline
            minRows={4}
          ></ThemedTextField>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={4}>
          <FileUpload name="healthAndSafetyDocuments" text="Add H&S Document (Optional)" />
        </Grid>
        <Grid item xs={4}>
          <FileUpload name="siteInstructionsDocuments" text="Add Site Instructions Document (Optional)" />
        </Grid>
        <Grid item xs={4}>
          <FileUpload name="siteMapDocuments" text="Add Site Map (Optional)" />
        </Grid>
      </Grid>
    </Box>
  );
};
