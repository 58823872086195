import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DataTotalContainerUsed } from '../../../../types';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
      backgroundColor: "white",
      borderRadius: "24px",
      boxShadow:"0px 2px 16px 1px rgba(0, 0, 0, 0.12)"

    },
    textColorInfo: {
      color: theme.palette.info.main,
    },
  })
);

const TotalContainersUsed = ({ dataTotalContainerUsed }: { dataTotalContainerUsed: DataTotalContainerUsed }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={600}>
            Total Containers Used
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            {dataTotalContainerUsed.map((data, index) => (
              <Grid item xs={12} sm={6} md={12} lg={6} key={index}>
                <Typography variant="h2" fontWeight={800} className={classes.textColorInfo}>
                  {data.total}
                </Typography>
                <Typography sx={{ marginLeft: "5px" }} variant="h6" fontWeight={600} className={classes.textColorInfo}>
                  {data.type}’
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TotalContainersUsed;
