import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { SyntheticEvent, useContext, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import ModalContext from '../../context/modal';
import { SubmitButton, CancellationReasonsSelectAsync } from '../Form';
import useUtils from '../../hooks/useUtils';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import SnackBarContext from '../../context/snackbar';
import { FileUpload } from '../FileUploads';

type Props = {};
interface MyFormValues {
  cancellationReason: string;
}

const CancelRequestContainerModal = (props: Props) => {
  const { setDrawerOpen, container, setContainer } = useContext(ModalContext);
  const { getContainerTypeName } = useUtils();
  const classes = useStyles();
  const { cancelRequestContainer } = useContainerApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    const requiredFields: (keyof MyFormValues)[] = ['cancellationReason'];
    for (const field of requiredFields) {
      if (!values[field]) {
        errors[field] = 'Required';
        break;
      }
    }
    return errors;
  };

  const handleSubmit = (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
    if (container?._id) {
      cancelRequestContainer(container?._id, values)
        .then((returnedContainer) => {
          if (returnedContainer.status === 'available') {
            showSnack(
              `Request Cancel Accepted. Container ${returnedContainer.containerCode} in now available to you.`,
              'success'
            );
          } else if (returnedContainer.status === 'cancelled') {
            showSnack(
              `Request Cancel Accepted. Container ${returnedContainer.containerCode} in now cancelled`,
              'warning'
            );
          }
          else {
            showSnack(`Error, container status is ${returnedContainer.status}`, 'error');
          }
          handleCancel();
        })
        .catch(() => setSubmitting(false));
    }
  };
  const handleCancel = () => {
    setDrawerOpen(undefined);
    setContainer(undefined);
  };



  return container ? (
    <Box>
      <Box mb={2.75}>
        <Typography lineHeight={1.235} variant="h4">
          Cancel Request
        </Typography>
        <Typography lineHeight={1.235} variant="h4">
          #{container?.containerCode}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography className={classes.body2} variant="body2">
          Type: <strong>{getContainerTypeName(container)}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2">
          Reference # <strong>{container.referenceId}</strong>
        </Typography>
      </Box>
      <Formik initialValues={{ cancellationReason: '' }} validate={validate} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values }) => {
          const handleSelectChange = (event: SyntheticEvent<Element, Event>, value: any) => {
            if (value !== 'NLR' && value !== '') {
              setShowFileUpload(true);
            } else {
              setShowFileUpload(false);
            }
          }
          return (
            <form onSubmit={handleSubmit}>
              <CancellationReasonsSelectAsync
                variant="outlined"
                name="cancellationReason"
                placeholder="Select"
                label="Reason for Cancellation"
                onChange={handleSelectChange}
              />
              {showFileUpload ? <Box mb={3}>
                <FileUpload
                  color="secondary"
                  name="evidenceOfCancellation"
                  text="Please add Photo Evidence for your Cancellation"
                />
              </Box> : null}
              <SubmitButton color="secondary" sx={{ marginRight: 3, marginTop: 2 }}>
                Submit Cancellation
              </SubmitButton>
            </form>
          )
        }}
      </Formik>
      <Box mt={5}>
        <Typography className={classes.caption}>
          On submit, an email will be sent to a<br />
          VirtualStack™ support team member <br />
        </Typography>
      </Box>
    </Box>
  ) : null;
};

export default CancelRequestContainerModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body2: {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 14,
      color: '#616161',
      fontStyle: 'italic',
    },
  })
);
