import { Box, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MemberAccount from './overview/MemberAccount';
import StackedContainer from './overview/StackedContainer';
import AccountTable from './overview/AccountTable';
import ContainerDisputes from './overview/ContainerDisputes';
import Savings from './overview/Savings';
import {
  Container,
  DataTotalContainerUsed,
  DataComponentchart,
  User,
  DataDoughnutChart,
  ContainerDisputesData,
  SavingData,
  Client,
  AccountData,
} from '../../../types';
import { useCallback, useContext, useEffect, useState } from 'react';
import UserContext from '../../context/user';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';
import TotalContainersUsed from './overview/TotalContainersUsed';
import useClientApiRoutes from '../../hooks/api/useClientApiRoutes';
import useUtils from '../../hooks/useUtils';
import ModalContext from '../../context/modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.grey[100],
      height: '100%',
    },
  })
);

const Overview = () => {
  const classes = useStyles();
  const [containers, setContainers] = useState<Container[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [dataMemberAccount, setdataMemberAccount] = useState<DataComponentchart>({
    total: 0,
    dataDoughnutCharts: [],
  });
  const [dataStackedContainer, setDataStackedContainer] = useState<DataComponentchart>({
    total: 0,
    dataDoughnutCharts: [],
  });
  const [dataTotalContainerUsed, setDataTotalContainerUsed] = useState<DataTotalContainerUsed>([]);
  const [containerDisputesData, setContainerDisputesData] = useState<ContainerDisputesData>({
    unclean: {
      total: 0,
      totalFinesPaid: 0,
    },
    futileTrip: {
      total: 0,
      totalFinesPaid: 0,
    },
  });
  const [savingData, setSavingData] = useState<SavingData>({
    kgO2Saving: {
      lastWeek: 0,
      lastMonth: 0,
    },
    kmSaving: {
      lastWeek: 0,
      lastMonth: 0,
    },
  });
  const [accountTableData, setAccountTableData] = useState<AccountData[]>([]);

  const { isLoggedIn, headerFilter } = useContext(UserContext);
  const { container, accountInfo } = useContext(ModalContext);
  const { getAllContainers } = useContainerApiRoutes();
  const { getAllUsers } = useUserApiRoutes();
  const { getAllClients } = useClientApiRoutes();

  const loadContainers = useCallback(() => {
    let where: any = undefined;
    if (headerFilter && headerFilter.fromDate && headerFilter.toDate) {
      const fromDate = new Date(new Date(headerFilter.fromDate).setHours(0, 0, 0, 0));
      const toDate = new Date(new Date(headerFilter.toDate).setHours(24, 0, 0, 0));
      where = {
        created_at: { $gte: fromDate.toString(), $lte: toDate.toString() },
      };
    }
    if ((headerFilter.fromDate && headerFilter.toDate) || (!headerFilter.fromDate && !headerFilter.toDate)) {
      getAllContainers(where).then((response) => {
        setContainers(response);
      });
    }
  }, [getAllContainers, setContainers, headerFilter]);

  const loadUsers = useCallback(() => {
    let where: any = { 'accountTypes.0': { $exists: true }, roles: { $in: ['OWNER', 'TEAMMATE'] } };
    if (headerFilter.fromDate && headerFilter.toDate) {
      const fromDate = new Date(new Date(headerFilter.fromDate).setHours(0, 0, 0, 0));
      const toDate = new Date(new Date(headerFilter.toDate).setHours(24, 0, 0, 0));
      where = {
        ...where,
        created_at: { $gte: fromDate.toString(), $lte: toDate.toString() },
      };
    }
    if ((headerFilter.fromDate && headerFilter.toDate) || (!headerFilter.fromDate && !headerFilter.toDate)) {
      getAllUsers(where).then((users) => {
        setUsers(users);
      });
    }
  }, [getAllUsers, setUsers, headerFilter]);

  const loadClients = useCallback(() => {
    getAllClients().then((response) => {
      if (response.clients && response.clients.length > 0) {
        setClients(response.clients);
      }
    });
  }, [getAllClients]);

  const getSavingData = (containers: Container[]): SavingData => {
    const today = new Date();
    const sevenDaysBefore = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const thirtyDayBefore = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const containersLastWeek = containers.filter(
      (container) => new Date(container.created_at) >= sevenDaysBefore && new Date(container.created_at) <= today
    );
    const containersLastMonth = containers.filter(
      (container) => new Date(container.created_at) >= thirtyDayBefore && new Date(container.created_at) <= today
    );
    const _savingData: SavingData = {
      kgO2Saving: {
        lastWeek: 0,
        lastMonth: 0,
      },
      kmSaving: {
        lastWeek: 0,
        lastMonth: 0,
      },
    };
    containersLastWeek.forEach((container) => {
      const savingValue = container.environmentalSavings || { kgSaved: 0, kmSaved: 0 };
      _savingData.kgO2Saving.lastWeek += savingValue.kgSaved;
      _savingData.kmSaving.lastWeek += savingValue.kmSaved;
    });

    containersLastMonth.forEach((container) => {
      const savingValue = container.environmentalSavings || { kgSaved: 0, kmSaved: 0 };
      _savingData.kgO2Saving.lastMonth += savingValue.kgSaved;
      _savingData.kmSaving.lastMonth += savingValue.kmSaved;
    });
    return _savingData;
  };

  const getAccountTableData = (users: User[], clients: Client[], containers: Container[]): AccountData[] => {
    const _accountData: AccountData[] = users.map((user) => {
      const clientOfUser = clients.filter((client) => client.user_id === user._id);
      const dataUser: AccountData = {
        user,
        stacked: {
          '40': 0,
          '20': 0,
        },
        assigned: {
          '40': 0,
          '20': 0,
        },
        penalties: 0,
        toTotalPenalty: 0,
        savings: {
          kgO2Saving: 0,
          kmSaving: 0,
        },
        teammateTotal: 0,
      };
      dataUser.teammateTotal = users.filter((u) => u.created_by_user_id === user._id).length;
      let containerStacked: Container[] = [];
      const containersAssigned: Container[] = containers.filter(
        (container) => container.status === 'assigned' && container.assigned_to_user_id === user._id
      );
      clientOfUser.forEach((client) => {
        const containerOfClient = containers.filter((container) => container.client_id === client._id);
        containerStacked = [...containerStacked, ...containerOfClient];
      });
      const containersDispute = containerStacked.filter((container) => container.disputes && container.disputes[0]);

      containersDispute.forEach((container) => {
        container.disputes.forEach((dispute) => {
          dispute.actions?.forEach((action) => {
            if (action.penaltyDollarFee && action.actionType === 'fined') {
              dataUser.penalties += action.penaltyDollarFee;
            }
          });
          dataUser.toTotalPenalty += 1;
        });
      });
      dataUser.stacked['40'] = containerStacked.filter((container) =>
        container.containerTypeCode.includes('40')
      ).length;
      dataUser.stacked['20'] = containerStacked.filter((container) =>
        container.containerTypeCode.includes('20')
      ).length;
      dataUser.assigned['40'] = containersAssigned.filter((container) =>
        container.containerTypeCode.includes('40')
      ).length;
      dataUser.assigned['20'] = containersAssigned.filter((container) =>
        container.containerTypeCode.includes('20')
      ).length;
      [...containerStacked, ...containersAssigned].forEach((container) => {
        const savingValue = container.environmentalSavings || { kgSaved: 0, kmSaved: 0 };
        dataUser.savings.kgO2Saving += savingValue.kgSaved;
        dataUser.savings.kmSaving += savingValue.kmSaved;
      });

      return dataUser;
    });
    return _accountData;
  };

  const getDataMemberAccount = (users: User[]): { total: number; dataDoughnutCharts: DataDoughnutChart[] } => {
    let total = 0;
    users.forEach((user) => {
      if (user.accountTypes && user.accountTypes.length > 0) {
        total += user.accountTypes.length;
      }
    });
    const dataDoughnutCharts: DataDoughnutChart[] = [
      {
        label: 'Importers',
        bgColor: '#00838F',
        valueNumber: users.filter((user) => user.accountTypes?.includes('importer')).length,
        valuePercent:
          total > 0
            ? Math.round((users.filter((user) => user.accountTypes?.includes('importer')).length / total) * 100)
            : 0,
      },
      {
        label: 'Exporters',
        bgColor: '#1DE9B6',
        valueNumber: users.filter((user) => user.accountTypes?.includes('exporter')).length,
        valuePercent:
          total > 0
            ? Math.round((users.filter((user) => user.accountTypes?.includes('exporter')).length / total) * 100)
            : 0,
      },
      {
        label: 'Freight Forwarders',
        bgColor: '#00B0FF',
        valueNumber: users.filter((user) => user.accountTypes?.includes('freight-forwarder')).length,
        valuePercent:
          total > 0
            ? Math.round(
                (users.filter((user) => user.accountTypes?.includes('freight-forwarder')).length / total) * 100
              )
            : 0,
      },
    ];
    return {
      total,
      dataDoughnutCharts,
    };
  };

  const getDataStackedContainer = (containers: Container[]): DataComponentchart => {
    const status = ['available', 'pending', 'assigned'];
    const total = containers.filter((container) => status.includes(container.status)).length;
    const dataDoughnutCharts: DataDoughnutChart[] = [
      {
        label: 'Available',
        bgColor: '#0091EA',
        valueNumber: containers.filter((container) => container.status === 'available').length,
        valuePercent:
          total > 0
            ? Math.round((containers.filter((container) => container.status === 'available').length / total) * 100)
            : 0,
      },
      {
        label: 'Assigned',
        bgColor: '#00BFA5',
        valueNumber: containers.filter((container) => container.status === 'assigned').length,
        valuePercent:
          total > 0
            ? Math.round((containers.filter((container) => container.status === 'assigned').length / total) * 100)
            : 0,
      },
      {
        label: 'Pending',
        bgColor: '#F44336',
        valueNumber: containers.filter((container) => container.status === 'pending').length,
        valuePercent:
          total > 0
            ? Math.round((containers.filter((container) => container.status === 'pending').length / total) * 100)
            : 0,
      },
    ];
    return {
      total,
      dataDoughnutCharts,
    };
  };

  const getContainerDisputesData = (containers: Container[]): ContainerDisputesData => {
    let uncleanTotal = 0;
    let futileTripTotal = 0;
    let uncleanTotalFinesPaid = 0;
    let futileTripTotalFinesPaid = 0;
    containers.forEach((container) => {
      if (container.disputes) {
        container.disputes.forEach((dispute) => {
          const actionRefunded = dispute.actions.filter((act) => act.actionType === 'fined');
          const sumFinesPaid = actionRefunded
            .map((atc) => (atc.penaltyDollarFee ? atc.penaltyDollarFee : 0))
            .reduce((prev, curr) => prev + curr, 0);
          if (dispute.disputeType === 'unclean') {
            uncleanTotal += 1;
            uncleanTotalFinesPaid += sumFinesPaid;
          }
          if (dispute.disputeType === 'futile-trip') {
            futileTripTotal += 1;
            futileTripTotalFinesPaid += sumFinesPaid;
          }
        });
      }
    });
    return {
      unclean: {
        total: uncleanTotal,
        totalFinesPaid: uncleanTotalFinesPaid,
      },
      futileTrip: {
        total: futileTripTotal,
        totalFinesPaid: futileTripTotalFinesPaid,
      },
    };
  };

  const getDataTotalContainerUsed = (containers: Container[]): DataTotalContainerUsed => {
    const _dataTotalContainerUsed = [
      {
        type: '20',
        total: 0,
      },
      {
        type: '40',
        total: 0,
      },
    ];
    return _dataTotalContainerUsed.map((data) => ({
      type: data.type,
      total: containers.filter(
        (container) => container.containerTypeCode.includes(data.type) && container.status === 'used'
      ).length,
    }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      loadContainers();
      loadUsers();
      loadClients();
    }
  }, [isLoggedIn, loadContainers, loadUsers, loadClients]);

  useEffect(() => {
    if (container) {
      const containerIndex = containers.findIndex((c) => c._id === container._id);
      const _containers = [...containers];
      _containers[containerIndex] = container;
      setContainers(_containers);
    }
  }, [container, setContainers]);

  useEffect(() => {
    if (accountInfo) {
      const userIndex = users.findIndex((u) => u._id === accountInfo.user._id);
      const _users = [...users];
      _users[userIndex] = accountInfo.user;
      setUsers(_users);
    }
  }, [accountInfo, setUsers]);

  // useEffect(() => {
  //   if (headerFilter && headerFilter?.fromDate && headerFilter?.toDate) {
  //     loadUsers();
  //     console.log("check:: ", headerFilter)
  //   }
  // }, [headerFilter, loadUsers]);

  useEffect(() => {
    const _dataStackedContainer = getDataStackedContainer(containers);
    setDataStackedContainer(_dataStackedContainer);

    const _dataTotalContainerUsed = getDataTotalContainerUsed(containers);
    setDataTotalContainerUsed(_dataTotalContainerUsed);

    const _containerDisputesData = getContainerDisputesData(containers);
    setContainerDisputesData(_containerDisputesData);
  }, [containers, setDataStackedContainer, setDataTotalContainerUsed, setContainerDisputesData]);

  useEffect(() => {
    const _savingData = getSavingData(containers);
    setSavingData(_savingData);
  }, [containers, clients, setSavingData]);

  useEffect(() => {
    const _dataMemberAccount = getDataMemberAccount(users);
    setdataMemberAccount(_dataMemberAccount);
    const _accountTableData = getAccountTableData(users, clients, containers);
    setAccountTableData(_accountTableData);
  }, [users, clients, containers, setdataMemberAccount, setAccountTableData]);

  return (
    <Box className={classes.container}>
      <Grid direction="row" container spacing={3} alignItems="flex-end" justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <MemberAccount dataMemberAccount={dataMemberAccount} />
        </Grid>
        <Grid item xs={12} md={4}>
          <StackedContainer dataStackedContainer={dataStackedContainer} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TotalContainersUsed dataTotalContainerUsed={dataTotalContainerUsed} />
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: '4px' }} container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={8}>
          <AccountTable accountTableData={accountTableData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContainerDisputes containerDisputesData={containerDisputesData} />
          <Savings savingData={savingData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overview;
