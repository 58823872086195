import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useRef } from 'react';
import startCase from 'lodash/startCase';
import { Badge } from './Badge';
import { ContainerStatus } from '../../../types';

export const StatusBadge = ({ status = 'available' }: { status: ContainerStatus }) => {
  const theme = useTheme();
  const statusMap = useRef<{ [K in ContainerStatus]: string }>({
    pending: theme.palette.warning.main,
    assigned: theme.palette.secondary.main,
    internalUse: theme.palette.primary.light,
    available: theme.palette.primary.main,
    requestDeclined: theme.palette.snackbar.main,
    dispute: theme.palette.snackbar.main,
    used: theme.palette.info.main,
    cancelled: theme.palette.error.main,
    timesUp: theme.palette.common.black,
  }).current;
  return (
    <Box display="flex">
      <Badge style={{ backgroundColor: statusMap[status] }} text={startCase(status)} />
    </Box>
  );
};
