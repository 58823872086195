import { useCallback, useContext } from 'react';
import SnackBarContext from '../context/snackbar';

export default function useHandleError() {
  const { showSnack } = useContext(SnackBarContext);

  const handleError = useCallback(
    (error: any) => {
      let errorText;
      console.log('handleError: ', error);
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          errorText = error.response.data.message;
        } else {
          errorText = error.response.data;
        }
      } else if (error.message) {
        errorText = error.message;
      } else {
        errorText = JSON.stringify(error);
      }
      showSnack(`Error Loading data: ${errorText}`, 'error');
      throw error;
    },
    [showSnack]
  );
  return handleError;
}
