import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { WhiteCheckbox, WhiteInput, WhiteLink } from './AppModals';
import FirebaseContext from '../../context/firebase';
import ModalContext from '../../context/modal';
import useValidators from '../../hooks/useValidators';
import { SubmitButton } from '../Form';
import { Role, User } from '../../../types';
import useHandleError from '../../hooks/useHandleError';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';

interface MyFormValues extends Required<Pick<User, 'fullName' | 'email' | 'phoneNumber' | 'password'>> {
  passwordConfirm: string;
  acceptTermsAndConditions: boolean;
}

const SignUpModal = () => {
  const classes = useStyles();
  const { setDrawerOpen } = useContext(ModalContext);
  const { firebaseApp } = useContext(FirebaseContext);
  const { createUser } = useUserApiRoutes();
  const handleError = useHandleError();
  const { isEmailValid, validatePasswords } = useValidators();
  const [signUpRole, setSignUpRole] = useState<Role>('OWNER');

  useEffect(() => {
    if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
      firebaseApp.auth().signOut();
      setSignUpRole('TEAMMATE');
    }
  }, [firebaseApp, setSignUpRole]);

  const initialValues: MyFormValues = {
    fullName: '',
    email: '',
    phoneNumber: '64',
    password: '',
    passwordConfirm: '',
    acceptTermsAndConditions: false,
  };
  const validate = (values: MyFormValues) => {
    const errors: { [Property in keyof Partial<MyFormValues>]: string } = { ...validatePasswords(values) };
    for (const key of Object.keys(values) as (keyof MyFormValues)[]) {
      if (!values[key]) {
        errors[key] = 'Required';
      }
    }
    if (values.email && !isEmailValid(values.email)) {
      errors.email = 'Invalid email address';
    } else if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Password must match';
    }
    return errors;
  };
  const signUp = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    async function runAsync() {
      try {
        if (values.email) {
          if (signUpRole === 'TEAMMATE') {
            await firebaseApp.auth().signInWithEmailLink(values.email, window.location.href);
          } else {
            await createUser({ ...values, phoneNumber: `+${values.phoneNumber}` });
          }
          // await verifyPhoneNumber(`+${values.phoneNumber}`, values.email);
          const { user } = await firebaseApp.auth().createUserWithEmailAndPassword(values.email, values.password);
          if (user) {
            await user.updateProfile({ displayName: values.fullName });
          }
        }
        setSubmitting(false);
        setDrawerOpen('signup-confirmed');
      } catch (error) {
        setSubmitting(false);
        handleError(error);
      }
    }
    if (firebaseApp && values.phoneNumber) {
      runAsync();
    }
  };

  return (
    <Box>
      <Typography variant="h4">{signUpRole === 'TEAMMATE' ? 'Sign up to join your team!' : 'Sign Up!'}</Typography>
      <Formik initialValues={initialValues} validate={validate} onSubmit={signUp}>
        {({
          values,
          handleChange,
          handleSubmit,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <WhiteInput label="Name" type="text" name="fullName" />
            <WhiteInput label="Email" type="email" name="email" />
            <WhiteInput label="Mobile #" type="text" name="phoneNumber" InputProps={{ startAdornment: '+' }} />
            <WhiteInput label="Password" type="password" name="password" />
            <WhiteInput label="Confrim Password" type="password" name="passwordConfirm" />
            <WhiteCheckbox
              label={
                <Typography>
                  I agree to the <WhiteLink href="#">Terms and Conditions</WhiteLink>
                </Typography>
              }
              checked={values.acceptTermsAndConditions}
              onChange={handleChange}
              name="acceptTermsAndConditions"
            />
            <Box display="flex" marginTop={3}>
              <SubmitButton classes={{ root: classes.buttonRoot }}>Submit</SubmitButton>
            </Box>
          </form>
        )}
      </Formik>
      <Box fontStyle="italic" lineHeight={1.4} className={classes.marginTop}>
        <Box>On submit, you will receive a</Box>
        {signUpRole === 'TEAMMATE' ? (
          <>
            <Box>confirmation mobile code.</Box>
            <Box>Please verify before proceeding.</Box>
          </>
        ) : (
          <>
            <Box>confirmation email & mobile code.</Box>
            <Box>Please verify both before proceeding.</Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SignUpModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: theme.spacing(5),
    },
    buttonRoot: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.info.main,
      marginRight: theme.spacing(2.25),
    },
  })
);
