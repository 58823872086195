import { Box, Grid, IconButton, TableCell, TableRow, Theme, Typography, Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import ContainerTable, {
  ContainerFilters,
  containerStyles,
  TableHeader,
} from '../components/Containers/ContainerTable';
import { Search } from '../components/Search';
import AddContainer from '../components/AddContainer';
import ModalContext from '../context/modal';
import { Container, DrawerType, ContainerStatus } from '../../types';
import useUtils from '../hooks/useUtils';
import { StatusBadge } from '../components/Containers/StatusBadge';
import { PickUpLocation } from '../components/Containers/PickUpLocation';
import { AvailableFrom } from '../components/Containers/AvailableFrom';
import InfoIcon from '@mui/icons-material/Info';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import useOwnerId from '../hooks/useOwnerId';
import { AssignedPageStatusSelect } from '../components/Form';
import usePrevious from '../hooks/usePrevious';
import useGetClient from '../hooks/useGetClient';
import { Formik, FormikHelpers } from 'formik';
import UserContext from '../context/user';

interface MyFormValues {
  searchText: string;
  status: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
    },
    arrowButton: {
      backgroundColor: theme.palette.secondary.main,
    },
    arrowIcon: {
      color: theme.palette.common.white,
    },
  })
);

const AssignedTableHead = () => (
  <TableRow>
    <TableHeader label="STATUS" sortField="status" />
    <TableHeader sx={{ minWidth: 160 }} label="COLLECT BY" sortField="availableFrom" />
    <TableHeader sx={{ minWidth: 120 }} label="CONTAINER #" sortField="containerCode" />
    <TableHeader sx={{ minWidth: 120 }} label="TYPE" sortField="containerTypeCode" />
    <TableHeader sx={{ minWidth: 100 }} label="SHIPPING LINE" sortField="shippingLineCode" />
    <TableHeader sx={{ minWidth: 220 }} label="PICK UP LOCATION" />
    <TableHeader sx={{ minWidth: 200 }} label="REFERENCE#" sortField="referenceId" />
    <TableHeader
      sx={{ minWidth: 220 }}
      startIcon={
        <Tooltip title="Have contacted client for finalised disclaimer copy to go here:" arrow placement="left">
          <InfoIcon color="secondary" sx={{ mr: 1 }} />
        </Tooltip>
      }
      label="ENVIRONMENTAL SAVINGS"
      colSpan={2}
    />
  </TableRow>
);

const ContainerRow = ({ container }: { container: Container }) => {
  const stackedClasses = useStyles();
  const classes = containerStyles();
  const { setDrawerOpen, setContainer } = useContext(ModalContext);
  const { getContainerTypeName, getShippingLineName, getEnvSavingsText } = useUtils();
  const client = useGetClient(container);

  const editContainer = () => {
    setContainer(container);
    setDrawerOpen('view-container-assigned');
  };

  return (
    <TableRow classes={{ root: classes.rowRoot }}>
      <TableCell>
        <StatusBadge status={container.status} />
      </TableCell>
      <TableCell>
        <AvailableFrom container={container} />
      </TableCell>
      <TableCell>{container.containerCode}</TableCell>
      <TableCell>{getContainerTypeName(container)}</TableCell>
      <TableCell>{getShippingLineName(container)}</TableCell>
      <TableCell sx={{ maxWidth: 220 }}>
        <PickUpLocation client={client} />
      </TableCell>
      <TableCell>{container.referenceId}</TableCell>
      <TableCell>
        <Box whiteSpace="pre-line">{getEnvSavingsText(container, client)}</Box>
      </TableCell>
      <TableCell align="right">
        <IconButton className={stackedClasses.arrowButton} size="medium" onClick={editContainer}>
          <ArrowIcon className={stackedClasses.arrowIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const Assigned = () => {
  const [lastRefeshedAt, setLastRefeshedAt] = useState(new Date().getTime().toString());
  const { drawerOpen } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const prevDrawerOpen: DrawerType = usePrevious(drawerOpen);
  const owner_id = useOwnerId();
  const [containerFilters, setContainerFilters] = useState<ContainerFilters>({
    assignedUserId: owner_id,
    clientId: undefined,
    shippingLineCodes: undefined,
    containerTypeCodes: undefined,
    searchText: undefined,
    statuses: ['assigned', 'internalUse'],
  });
  const classes = useStyles();

  const refreshTable = () => {
    setLastRefeshedAt(new Date().getTime().toString());
  };

  useEffect(() => {
    const prevDrawerOpens = [
      'confirm-container',
      'cancel-request-container',
      'claim-futile-trip',
      'view-container-assigned',
      'edit-container'
    ];
    if (prevDrawerOpens.includes(`${prevDrawerOpen}`) && (drawerOpen === undefined || 'thank-you-dispute')) {
      refreshTable();
    }
  }, [drawerOpen, prevDrawerOpen]);

  const handleSubmit = (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
    let statuses: ContainerStatus[] = ['assigned', 'dispute', 'internalUse'];
    if (values.status && values.status !== 'all') {
      statuses = statuses.filter((status) => status === values.status);
    }
    setContainerFilters({
      ...containerFilters,
      searchText: values.searchText,
      statuses,
    });
  };

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="space-between" mb={7}>
        <Grid item >
          <Typography variant="h4" color="secondary">
            Assigned To Us
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2.5} alignItems="center" justifyContent="flex-end">
            <Formik initialValues={{ status: 'all', searchText: '' }} onSubmit={handleSubmit}>
              {({ handleSubmit, values, handleChange }) => {
                const handleSelectChange = (e: any, newValue: any) => {
                  handleSubmit(e);
                };
                const handleSearchChange = (e: any) => {
                  handleChange(e);
                  handleSubmit(e);
                };
                return (
                  <Grid item >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <AssignedPageStatusSelect
                          variant="outlined"
                          name="status"
                          placeholder="Status"
                          useGrey={true}
                          size="small"
                          onChange={handleSelectChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Search name="searchText" placeholder="Search..." onChange={handleSearchChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
            {!(user && user.accountTypes?.length === 1 && user.accountTypes[0] === 'exporter') ?
              <Grid item >
                <AddContainer onContainerAdd={refreshTable} />
              </Grid> : null
            }
          </Grid>
        </Grid>
      </Grid>
      <ContainerTable
        lastRefeshedAt={lastRefeshedAt}
        {...containerFilters}
        tableHead={<AssignedTableHead />}
        ContainerRow={ContainerRow}
        initialSortOptions={[{ direction: 'asc', sortField: 'availableFrom' }]}
      />
    </Box>
  );
};

export default Assigned;
