import { Box, Chip } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import SnackBarContext from '../../../context/snackbar';
import UserContext from '../../../context/user';
import { User } from '../../../../types';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';
import get from 'lodash/get';

export interface MyFormValues extends Required<Pick<User, 'settings'>> {}
interface ChipObject {
  name: string;
  code: string;
}

type Props = {
  fieldName: string;
  chips: ChipObject[];
};
export const ChipSelector = ({ chips, fieldName }: Props) => {
  const { user, saveUser } = useContext(UserContext);
  const { showSnack } = useContext(SnackBarContext);
  const { updateUser } = useUserApiRoutes();
  const [initialValues, setInitialValues] = useState<MyFormValues>();

  useEffect(() => {
    if (user) {
      setInitialValues(
        user?.settings
          ? { settings: user.settings }
          : { settings: { searchPreferences: { shippingLineCodes: [], containerTypeCodes: [] } } }
      );
    }
  }, [user]);

  const save = async (values: MyFormValues) => {
    const updatedUser = await updateUser({ ...values, uid: user?.uid });
    saveUser(updatedUser);
    showSnack('Setting saved!', 'success');
  };

  return (
    <Box my={6}>
      {initialValues ? (
        <Formik initialValues={initialValues} onSubmit={save} enableReinitialize>
          {({ handleSubmit, values, setFieldValue }) => {
            const handleClick = (chip: ChipObject) => {
              const curSelections: string[] = get(values, fieldName, []);
              if (curSelections.includes(chip.code)) {
                // remove it
                setFieldValue(
                  fieldName,
                  curSelections.filter((c) => c !== chip.code)
                );
              } else {
                // add it
                setFieldValue(fieldName, [...curSelections, chip.code]);
              }
              // setFieldValue(fieldName, chip.code);
              handleSubmit();
            };
            return (
              <form>
                {chips.map((chip) => {
                  const selected = get(values, fieldName).includes(chip.code);
                  return (
                    <Box key={chip.code} display="inline-block" mr={0.75} mb={0.5}>
                      <Chip
                        label={chip.name}
                        variant={selected ? 'filled' : 'outlined'}
                        color={selected ? 'primary' : 'default'}
                        onClick={() => handleClick(chip)}
                      />
                    </Box>
                  );
                })}
              </form>
            );
          }}
        </Formik>
      ) : null}
    </Box>
  );
};
