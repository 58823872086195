import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext } from 'react';
import ModalContext from '../../context/modal';
import { useHistory } from 'react-router-dom';

type Props = {};

const ThankYouDisputeModal = (props: Props) => {
  const { setDrawerOpen } = useContext(ModalContext);
  const history = useHistory();
  const classes = useStyles();

  const backToDashboard = () => {
    setDrawerOpen(undefined);
    history.push('/');
  };

  return (
    <Box className={classes.root}>
      <Box mb={2.75}>
        <Typography lineHeight={1.235} variant="h4">
          Thank You!
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="body1" className={classes.body1}>
          Your dispute has now been sent to the Virtual Stack support team. We will follow up on your behalf and confirm
          if monetary compensation is required.
        </Typography>
      </Box>
      <Button variant="contained" color="secondary" size="large" onClick={backToDashboard}>
        Back To Dashboard
      </Button>
    </Box>
  );
};

export default ThankYouDisputeModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 300,
    },
    body1: {
      color: theme.palette.text.primary,
      fontFamily: 'Oxygen',
    },
  })
);
