import { useCallback } from 'react';
import { User, UserStatus } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { useProtectedRoute } from './useProtectedRoute';
import { usePublicRoute } from './usePublicRoute';

export default function useUserApiRoutes() {
  const protectedRoute = useProtectedRoute();
  const publicRoute = usePublicRoute();

  const createUser = useCallback(
    async (user: Partial<User>): Promise<User> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/users`,
        data: user,
      });
      return response;
    },
    [publicRoute]
  );

  const updateUser = useCallback(
    async (user: Partial<User>, idToken?: string): Promise<User> => {
      const response = await protectedRoute(
        {
          method: 'put',
          url: `${REACT_APP_API_BASE_URL}/users/${user.uid}`,
          data: user,
        },
        undefined,
        idToken
      );
      return response;
    },
    [protectedRoute]
  );

  const getUser = useCallback(
    async (uid: string): Promise<User> => {
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/users/${uid}`,
      });
      return response;
    },
    [protectedRoute]
  );

  const getAllUsers = useCallback(
    async (where: any): Promise<User[]> => {
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/users/`,
        params: { where, isUnlimited: true },
      });
      return response.users;
    },
    [protectedRoute]
  );

  const updateUserStatus = useCallback(
    async (status: UserStatus, userId: string): Promise<User> => {
      const response = await protectedRoute({
        method: 'patch',
        url: `${REACT_APP_API_BASE_URL}/users/${userId}/status`,
        data: { status },
      });
      return response;
    },
    [protectedRoute]
  );

  return { createUser, updateUser, getUser, getAllUsers, updateUserStatus };
}
