import { Typography, Box, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Client } from '../../../types';
import useUtils from '../../hooks/useUtils';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);
export const PickUpLocation = ({ client, showFullAddress = true }: { client?: Client; showFullAddress?: boolean }) => {
  const { addressSuburb } = useUtils();

  const classes = styles();
  const tooltipText = `Site open ${client?.daysOperating?.join(', ')}. Access hours ${client?.accessHoursFrom} to ${
    client?.accessHoursTo
  }. ${client?.containerDoorOptions} Site Contact ${client?.siteContact}. ${client?.additionalSiteInstructions}`;
  return (
    <Tooltip title={tooltipText} arrow placement="left">
      <Box className={classes.root}>
        <InfoIcon color="secondary" sx={{ marginRight: 1 }} />
        <Typography variant="body2" noWrap>
          {showFullAddress ? client?.address : addressSuburb(client?.address || '')}
        </Typography>
      </Box>
    </Tooltip>
  );
};
