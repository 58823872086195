import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { useCallback, useContext } from 'react';
import FirebaseContext from '../../context/firebase';
import useHandleError from '../useHandleError';
import { serialize } from 'object-to-formdata';
// let cancelToken: CancelTokenSource;

export const useProtectedRoute = () => {
  const { firebaseApp } = useContext(FirebaseContext);
  const handleError = useHandleError();

  type FileObjKv = { [key: string]: File };
  const getAllFiles = useCallback((data: any, key?: string): FileObjKv[] => {
    const files: FileObjKv[] = [];
    if (key && data instanceof File) {
      files.push({ [key]: data });
    } else if (typeof data === 'object') {
      Object.entries(data).forEach(([k, v]) => {
        if (v instanceof File) {
          files.push({ [k]: v });
        } else if (Array.isArray(v)) {
          v.forEach((v2) => files.push(...getAllFiles(v2, k)));
        } else if (typeof v === 'object') {
          Object.values(v as any).forEach((v2) => files.push(...getAllFiles(v2, k)));
        }
      });
    }
    return files;
  }, []);

  const protectedRoute = useCallback(
    async (options: AxiosRequestConfig, source?: { token: CancelToken } | undefined, idToken?: string) => {
      const currentUser = firebaseApp.auth().currentUser;

      if (currentUser || idToken) {
        if (idToken === undefined && currentUser) {
          idToken = await currentUser.getIdToken();
        }
        const allFiles = getAllFiles(options.data);
        if (options.data && allFiles.length > 0) {
          options.data = serialize(options.data, { indices: true });
        }
        return axios({
          ...options,
          cancelToken: source ? source.token : undefined,
          headers: {
            'x-access-token': idToken,
            Pragma: 'no-cache',
          },
          responseType: 'json',
          withCredentials: true,
        })
          .then((response) => response.data)
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log('Request canceled', error.message);
            } else {
              handleError(error);
            }
          });
      } else {
        return;
      }
    },
    [firebaseApp, getAllFiles, handleError]
  );

  return protectedRoute;
};
