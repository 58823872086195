import './App.css';
import { FirebaseProvider } from './context/firebase';
import { UserProvider } from './context/user';
import NavBar from './components/NavBar';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './context/theme';
import Footer from './components/Footer';
import { Box } from '@mui/material';
import { ModalServiceProvider } from './context/modal';
import { SnackBarProvider } from './context/snackbar';
import AppRouter from './components/AppRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { GoogleMapsProvider } from './context/googleMaps';
import AccountVerifier from './components/client/AccountVerifier';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* SnackBarContext as outtermost as Firebaseprovider may need to show network errors */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackBarProvider>
            <GoogleMapsProvider>
              <Router>
                <UserProvider>
                  <FirebaseProvider>
                    <ModalServiceProvider>
                      <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
                        <NavBar />
                        <AccountVerifier />
                        <Box flex={1} overflow="auto" display="flex" flexDirection="column">
                          <AppRouter />
                        </Box>
                        <Footer />
                      </Box>
                    </ModalServiceProvider>
                  </FirebaseProvider>
                </UserProvider>
              </Router>
            </GoogleMapsProvider>
          </SnackBarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
