import { Box, Button, Grid, TableCell, TableRow, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import ContainerTable, {
  ContainerFilters,
  containerStyles,
  TableHeader,
} from '../components/Containers/ContainerTable';
import { ContainerTypeSelectAsync, ShippingLineSelectAsync } from '../components/Form';
import { Formik } from 'formik';
import { Search } from '../components/Search';
import AddContainer from '../components/AddContainer';
import ModalContext from '../context/modal';
import { Container, DrawerType } from '../../types';
import useUtils from '../hooks/useUtils';
import { StatusBadge } from '../components/Containers/StatusBadge';
import { PickUpLocation } from '../components/Containers/PickUpLocation';
import { AvailableFrom } from '../components/Containers/AvailableFrom';
import { LocationOption, LocationSelector } from './Landing/LocationSelector';
import usePrevious from '../hooks/usePrevious';
import UserContext from '../context/user';
import useGetClient from '../hooks/useGetClient';
import { StackedByUsBadge } from '../components/Containers/StackedByUsBadge';
import BlurredText from '../components/BlurredText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
    },
    caption: {
      fontFamily: 'Oxygen',
      fontWeight: 700,
      color: theme.palette.text.disabled,
    },
    headRow: {
      verticalAlign: 'top',
    },
    textBlur: {
      filter: 'blur(3px)',
    },
  })
);

const ContainerRow = ({
  container,
  goingTo,
  numberRow,
}: {
  container: Container;
  goingTo?: LocationOption;
  numberRow?: number;
}) => {
  const classes = containerStyles();
  const { isLoggedIn } = useContext(UserContext);
  const { setDrawerOpen, setContainer } = useContext(ModalContext);
  const { getContainerTypeName, getShippingLineName, calcDistanceBetween, isStackedByMe } = useUtils();

  const reserveContainer = () => {
    if (isLoggedIn) {
      setContainer({
        ...container,
        goingTo: goingTo
          ? { address: goingTo.address, location: goingTo.location, client_id: goingTo.client_id }
          : undefined,
      });
      setDrawerOpen('reserve-container');
    } else {
      setDrawerOpen('login');
    }
  };
  const client = useGetClient(container);

  let distanceBetween = client?.location && goingTo ? calcDistanceBetween(client?.location, goingTo.location) : 0;

  return (
    <TableRow classes={{ root: classes.rowRoot }}>
      <TableCell>
        <StatusBadge status={container.status} />
      </TableCell>
      <TableCell>
        <AvailableFrom container={container} />
      </TableCell>
      <TableCell>{isLoggedIn ? container.containerCode : <BlurredText />}</TableCell>
      <TableCell>{getContainerTypeName(container)}</TableCell>
      <TableCell>{isLoggedIn ? getShippingLineName(container) : <BlurredText />}</TableCell>
      <TableCell sx={{ maxWidth: 220 }}>
        {isLoggedIn ? <PickUpLocation client={client} showFullAddress={false} /> : <BlurredText />}
      </TableCell>
      <TableCell>
        {numberRow === 0 && !isLoggedIn && (
          <Typography color="secondary" fontStyle="italic">
            {numberRow === 0 && !isLoggedIn ? 'Please login to calculate distance' : null}
          </Typography>
        )}
        {isLoggedIn && <Typography>{(distanceBetween / 1000).toFixed(1)} kms</Typography>}
      </TableCell>
      <TableCell align="right">
        {isStackedByMe(container) ? (
          <StackedByUsBadge />
        ) : (
          <Button onClick={reserveContainer} size="large" variant="contained" color="secondary">
            Reserve
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const withDistanceCalc = (
  WrappedRow: React.FunctionComponent<{
    container: Container;
    goingTo?: LocationOption;
  }>,
  goingTo?: LocationOption
) => {
  return function (props: { container: Container }) {
    return <WrappedRow {...props} goingTo={goingTo} />;
  };
};

const AvailableTableHead = () => {
  const classes = useStyles();
  return (
    <TableRow classes={{ root: classes.headRow }}>
      <TableHeader sortField="status" label="STATUS" />
      <TableHeader sx={{ minWidth: 160 }} label="DATE AVAILABLE FROM" sortField="availableFrom" />
      <TableHeader sx={{ minWidth: 100 }} label="CONTAINER #" />
      <TableHeader sx={{ minWidth: 120 }} label="CONTAINER TYPE" sortField="containerTypeCode" />
      <TableHeader sx={{ minWidth: 100 }} label="SHIPPING LINE" sortField="shippingLineCode" />
      <TableHeader sx={{ width: 220 }} label="PICK UP LOCATION" />
      <TableHeader sx={{ minWidth: 310 }} colSpan={2} label="DISTANCE BETWEEN" sortField="distance">
        <Typography variant="caption" className={classes.caption}>
          Please select a location if different from your address
        </Typography>
      </TableHeader>
    </TableRow>
  );
};

const Landing = () => {
  const { drawerOpen } = useContext(ModalContext);
  const { user, isFrozen } = useContext(UserContext);
  const [lastRefeshedAt, setLastRefeshedAt] = useState(new Date().getTime().toString());
  const prevDrawerOpen: DrawerType = usePrevious(drawerOpen);

  const [containerFilters, setContainerFilters] = useState<ContainerFilters & { goingTo?: LocationOption }>({
    clientId: undefined,
    shippingLineCodes: undefined,
    containerTypeCodes: undefined,
    searchText: undefined,
    statuses: ['available', 'pending'],
    availableTo: { $gt: new Date().toISOString() },
  });

  useEffect(() => {
    if (user?.companyInformation) {
      setContainerFilters((curFilters) => {
        return {
          ...curFilters,
          goingTo: {
            locationType: 'company',
            address: user.companyInformation?.address || '',
            location: {
              type: 'Point',
              coordinates: user.companyInformation?.location?.coordinates || [0, 0],
            },
          },
        };
      });
    }
  }, [user?.companyInformation]);

  useEffect(() => {
    if (prevDrawerOpen === 'reserve-container' && drawerOpen === undefined) {
      // reserve-container modal has just been closed, so refresh the table to updated statuses etc
      refreshTable();
    }
  }, [drawerOpen, prevDrawerOpen]);
  const classes = useStyles();

  const refreshTable = () => {
    setLastRefeshedAt(new Date().getTime().toString());
  };

  return isFrozen ? (
    <Box className={classes.container}>
      <h3 color="error">
        Your account has been frozen. Please contact{' '}
        <a href="mailto:adam@hemispherefreight.com">adam@hemispherefreight.com</a> addressto appeal this decision !
      </h3>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Grid container justifyContent="space-between" mb={7}>
        <Grid item>
          <Typography variant="h4" color="primary">
            Available Containers
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2.5} alignItems="center">
            <Formik initialValues={containerFilters} onSubmit={setContainerFilters} enableReinitialize>
              {({ handleSubmit, handleChange }) => {
                const handleSelectChange = (e: any) => {
                  handleSubmit(e);
                };
                const handleSearchChange = (e: any) => {
                  handleChange(e);
                  handleSubmit(e);
                };
                return (
                  <>
                    <Grid item>
                      <ContainerTypeSelectAsync
                        multiple
                        onChange={handleSelectChange}
                        sx={{ minWidth: 150 }}
                        placeholder="Type"
                        name="containerTypeCodes"
                        variant="outlined"
                        useGrey={true}
                      />
                    </Grid>
                    <Grid item>
                      <ShippingLineSelectAsync
                        multiple
                        onChange={handleSelectChange}
                        sx={{ minWidth: 200 }}
                        placeholder="Shipping Line"
                        name="shippingLineCodes"
                        variant="outlined"
                        useGrey={true}
                      />
                    </Grid>
                    <Grid item>
                      <LocationSelector
                        onChange={handleSelectChange}
                        sx={{ minWidth: 250 }}
                        placeholder="Start typing to select Location Going To"
                        name="goingTo"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Search name="searchText" placeholder="Search..." onChange={handleSearchChange} />
                    </Grid>
                  </>
                );
              }}
            </Formik>
            <Grid item>
              <AddContainer onContainerAdd={refreshTable} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ContainerTable
        goingTo={containerFilters.goingTo}
        lastRefeshedAt={lastRefeshedAt}
        {...containerFilters}
        tableHead={<AvailableTableHead />}
        ContainerRow={withDistanceCalc(ContainerRow, containerFilters.goingTo)}
        initialSortOptions={[{ direction: 'asc', sortField: 'availableFrom' }]}
      />
    </Box>
  );
};

export default Landing;
