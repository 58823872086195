import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReportData } from './OurReportComponents/ReportData'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
  })
);

const OurReports = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <ReportData />
    </Box>
  );
};

export default OurReports;
