import { Box, Divider, Link, Theme, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import ModalContext from '../../context/modal';
import useUtils from '../../hooks/useUtils';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DescriptionIcon from '@mui/icons-material/Description';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import { startCase } from 'lodash';
import { DisputeAction } from '../Containers/DisputeAction';

type Props = {};

const ViewContainerDispute = (props: Props) => {
  const { container, setContainer } = useContext(ModalContext);
  const classes = useStyles();
  const { getContainerTypeName } = useUtils();
  const { getContainer } = useContainerApiRoutes();

  useEffect(() => {
    if (container?._id) {
      getContainer(container._id).then((returnedContainer) => {
        setContainer(returnedContainer);
      });
    }
  }, [container?._id, getContainer, setContainer]);

  return container ? (
    <Box className={classes.root}>
      <Box mb={2.75}>
        <Typography lineHeight={1.235} variant="h4">
          Container Dispute
        </Typography>
        <Typography lineHeight={1.235} variant="h4">
          #{container?.containerCode}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography className={classes.body2} variant="body2">
          Type: <strong>{getContainerTypeName(container)}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2" noWrap>
          Reference #<strong>{container.referenceId}</strong>
        </Typography>
      </Box>
      {container.disputes?.map((dispute) => {
        return (
          <Box key={dispute._id}>
            <Divider className={classes.divider} />

            <Box mb={3}>
              <Typography variant="subtitle1">DETAILS</Typography>
              <Typography className={classes.body2} variant="body2" noWrap>
                Dispute Claim: <strong>{startCase(dispute.disputeType)}</strong>
              </Typography>
              <Typography className={classes.body2} variant="body2">
                Dispute Details: {dispute?.description}
              </Typography>
            </Box>
            {dispute.evidenceOfDispute && dispute.evidenceOfDispute[0] ? (
              <Link
                href={dispute.evidenceOfDispute[0].signedUrl}
                target="_blank"
                display="flex"
                alignItems="center"
                underline="hover"
              >
                <Box className={classes.iconWrapper}>
                  <DescriptionIcon className={classes.icon} />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {dispute.evidenceOfDispute[0].origFileName}
                </Typography>
              </Link>
            ) : null}

            <Typography variant="subtitle1" mb={2}>
              ACTIONS
            </Typography>
            {dispute.actions?.map((action) => (
              <DisputeAction action={action} />
            ))}
          </Box>
        );
      })}
    </Box>
  ) : null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 435,
    },
    body2: {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
    },
    iconWrapper: {
      backgroundColor: theme.palette.info.main,
      borderRadius: theme.spacing(0.75),
      marginRight: theme.spacing(1),
      display: 'inline-flex',
      padding: theme.spacing(1),
    },
    icon: {
      color: theme.palette.common.white,
    },
    divider: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      backgroundColor: theme.palette.info.main,
    },
  })
);

export default ViewContainerDispute;
