import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext } from 'react';
import ModalContext from '../../context/modal';
import { Formik, FormikHelpers } from 'formik';
import {
  ClientSelectAsync,
  ContainerTypeSelectAsync,
  ShippingLineSelectAsync,
  SubmitButton,
  ThemedDatePicker,
  ThemedSwitch,
  ThemedTextField,
} from '../Form';
import { Client, Container } from '../../../types';
import { ImageUpload } from '../FileUploads';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import SnackBarContext from '../../context/snackbar';
import UserContext from '../../context/user';

type Props = {};
interface MyFormValues extends Container {
  internalUse: boolean;
}

const EditContainerModal = (props: Props) => {
  const { setDrawerOpen, container, setContainer } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { updateContainer } = useContainerApiRoutes();
  const { showSnack } = useContext(SnackBarContext);

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    // (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
    //   if (!values[k]) {
    //     errors[k] = 'Required';
    //   }
    // });
    return errors;
  };
  const handleSubmit = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    updateContainer(values)
      .then((returnedContainer) => {
        showSnack('Container Updated!', 'success');
        setSubmitting(false);
        setContainer(returnedContainer);
        setDrawerOpen(undefined);
      })
      .catch(() => setSubmitting(false));
  };

  // const editContainer = () => {
  //   setDrawerOpen(undefined);
  // };

  return container ? (
    <Box color="unset">
      <Typography mb={3} variant="h4">
        Edit Container #{container.containerCode}
      </Typography>
      <Formik
        initialValues={{ ...container, internalUse: container.status === 'internalUse' }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => {
          const handleInternalUseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            // set the status too
            setFieldValue('status', event.target.checked ? 'internalUse' : container.status);
            setFieldValue('assigned_to_user_id', event.target.checked ? user?._id : container.assigned_to_user_id);
            handleChange(event);
          };

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={6}>
                  <ThemedTextField
                    disabled={values.status !== 'available'}
                    name="containerCode"
                    label="Code"
                    fullWidth
                  ></ThemedTextField>
                </Grid>
                <Grid item xs={6}>
                  <ShippingLineSelectAsync
                    disabled={values.status !== 'available'}
                    label="Shipping Line"
                    name="shippingLineCode"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={6}>
                  <ThemedDatePicker
                    fullWidth={true}
                    name="availableFrom"
                    textFieldVariant="outlined"
                    label="Date Available From"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ThemedDatePicker
                    fullWidth={true}
                    name="availableTo"
                    textFieldVariant="outlined"
                    label="Date Available To"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={6}>
                  <ContainerTypeSelectAsync
                    disabled={values.status !== 'available'}
                    label="Container Type"
                    name="containerTypeCode"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ThemedSwitch
                    disabled={values.status !== 'available' && values.status !== 'internalUse'}
                    name="internalUse"
                    label="For Internal Use Only"
                    classes={{ label: classes.textSecondary }}
                    SwitchProps={{ onChange: handleInternalUseChange }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={6}>
                  <ClientSelectAsync
                    disabled={values.status !== 'available'}
                    getOptionLabel={(c: any) => c?.companyName || ''}
                    placeholder="Select Client"
                    name="client_id"
                    variant="outlined"
                    label="Pick Up Location"
                  />
                </Grid>
                <Grid item xs={6}>
                  {values.internalUse ? (
                    <ClientSelectAsync
                      disabled={container.status !== 'available'}
                      getOptionLabel={(c: any) => c?.companyName || ''}
                      getValue={(client: Client) => ({ address: client?.address, location: client?.location })}
                      isOptionEqualToValue={(option: any, value: any) => option?.address === value?.address}
                      placeholder="Select Location Going To"
                      name="goingTo"
                      variant="outlined"
                      label="Going To Location"
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={6}>
                  <ImageUpload name="photos" />
                </Grid>
              </Grid>
              <SubmitButton color="primary">Save Edits</SubmitButton>
            </form>
          );
        }}
      </Formik>
    </Box>
  ) : null;
};

export default EditContainerModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textSecondary: { color: theme.palette.text.secondary },
  })
);
