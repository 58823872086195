import { Box, Theme, Divider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { AddSite } from './OurSitesComponents/AddSite';
import { ClientTable } from './OurSitesComponents/ClientTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
  })
);

const OurClients = () => {
  const classes = useStyles();
  const [lastRefeshedAt, setLastRefeshedAt] = useState(new Date().getTime().toString());
  const handleAddSite = () => {
    setLastRefeshedAt(new Date().getTime().toString());
  };

  return (
    <Box className={classes.container}>
      <AddSite client={{}} onAddSite={handleAddSite} />
      <Divider />
      <ClientTable key={lastRefeshedAt} />
    </Box>
  );
};

export default OurClients;
