import { Typography, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { startCase } from 'lodash';
import useUtils from '../../hooks/useUtils';
import { Action } from '../../../types';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    body2: {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
    },
  })
);
export const DisputeAction = ({ action }: { action: Action }) => {
  const classes = styles();
  const { formattedDate } = useUtils();

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs>
        <Typography className={classes.body2} variant="body2">
          Claim {startCase(action.actionType)}
        </Typography>
      </Grid>
      {action.penaltyDollarFee ? (
        <Grid item xs>
          <Typography className={classes.body2} variant="body2">
            ${action.penaltyDollarFee.toFixed(0)}
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs>
        <Typography align="right" className={classes.body2} variant="body2">
          {formattedDate(new Date(action.created_at))}
        </Typography>
      </Grid>
    </Grid>
  );
};
