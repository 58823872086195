import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext } from 'react';
import ModalContext from '../../context/modal';

const SignUpConfirmedModal = () => {
  const { setDrawerOpen } = useContext(ModalContext);
  const classes = useStyles();

  const close = () => {
    setDrawerOpen(undefined);
  };

  return (
    <Box className={classes.root}>
      <Box mb={2.75}>
        <Typography lineHeight={1.235} variant="h4">
          Account Created!
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="body1">
          Please check your email for account verification before you can sign in.
        </Typography>
      </Box>
      <Button variant="contained" classes={{ root: classes.buttonRoot }} size="large" onClick={close}>
        Close
      </Button>
    </Box>
  );
};

export default SignUpConfirmedModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 300,
    },
    buttonRoot: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.info.main,
    },
  })
);
