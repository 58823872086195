import { useCallback } from 'react';
import { Client } from '../../types';

export interface ClientFormValues extends Partial<Client> {}
export interface EmailValues {
  email: string;
}
export interface PasswordValues {
  password: string;
  passwordConfirm: string;
}

export default function useValidators() {
  const isEmailValid = useCallback((email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email), []);

  const validateEmail = useCallback(
    (values: EmailValues) => {
      const errors: Partial<EmailValues> = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (!isEmailValid(values.email)) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
    [isEmailValid]
  );

  const validatePasswords = useCallback((values: PasswordValues) => {
    const errors: Partial<PasswordValues> = {};
    if (values.password && values.password.length < 8) {
      errors.password = 'Password Minimum 8 Characters';
    } else if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Password must match';
    }
    return errors;
  }, []);

  const validateClient = useCallback((values: ClientFormValues) => {
    const errors: { [Property in keyof Partial<Client>]: string } = {};
    const requiredFields: (keyof ClientFormValues)[] = ['companyName', 'address'];
    for (const field of requiredFields) {
      if (!values[field]) {
        errors[field] = 'Required';
        break;
      }
    }
    return errors;
  }, []);

  return { isEmailValid, validateClient, validateEmail, validatePasswords };
}
