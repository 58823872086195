import { useCallback } from 'react';
import { Constants } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';

export default function useConstantsApiRoutes() {
  const publicRoute = usePublicRoute();

  const getConstants = useCallback(async (): Promise<Constants> => {
    const response = await publicRoute({
      method: 'get',
      url: `${REACT_APP_API_BASE_URL}/constants`,
    });
    return response;
  }, [publicRoute]);

  const getEnvSavings = useCallback(async (): Promise<{
    tripsSaved: number;
    kmSaved: number;
    kgSaved: number;
  }> => {
    const response = await publicRoute({
      method: 'get',
      url: `${REACT_APP_API_BASE_URL}/container-env-savings`,
    });
    return response;
  }, [publicRoute]);

  return { getConstants, getEnvSavings };
}
