import { Box, Typography } from '@mui/material';

export const InfoField = ({
  heading,
  detail,
  children,
}: {
  heading: string;
  detail?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Box mb={3}>
      <Typography variant="overline" lineHeight={2.66} color="textSecondary">
        {heading}
      </Typography>
      {detail ? (
        <Typography variant="body2" color="textPrimary" whiteSpace="pre-line">
          {detail}
        </Typography>
      ) : null}
      <Box>{children}</Box>
    </Box>
  );
};
