import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import FirebaseContext from '../../context/firebase';
import UserContext from '../../context/user';
import AccountPending from './AccountPending';
import EmailNotVerified from './EmailNotVerified';
import MobileNotVerified from './MobileNotVerified';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // marginBottom: theme.spacing(2)
    },
  })
);

const AccountVerifier = () => {
  const classes = useStyles();
  const { currentUser } = useContext(FirebaseContext);
  const { user, isLoggedIn } = useContext(UserContext);
  return (
    <Box className={classes.container}>
      {isLoggedIn && user?.status === 'pending-approval' ? <AccountPending /> : null}
      {isLoggedIn && user?.emailVerified !== true ? <EmailNotVerified /> : null}
      {currentUser && currentUser.phoneNumber === null ? <MobileNotVerified /> : null}
    </Box>
  );
};

export default AccountVerifier;
