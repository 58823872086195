import { Box, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import SnackBarContext from '../../../context/snackbar';
import SaveIcon from '@mui/icons-material/Save';
import { ClientForm } from './ClientForm';
import { BaseFields, Client } from '../../../../types';
import useClientApiRoutes from '../../../hooks/api/useClientApiRoutes';
import useValidators from '../../../hooks/useValidators';
import { SubmitButton } from '../../../components/Form';
import UserContext from '../../../context/user';

export interface MyFormValues extends Required<Omit<Client, keyof BaseFields>>, Partial<BaseFields> {}

export const AddSite = ({ client, onAddSite }: { client: Omit<Client, keyof BaseFields>; onAddSite: () => void }) => {
  const { setClients } = useContext(UserContext);
  const { showSnack } = useContext(SnackBarContext);
  const { createClient } = useClientApiRoutes();
  const [initialValues, setInitialValues] = useState<MyFormValues | undefined>();
  const { validateClient } = useValidators();

  useEffect(() => {
    if (client) {
      setInitialValues({
        user_id: client.user_id || '',
        companyName: client.companyName || '',
        address: client.address || '',
        clientType: client.clientType || 'importer',
        gridLocations: client.gridLocations || [{ name: '' }],
        daysOperating: [],
        accessHoursFrom: '',
        accessHoursTo: '',
        containerDoorOptions: '',
        siteContact: '',
        additionalSiteInstructions: '',
        minimumHealthSafetyRequirements: '',
        healthAndSafetyDocuments: [],
        siteInstructionsDocuments: [],
        siteMapDocuments: [],
        location: {
          type: 'Point',
          coordinates: [0, 0],
        },
      });
    }
  }, [client]);

  const save = (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
    createClient(values)
      .then((client) => {
        setClients((curClients) => [client, ...curClients]);
        showSnack('Site Created!', 'success');
        resetForm();
        onAddSite();
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography variant="subtitle1" color="primary">
          ADD A NEW SITE
        </Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
        Add your site's details to your database. This information will be available when Requesting or Listing a
        container.
      </Typography>
      {initialValues ? (
        <Formik initialValues={initialValues} validate={validateClient} onSubmit={save} enableReinitialize>
          {({ handleSubmit, isSubmitting, isValid, dirty, setSubmitting, values }) => (
            <form onSubmit={handleSubmit}>
              <ClientForm />
              <SubmitButton startIcon={<SaveIcon />}>Save to Our Sites</SubmitButton>
            </form>
          )}
        </Formik>
      ) : null}
    </Box>
  );
};
