import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Box,
  TableRow,
  Button,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/user';
import { TableHeader } from '../../../components/Containers/ContainerTable';
import { ShippingLineData } from '../../../../types';

const accountTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablePagination: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      borderRadius: 24,
    },
    rowRoot: {
      '& td': {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      '&:nth-of-type(odd)': {
        '& td': {
          backgroundColor: theme.palette.grey[100],
        },
        '& td:first-child': {
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
        },
        '& td:last-child': {
          borderTopRightRadius: 24,
          borderBottomRightRadius: 24,
        },
      },
    },
    container: {
      backgroundColor: 'white',
      borderRadius: 24,
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      boxShadow: '0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    },
    arrowButton: {
      backgroundColor: theme.palette.info.main,
    },
    arrowIcon: {
      color: theme.palette.common.white,
    },
    colorInfoMain: {
      color: theme.palette.info.main,
    },
  })
);

const ShippingLineTabHeader = () => {
  const {
    constants: { containerTypes },
  } = useContext(UserContext);
  return (
    <TableRow>
      <TableHeader sx={{ minWidth: 100 }} label="SHIPPING LINE" />
      <TableHeader sx={{ minWidth: 40 }} label="" />
      {containerTypes.map((containerType, index) => (
        <TableHeader key={index} sx={{ minWidth: 60 }} label={containerType.name} />
      ))}
      <TableHeader sx={{ minWidth: 35 }} label="TOTAL" />
      <TableHeader sx={{ minWidth: 140 }} label="" />
    </TableRow>
  );
};

const ShippingLineRow = ({
  data,
  shippingLine,
  buttonOnClick,
}: ShippingLineData & { buttonOnClick: (shippingLineCode: string) => void }) => {
  const classes = accountTableStyles();
  const [totalAssigned, setTotalAssigned] = useState(0);
  const [totalUsed, setTotalUsed] = useState(0);
  const handleOnclick = () => {
    buttonOnClick(shippingLine.code);
  };
  useEffect(() => {
    let _totalAssigned = 0;
    let _totalUsed = 0;
    data.forEach((d) => {
      _totalAssigned += d.assigned;
      _totalUsed += d.used;
    });
    setTotalAssigned(_totalAssigned);
    setTotalUsed(_totalUsed);
  }, [data, setTotalAssigned, setTotalUsed]);
  return (
    <TableRow classes={{ root: classes.rowRoot }}>
      <TableCell>
        <Typography variant="body2">{shippingLine.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.colorInfoMain} variant="body2">
          Assigned
        </Typography>
        <Typography className={classes.colorInfoMain} variant="body2">
          Used
        </Typography>
      </TableCell>
      {data.map((d, index) => (
        <TableCell key={index}>
          <Typography variant="body2">{d.assigned}</Typography>
          <Typography variant="body2">{d.used}</Typography>
        </TableCell>
      ))}
      <TableCell>
        <Typography variant="body2">{totalAssigned}</Typography>
        <Typography variant="body2">{totalUsed}</Typography>
      </TableCell>
      <TableCell align="right">
        <Button onClick={handleOnclick} variant="contained" color="info">
          <Typography color="white">Create Report</Typography>
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ShippingLineTable = ({
  shippingLineDatas,
  onCreateReport,
}: { shippingLineDatas: ShippingLineData[] } & { onCreateReport: (shippingLineCode: string) => void }) => {
  const classes = accountTableStyles();
  // const [page, setPage] = useState(0);
  // const [totalItems, setTotalItems] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const handleChangePage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = ({
  //   target: { value },
  // }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setPage(0);
  //   setItemsPerPage(Number(value));
  // };

  const handleCreateReport = (shippingLineCode: string) => {
    onCreateReport(shippingLineCode);
  };
  return (
    <Box className={classes.container} display="flex" flexDirection="column" flex={1} minHeight={0}>
      <TableContainer sx={{ flex: 1, minHeight: 0 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <ShippingLineTabHeader />
          </TableHead>
          <TableBody>
            {shippingLineDatas.map((shippingLineData, index) => (
              <ShippingLineRow
                key={index}
                data={shippingLineData.data}
                shippingLine={shippingLineData.shippingLine}
                buttonOnClick={handleCreateReport}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        classes={{ root: classes.tablePagination }}
        component="div"
        count={totalItems}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={itemsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
  );
};

export default ShippingLineTable;
