import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  SelectAsyncWrapperProps,
  ThemedTextField,
  useGreyOutlinedStyles,
  useSelectChange,
} from '../../components/Form';
import { Client, Company, PointSchema } from '../../../types';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
// import useClientApiRoutes from '../../hooks/api/useClientApiRoutes';
// import useCompanyApiRoutes from '../../hooks/api/useCompanyApiRoutes';
import GoogleMapsContext, {
  componentRestrictions,
  PlaceResult,
  PlaceType,
  StructuredFormatting,
} from '../../context/googleMaps';
import { AutoCompleteOption, Part } from '../../components/PlacesAutoComplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Grid, Typography } from '@mui/material';
import UserContext from '../../context/user';

export type LocationOption = {
  locationType: 'client' | 'company' | 'google';
  name?: string;
  address: string;
  location: PointSchema;
  structured_formatting?: StructuredFormatting;
  place_id?: string;
  client_id?: string;
};
export const LocationSelector = ({
  variant = 'standard',
  name,
  onChange,
  placeholder,
  ...otherProps
}: SelectAsyncWrapperProps) => {
  // const { getClients } = useClientApiRoutes();
  // const { getCompanies } = useCompanyApiRoutes();
  const { clients, user } = useContext(UserContext);
  const { autocompleteService, placesService } = useContext(GoogleMapsContext);
  const classes = useGreyOutlinedStyles();
  const { values } = useFormikContext();
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = useState(get(values, name, ''));
  const [options, setOptions] = React.useState<readonly LocationOption[]>([]);
  const getOptionLabel = (o: any) => `${o?.name ? o.name + ': ' : ''}${o?.address || ''}`; //display client name first, then address
  const clientToOption = useCallback((client: Client | undefined): LocationOption => {
    return {
      name: client?.companyName,
      locationType: 'client',
      address: client?.address || '',
      location: { type: 'Point', coordinates: client?.location?.coordinates || [0, 0] },
      client_id: client?._id,
    };
  }, []);
  const companyToOption = useCallback((company: Company | undefined): LocationOption => {
    return {
      name: company?.companyName,
      locationType: 'company',
      address: company?.address || '',
      location: { type: 'Point', coordinates: company?.location?.coordinates || [0, 0] },
    };
  }, []);
  const placeTypeToOption = useCallback((placeType: PlaceType | undefined): LocationOption => {
    return {
      locationType: 'google',
      address: placeType?.description || '',
      location: { type: 'Point', coordinates: [0, 0] },
      structured_formatting: placeType?.structured_formatting,
      place_id: placeType?.place_id,
    };
  }, []);
  const afterSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => {
    if (onChange) {
      const locationOption: LocationOption | undefined = value;
      if (locationOption?.locationType === 'google') {
        placesService.getDetails(
          { placeId: locationOption.place_id, fields: ['geometry.location'] },
          (placeResult: PlaceResult) => {
            locationOption.location.coordinates = [
              placeResult.geometry.location.lng(),
              placeResult.geometry.location.lat(),
            ];
            onChange(event, value, reason, details);
          }
        );
      } else {
        onChange(event, value, reason, details);
      }
    }
  };
  const handleSelectChange = useSelectChange({
    name,
    onChange: afterSelectChange,
    getValue: (o) => o,
  });

  const fetch = React.useMemo(
    () =>
      debounce(async (searchString: string, callback: (results?: readonly LocationOption[]) => void) => {
        const [clientResponse, companyResponse, { predictions }] = await Promise.all([
          { clients },
          { companies: [user?.companyInformation] },
          autocompleteService
            ? autocompleteService.getPlacePredictions({ input: searchString, componentRestrictions })
            : [],
        ]);
        // const [clientResponse, companyResponse, { predictions }] = await Promise.all([
        //   getClients(0, 10, {
        //     $or: [
        //       { address: { $regex: searchString, $options: 'i' } },
        //       { companyName: { $regex: searchString, $options: 'i' } },
        //     ],
        //   }),
        //   getCompanies(0, 10, { 'companyInformation.address': { $regex: searchString, $options: 'i' } }),
        //   autocompleteService
        //     ? autocompleteService.getPlacePredictions({ input: searchString, componentRestrictions })
        //     : [],
        // ]);
        const optionsToReturn = [
          ...(clientResponse?.clients || []).map((c) => clientToOption(c)),
          ...(companyResponse?.companies || []).map((c) => companyToOption(c)),
          ...((predictions as PlaceType[]) || []).map((p) => placeTypeToOption(p)),
        ];
        return callback(optionsToReturn);
      }, 500),
    [autocompleteService, clientToOption, clients, companyToOption, placeTypeToOption, user?.companyInformation]
  );

  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (inputValue === value.address) {
      // starting value
      setOptions([value]);
      return;
    }

    fetch(inputValue, (returnedOptions?: readonly LocationOption[]) => {
      if (active) {
        let newOptions: readonly LocationOption[] = [];
        if (value) {
          newOptions = [value];
        }
        if (returnedOptions) {
          newOptions = [...newOptions, ...returnedOptions.filter((o) => o.address !== value?.address)];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [inputValue, values, name, fetch, value]);

  useEffect(() => {
    if (values && get(values, name)) {
      setValue(get(values, name, ''));
    }
  }, [name, values]);

  return (
    <Autocomplete
      disableClearable={true}
      fullWidth
      disablePortal
      value={value}
      options={options}
      onChange={handleSelectChange}
      isOptionEqualToValue={(option: any, value: any) => option.address === value.address}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={getOptionLabel}
      renderInput={({ InputProps, ...otherParams }) => (
        <ThemedTextField
          placeholder={placeholder}
          variant={variant}
          InputProps={{ classes: classes, ...InputProps }}
          name={name}
          onChange={() => {}}
          {...otherParams}
        />
      )}
      renderOption={(props, option: any, state) => {
        const locationOption: LocationOption = option;
        let matches: any;
        let parts: Part[] = [];
        let secondaryText: string;
        if (locationOption.locationType === 'google' && locationOption.structured_formatting) {
          matches = locationOption.structured_formatting?.main_text_matched_substrings;
          parts = parse(
            locationOption.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length])
          );
          secondaryText = locationOption.structured_formatting?.secondary_text;
          return (
            <AutoCompleteOption key={props.id} listElemProps={props} parts={parts} secondaryText={secondaryText} />
          );
        } else {
          matches = match(locationOption.address, inputValue);
          parts = parse(locationOption.address, matches);
          return (
            <AutoCompleteLocationOption key={props.id} name={locationOption.name} listElemProps={props} parts={parts} />
          );
        }
      }}
      {...otherProps}
    />
  );
};

const AutoCompleteLocationOption = ({
  name,
  listElemProps,
  parts,
}: {
  listElemProps: React.HTMLAttributes<HTMLLIElement>;
  name?: string;
  parts: Part[];
}) => {
  return (
    <li {...listElemProps}>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography variant="body1" fontWeight={700}>
            {name}
          </Typography>
          <Typography noWrap>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};
