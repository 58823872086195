import { Box, Button, Grid, TableCell, TableRow, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ContainerTable, {
  ContainerFilters,
  containerStyles,
  TableHeader,
} from '../components/Containers/ContainerTable';
import { Formik } from 'formik';
import { Search } from '../components/Search';
import ModalContext from '../context/modal';
import { Container } from '../../types';
import useUtils from '../hooks/useUtils';
import { StatusBadge } from '../components/Containers/StatusBadge';
import { PickUpLocation } from '../components/Containers/PickUpLocation';
import InfoIcon from '@mui/icons-material/Info';
import useOwnerId from '../hooks/useOwnerId';
import useGetClient from '../hooks/useGetClient';
import UserContext from '../context/user';
import { ArchivedTypesSelectAsync } from '../components/Form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
    },
  })
);

type colorOption = 'primary' | 'secondary';

const StackedTableHead = ({ color }: { color: colorOption }) => (
  <TableRow>
    <TableHeader label="STATUS" />
    <TableHeader sx={{ minWidth: 90 }} label="DATE USED" />
    <TableHeader sx={{ minWidth: 100 }} label="CONTAINER #" />
    <TableHeader sx={{ minWidth: 120 }} label="CONTAINER TYPE" />
    <TableHeader sx={{ minWidth: 100 }} label="SHIPPING LINE" />
    <TableHeader sx={{ minWidth: 220 }} label="PICK UP LOCATION" />
    <TableHeader sx={{ minWidth: 200 }} label="REFERENCE#" />
    <TableHeader
      sx={{ minWidth: 250 }}
      startIcon={<InfoIcon color={color} sx={{ mr: 1 }} />}
      label="ENVIRONMENTAL SAVINGS"
      colSpan={2}
    />
  </TableRow>
);

const withColor = (
  WrappedRow: React.FunctionComponent<{
    container: Container;
    color: colorOption;
  }>,
  color: colorOption
) => {
  return function (props: { container: Container }) {
    return <WrappedRow {...props} color={color} />;
  };
};

const ContainerRow = ({ container, color }: { container: Container; color: colorOption }) => {
  const classes = containerStyles();
  const { setDrawerOpen, setContainer } = useContext(ModalContext);
  const { getContainerTypeName, getShippingLineName, getEnvSavingsText, formattedDate } = useUtils();
  const client = useGetClient(container);

  const viewContainer = () => {
    setContainer(container);
    setDrawerOpen('view-dispute');
  };

  return (
    <TableRow classes={{ root: classes.rowRoot }}>
      <TableCell>
        <StatusBadge status={container.status} />
      </TableCell>
      <TableCell>{container.confirmedAt ? formattedDate(new Date(container.confirmedAt)) : "N/A"}</TableCell>
      <TableCell>{container.containerCode}</TableCell>
      <TableCell>{getContainerTypeName(container)}</TableCell>
      <TableCell>{getShippingLineName(container)}</TableCell>
      <TableCell sx={{ maxWidth: 220 }}>
        <PickUpLocation client={client} />
      </TableCell>
      <TableCell>{container.referenceId}</TableCell>
      <TableCell>
        <Box whiteSpace="pre-line">{getEnvSavingsText(container, client)}</Box>
      </TableCell>
      <TableCell align="right">
        {container.disputes?.length > 0 ? (
          <Button size="large" variant="contained" color={color} onClick={viewContainer}>
            View Details
          </Button>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const Archived = () => {
  const lastLastRefeshedAt = useRef(new Date().getTime().toString()).current;
  const owner_id = useOwnerId();
  const { user } = useContext(UserContext);
  const [archivedType, setArchivedType] = useState<String>("SBU");
  const [header, setHeader] = useState<any>({
    title: "Archived Stacked By Us",
    color: "primary"
  })
  const [stackedContainerFilters, setStackedContainerFilters] = useState<ContainerFilters>({
    userId: owner_id,
    clientId: undefined,
    shippingLineCodes: undefined,
    containerTypeCodes: undefined,
    searchText: undefined,
    statuses: ['used', 'dispute', 'cancelled'],
  });
  const [assignedContainerFilters, setAssignedContainerFilters] = useState<ContainerFilters>({
    userId: undefined,
    clientId: undefined,
    shippingLineCodes: undefined,
    containerTypeCodes: undefined,
    searchText: undefined,
    statuses: ['used', 'dispute', 'cancelled'],
    assignedUserId: owner_id
  });
  const classes = useStyles();

  const onsubmitFilter = (values: any) => {
    setArchivedType(values.archivedType);
    if (values.archivedType === "SBU") {
      setHeader({
        title: "Archived Stacked By Us",
        color: "primary"
      });
      setStackedContainerFilters({ ...stackedContainerFilters, searchText: values.searchText });
    } else {
      setHeader({
        title: "Archived Assigned To Us",
        color: "secondary"
      });
      setAssignedContainerFilters({ ...assignedContainerFilters, searchText: values.searchText });
    }
  };

  useEffect(() => {
    if (user && user.accountTypes?.includes('exporter') && user.accountTypes?.length === 1) {
      setHeader({
        title: "Archived Assigned To Us",
        color: "secondary"
      });
      setArchivedType('ATU');
    }
  }, [user, setHeader, setArchivedType])


  return (
    <Box className={classes.container}>
      <>
        <Grid container justifyContent="space-between" mb={7}>
          <Grid item xs={5}>
            <Typography variant="h4" color={header.color}>
              {header.title}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Formik initialValues={{ searchText: "", archivedType }} onSubmit={onsubmitFilter}>
              {({ handleSubmit, values, handleChange }) => {
                const handleSearchChange = (e: any) => {
                  handleChange(e);
                  handleSubmit(e);
                };
                return (
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item xs={4}>
                      <ArchivedTypesSelectAsync useGrey={true} size="small" name="archivedType" variant="outlined" onChange={handleSearchChange} />
                    </Grid>
                    <Grid item xs={6}>
                      <Search name="searchText" placeholder="Reference# & Container# & Location" onChange={handleSearchChange} />
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </>
      {user && (user.accountTypes?.includes('freight-forwarder') || user.accountTypes?.includes('importer')) && archivedType === "SBU" &&
        <>
          <ContainerTable
            lastRefeshedAt={lastLastRefeshedAt}
            {...stackedContainerFilters}
            tableHead={<StackedTableHead color="primary" />}
            ContainerRow={withColor(ContainerRow, 'primary')}
          />
        </>
      }
      {user && (user.accountTypes?.includes('freight-forwarder') || user.accountTypes?.includes('exporter')) && archivedType === "ATU" &&
        <>
          <ContainerTable
            lastRefeshedAt={lastLastRefeshedAt}
            {...assignedContainerFilters}
            tableHead={<StackedTableHead color="secondary" />}
            ContainerRow={withColor(ContainerRow, 'secondary')}
          />
        </>
      }
    </Box>
  );
};

export default Archived;
