import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Box,
  TablePagination,
  TableRow,
  IconButton,
  Grid,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext, useEffect, useState } from 'react';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import { Formik } from 'formik';
import { Search } from '../Search';
import ModalContext from '../../context/modal';
import { ContainerDispute } from '../../../types';
import startCase from 'lodash/startCase';
import { format } from 'date-fns';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';

const accountTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablePagination: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
    },
    rowRoot: {
      '& td': {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
    },
    cellRoot: {
      paddingLeft: '0px',
    },
    container: {
      backgroundColor: theme.palette.grey[100],
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    arrowButton: {
      backgroundColor: theme.palette.grey[100],
    },
    colorInfoMain: {
      color: theme.palette.info.main,
    },
    arrowIcon: {
      color: theme.palette.info.main,
    },
    textDisable: {
      color: theme.palette.text.disabled,
    },
    textError: {
      color: theme.palette.error.main,
    },
    textSuccess: {
      color: theme.palette.success.main,
    }
  })
);

const DisputeRow = ({ containerDispute, isCreated }: { containerDispute: ContainerDispute, isCreated: boolean }) => {
  const classes = accountTableStyles();
  const { setDrawerOpen, setContainer, setDispute } = useContext(ModalContext);
  // const [userAssigned, setUserAssigned] = useState<User | undefined>();
  const [actionType, setActionType] = useState<string>("");
  const [classText , setClassText] = useState<string>("")
  // const [containerDispute.dispute , setDispute] = useState<containerDispute.dispute>();

  useEffect(() => {
    const _actionType = containerDispute.dispute.actions[containerDispute.dispute.actions.length - 1].actionType
    if(isCreated && _actionType === "fined") {
      setActionType("Approved")
      setClassText(classes.textSuccess);
    } else {
      setActionType(startCase(_actionType))
      setClassText(classes.textError);
    }
  }, [])



  const handleApprovedDisputes = () => {
    setContainer(containerDispute.container);
    setDispute(containerDispute.dispute);
    setDrawerOpen('approved-disputes');
  };

  const handlePendingDisputes = () => {
    setContainer(containerDispute.container);
    setDispute(containerDispute.dispute);
    setDrawerOpen('pending-disputes');
  };

  const handleClick = {
    pending: handlePendingDisputes,
    fined: handleApprovedDisputes,
    refunded: undefined,
    rejected: undefined,
  };

  return (
    <TableRow classes={{ root: classes.rowRoot }}>
      <TableCell classes={{ root: classes.cellRoot }}>
        <Typography variant="body2">{containerDispute.userAssigned?.fullName || '-'}</Typography>
        <Typography variant="body2" className={classes.textDisable}>
          {containerDispute.userAssigned?.email || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{containerDispute.container.containerCode || '-'}</Typography>
        <Typography variant="body2" className={classes.textDisable}>
          #{containerDispute.container.referenceId || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{startCase(containerDispute.dispute.disputeType)}</Typography>
        {/* <Typography variant="body2" className={classes.textDisable}>(Declined)</Typography> */}
      </TableCell>
      <TableCell>
        {containerDispute.dispute.actions?.length > 0 ? (
          <>
            <Typography
              variant="body2"
              className={
                actionType === 'refunded'
                  ? classes.textError
                  : undefined
              }
            >
              {actionType}
            </Typography>
            <Typography variant="body2" className={classes.textDisable}>
              {format(
                new Date(containerDispute.dispute.actions[containerDispute.dispute.actions.length - 1]?.created_at),
                'dd/MM/yy'
              )}
            </Typography>
          </>
        ) : null}
      </TableCell>
      <TableCell>
        {containerDispute.dispute.actions?.length > 0 &&
          containerDispute.dispute.actions[containerDispute.dispute.actions.length - 1].penaltyDollarFee ? (
          <>
            {containerDispute.dispute.actions[containerDispute.dispute.actions.length - 1].actionType === 'refunded' ? (
              <Typography className={classes.textError} variant="body2">
                -${containerDispute.dispute.actions[containerDispute.dispute.actions.length - 1].penaltyDollarFee}
              </Typography>
            ) : (
              <Typography className={classText} variant="body2">
                ${containerDispute.dispute.actions[containerDispute.dispute.actions.length - 1].penaltyDollarFee}
              </Typography>
            )}
          </>
        ) : null}
      </TableCell>
      <TableCell align="right">
        {containerDispute.dispute.actions?.length > 0 ? (
          <>
            <IconButton
              onClick={
                handleClick[containerDispute.dispute.actions[containerDispute.dispute.actions.length - 1].actionType]
              }
              className={classes.arrowButton}
              size="medium"
            >
              <ArrowIcon className={classes.arrowIcon} />
            </IconButton>
          </>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const DisputesTable = ({ userId }: { userId: string }) => {
  const classes = accountTableStyles();
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentContainerDisputes, setCurrentContainerDisputes] = useState<ContainerDispute[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const { getContainerDisputes } = useContainerApiRoutes();

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0);
    setItemsPerPage(Number(value));
  };

  useEffect(() => {
    getContainerDisputes(userId, {
      currentPage: page,
      itemsPerPage,
      searchText
    }).then((response) => {
      setTotalItems(response.totalItems);
      setCurrentContainerDisputes(response.containerDisputes)
    })
  }, [userId, searchText, page, itemsPerPage, setCurrentContainerDisputes, setTotalItems, getContainerDisputes]);

  const handleSubmit = (value: any) => {
    setSearchText(value.searchText);
    setPage(0);

  };

  return (
    <Box className={classes.container} display="flex" flexDirection="column" flex={1} minHeight={0}>
      <Grid container justifyContent="space-between" spacing={4} alignItems="center">
        <Grid item>
          <Typography variant="subtitle1" className={classes.colorInfoMain}>
            DISPUTES
          </Typography>
        </Grid>
        <Grid item>
          <Formik
            validationDisabled={true}
            initialValues={{ searchText: '' }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ handleSubmit, values, handleChange }) => {
              const handleSearchChange = (e: any) => {
                handleChange(e);
                handleSubmit(e);
              };
              return (
                <>
                  <Search bgColor="white" name="searchText" placeholder="Search..." onChange={handleSearchChange} />
                </>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
      {currentContainerDisputes.length > 0 ? (
        <>
          <TableContainer sx={{ flex: 1, minHeight: 0 }}>
            <Table size="small">
              <TableBody>
                {currentContainerDisputes.map((containerDispute, index) => (
                  <DisputeRow key={index} containerDispute={containerDispute} isCreated={containerDispute.userAssigned?._id === userId} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{ root: classes.tablePagination }}
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : null}
    </Box>
  );
};

// (<DisputeRow key={index} containerDispute={containerDispute} />)

export default DisputesTable;
