import { AppBar, Box, Tab, Tabs, Theme, Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import UserContext from '../../context/user';
import NavLogo from '../../images/NavLogo.svg';
import ModalContext, { ConfirmationServiceContext } from '../../context/modal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import FirebaseContext from '../../context/firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      justifyContent: 'space-between',
    },
    image: {
      margin: theme.spacing(3),
    },
    tab: {
      fontSize: 15,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
      '&.Mui-disabled': {
        pointerEvents: 'auto',
      },
    },
    appBarRoot: {
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 6%)',
      zIndex: 1,
    },
  })
);

const NavBarClient = () => {
  let history = useHistory();
  const location = useLocation();
  const { isLoggedIn, user } = useContext(UserContext);
  const { setDrawerOpen } = useContext(ModalContext);
  const { firebaseApp, currentUser } = useContext(FirebaseContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const basePath = location.pathname.split('/', 2).join('/'); // only keep the top level path e.g. /settings
  const [tabValue, setTabValue] = useState('/'); // default to root tab as it is alwasy available
  const classes = useStyles();

  const linkTabs = useMemo(
    () => [
      {
        value: '/stacked',
        label: 'Stacked',
        disabled:
          !isLoggedIn ||
          !(user?.accountTypes?.includes('importer') || user?.accountTypes?.includes('freight-forwarder')),
        tooltipText: !(user?.accountTypes?.includes('importer') || user?.accountTypes?.includes('freight-forwarder'))
          ? 'Your account type must be Importer or Freight Fowarder to access this tab. Account type can be changed from Setting tab.'
          : '',
      },
      {
        value: '/',
        label: 'Available',
        disabled: false,
        tooltipText: '',
      },
      {
        value: '/assigned',
        label: 'Assigned',
        disabled:
          !isLoggedIn ||
          !(user?.accountTypes?.includes('exporter') || user?.accountTypes?.includes('freight-forwarder')),
        tooltipText: !(user?.accountTypes?.includes('exporter') || user?.accountTypes?.includes('freight-forwarder'))
          ? 'Your account type must be Exporter or Freight Fowarder to access this tab. Account type can be changed from Setting tab.'
          : '',
      },
      {
        value: '/archived',
        label: 'Archived',
        disabled: !isLoggedIn,
        tooltipText: !isLoggedIn ? 'Must be logged in to access this tab' : '',
      },
      {
        value: '/settings',
        label: 'Settings',
        disabled: !isLoggedIn,
        tooltipText: !isLoggedIn ? 'Must be logged in to access this tab' : '',
      },
    ],
    [isLoggedIn, user?.accountTypes]
  );

  useEffect(() => {
    /**
     * if user?.accountTypes changes, make sure the tabvalue is set from the basePath
     * Note this is common when a user has bookmarked or refreshed the page as the
     * initial user may not be loaded yet, so the Tab they are viewing may not be
     * enabled based on the users account types
     */
    if (user?.accountTypes && basePath !== tabValue) {
      const requestedTab = linkTabs.find((linkTab) => linkTab.value === basePath);
      if (!requestedTab?.disabled && !basePath.includes('super-admin')) {
        setTabValue(basePath);
      }
    }
  }, [basePath, linkTabs, tabValue, user?.accountTypes]);

  useEffect(() => {
    if (currentUser === null) {
      history.replace(`/${location.search}`);
    }
  }, [currentUser, history, location.search]);

  useEffect(() => {
    if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
      setDrawerOpen('signup');
    }
  }, [firebaseApp, setDrawerOpen]);

  const logOut = async () => {
    const res = await showConfirmationModal({
      title: 'Log Out',
      contentText: 'Are you sure you want to Log Out?',
    });
    if (res && res.confirmed === true) {
      setOpenId(undefined);
      firebaseApp?.auth().signOut();
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (newValue === 'login') {
      setDrawerOpen('login');
    } else if (newValue === 'logout') {
      logOut();
    } else {
      setTabValue(newValue);
    }
  };

  return (
    <>
      <AppBar position="static" color="transparent" classes={{ root: classes.appBarRoot }}>
        <Box display="flex" flexDirection="row" className={classes.container}>
          <img src={NavLogo} alt="Logo" className={classes.image} />
          <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary">
            {linkTabs.map((linkTab) => {
              return (
                <Tab
                  key={linkTab.value}
                  label={
                    <Tooltip title={linkTab.tooltipText}>
                      <span>{linkTab.label}</span>
                    </Tooltip>
                  }
                  value={linkTab.value}
                  classes={{ root: classes.tab }}
                  component={linkTab.disabled ? 'button' : Link} // use a div so that it cannot be click on when disabled
                  to={linkTab.disabled ? undefined : linkTab.value} // must be undefined when disabled or else it still works!
                  disabled={linkTab.disabled}
                />
              );
            })}
            {isLoggedIn ? (
              <Tab label="Logout" value="logout" classes={{ root: classes.tab }} />
            ) : (
              <Tab label="Login" value="login" classes={{ root: classes.tab }} />
            )}
          </Tabs>
        </Box>
      </AppBar>
    </>
  );
};

export default NavBarClient;
