import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Container, Client, AttachedFile } from '../../../types';
import useUtils from '../../hooks/useUtils';
import { Box, Grid, Link, Typography } from '@mui/material';
import { StatusBadge } from './StatusBadge';
import { Badge } from './Badge';
import { Theme } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import { InfoField } from './InfoField';
import ContainerPlaceholder from '../../images/ContainerPlaceholder.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    daysAvailableBadge: {
      backgroundColor: theme.palette.error.light,
      marginLeft: theme.spacing(1),
    },
    image: {
      height: 300,
      width: 400,
      objectFit: 'contain',
    },
  })
);

export const ContainerInfo = ({
  container,
  client,
  color,
  referenceVisible,
}: {
  container: Container;
  client?: Client;
  color?: string;
  referenceVisible?: boolean;
}) => {
  const { getContainerTypeName, getShippingLineName, getDaysAvailableText, getImages } = useUtils();
  const classes = useStyles();
  const { daysLeftText, datesAvailableText } = getDaysAvailableText(container);
  const images = container && container.photos ? getImages(container.photos, 'large') : [];
  const InfoFile = ({ heading, attachedFiles }: { heading: string; attachedFiles: AttachedFile[] }) => {
    return (
      <Grid item xs={4}>
        <InfoField heading={heading} detail={attachedFiles && attachedFiles.length > 0 ? undefined : '-'}>
          {attachedFiles?.map((attachedFile, index) => (
            <Link color={color ? color : 'primary'} key={index} href={attachedFile.signedUrl}>
              View
            </Link>
          ))}
        </InfoField>
      </Grid>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between" spacing={4} alignItems="center" mb={3}>
        <Grid item>
          <Typography color={color ? color : 'primary'} variant="h4">
            Container #{container.containerCode}
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex">
            <StatusBadge status={container.status} />
            <Badge className={classes.daysAvailableBadge} text={daysLeftText} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4} color="unset" mb={4}>
        <Grid item xs={4}>
          <InfoField heading="SHIPPING LINE" detail={getShippingLineName(container)} />
          <InfoField heading="DATE RANGE AVAILABLE" detail={datesAvailableText} />
          <InfoField heading="CONTAINER TYPE" detail={getContainerTypeName(container)} />
          {referenceVisible ? (
            <InfoField heading="REFERENCE #" detail={container.referenceId || ''} />
          ) : (
            <InfoField heading="SITE STACKED" detail={client?.companyName || ''} />
          )}
        </Grid>
        <Grid item xs={8}>
          {images.length > 0 && images[0].signedUrl ? (
            <Carousel navButtonsAlwaysVisible={images.length > 1} indicators={images.length > 1} timeout={300}>
              {images.map((image, index) => (
                <Box key={index} display="flex" justifyContent="center">
                  <img src={image.signedUrl} alt={container.containerCode} className={classes.image} />
                </Box>
              ))}
            </Carousel>
          ) : (
            <Box display="flex" justifyContent="center">
              <img src={ContainerPlaceholder} alt={container.containerCode} className={classes.image} />
            </Box>
          )}
        </Grid>
      </Grid>
      {client ? (
        <Grid container spacing={4} color="unset">
          <InfoFile heading="HEALTH AND SAFETY" attachedFiles={client.healthAndSafetyDocuments || []} />
          <InfoFile heading="SITE INSTRUCTIONS" attachedFiles={client.siteInstructionsDocuments || []} />
          <InfoFile heading="SITE MAP" attachedFiles={client.siteMapDocuments || []} />
        </Grid>
      ) : null}
    </>
  );
};
