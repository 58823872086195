import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Badge } from './Badge';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#9E9E9E",
      color: theme.palette.common.white,
      marginTop: theme.spacing(0.375),
    },
  })
);

export const StackedByUsBadge = () => {
  const classes = useStyles();
  return (
    <Box display="inline-flex">
      <Badge  className={classes.root} text="Stacked By Us" />
    </Box>
  );
};
