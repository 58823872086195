import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { ClientForm } from './OurSitesComponents/ClientForm';
import { SubmitButton } from '../../components/Form';
import SaveIcon from '@mui/icons-material/Save';
import { BaseFields, Client } from '../../../types';
import useValidators from '../../hooks/useValidators';
import useClientApiRoutes from '../../hooks/api/useClientApiRoutes';
import SnackBarContext from '../../context/snackbar';
import UserContext from '../../context/user';
import useOwnerId from '../../hooks/useOwnerId';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
  })
);
export interface MyFormValues extends Required<Omit<Client, keyof BaseFields>>, Partial<BaseFields> {}

const Fineprint = () => {
  const classes = useStyles();
  const { user, setClients, clients } = useContext(UserContext);
  const owner_id = useOwnerId(user);
  const { showSnack } = useContext(SnackBarContext);
  const { createClient, updateClient } = useClientApiRoutes();
  const [initialValues, setInitialValues] = useState<MyFormValues | undefined>();
  const { validateClient } = useValidators();

  const client = clients.find((c) => c.clientType === 'user-fineprint');

  useEffect(() => {
    setInitialValues({
      _id: client?._id,
      user_id: client?.user_id || owner_id || '',
      companyName: client?.companyName || user?.companyInformation?.companyName || '',
      address: client?.address || user?.companyInformation?.address || '',
      clientType: 'user-fineprint',
      gridLocations: client?.gridLocations || [{ name: '' }],
      daysOperating: client?.daysOperating || [],
      accessHoursFrom: client?.accessHoursFrom || '',
      accessHoursTo: client?.accessHoursTo || '',
      containerDoorOptions: client?.containerDoorOptions || '',
      siteContact: client?.siteContact || '',
      additionalSiteInstructions: client?.additionalSiteInstructions || '',
      minimumHealthSafetyRequirements: client?.minimumHealthSafetyRequirements || '',
      healthAndSafetyDocuments: client?.healthAndSafetyDocuments || [],
      siteInstructionsDocuments: client?.siteInstructionsDocuments || [],
      siteMapDocuments: client?.siteMapDocuments || [],
      location: {
        type: 'Point',
        coordinates: client?.location?.coordinates || user?.companyInformation?.location?.coordinates || [0, 0],
      },
    });
  }, [
    client,
    owner_id,
    user?.companyInformation?.address,
    user?.companyInformation?.companyName,
    user?.companyInformation?.location?.coordinates,
  ]);

  const save = (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
    const promise = values._id ? updateClient(values) : createClient(values);
    promise
      .then((client) => {
        setClients((curClients) => [client, ...curClients.filter((c) => c._id !== client._id)]);
        showSnack('Fineprint Updated!', 'success');
        resetForm();
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <Box className={classes.container}>
      <Box mb={1}>
        <Typography variant="subtitle1" color="primary">
          ADD YOUR COMPANY FINEPRINT
        </Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
        These instructions are for all containers stored on your site and added to the Virtual Stack. Upload pdf, png or
        jpg files.
      </Typography>
      {initialValues ? (
        <Formik initialValues={initialValues} validate={validateClient} onSubmit={save} enableReinitialize>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ClientForm />
              <SubmitButton startIcon={<SaveIcon />}>Save & Update</SubmitButton>
            </form>
          )}
        </Formik>
      ) : null}
    </Box>
  );
};

export default Fineprint;
