import { useCallback } from 'react';
import { User } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { useProtectedRoute } from './useProtectedRoute';

export default function useTeammateApiRoutes() {
  const protectedRoute = useProtectedRoute();

  const createTeammate = useCallback(
    async (ownerId: string, user: Partial<User>): Promise<User> => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/teammates/${ownerId}`,
        data: user,
      });
      return response;
    },
    [protectedRoute]
  );

  const deleteTeammate = useCallback(
    async (ownerId: string, teammateId: string): Promise<User> => {
      const response = await protectedRoute({
        method: 'delete',
        url: `${REACT_APP_API_BASE_URL}/teammates/${ownerId}/${teammateId}`,
      });
      return response;
    },
    [protectedRoute]
  );

  const getTeammates = useCallback(
    async (ownerId: string): Promise<{ users: User[] }> => {
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/teammates/${ownerId}`,
      });
      return response;
    },
    [protectedRoute]
  );

  return { createTeammate, deleteTeammate, getTeammates };
}
