import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ShippingLineTable from './shipping-lines/ShippingLineTable';
import { Container, ShippingLineData, ShippingLine, Client } from '../../../types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import UserContext from '../../context/user';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import useClientApiRoutes from '../../hooks/api/useClientApiRoutes';
import { startCase } from 'lodash';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.grey[100],
      height: '100%',
    },
  })
);

const ShippingLineActive = () => {
  const classes = useStyles();
  const [containers, setContainers] = useState<Container[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const {
    isLoggedIn,
    constants: { shippingLines, containerTypes },
  } = useContext(UserContext);
  const { getAllContainers } = useContainerApiRoutes();
  const { getAllClients } = useClientApiRoutes();
  const [shippingLineDatas, setShippingLineDatas] = useState<ShippingLineData[]>([]);
  const [dataReportContainer, setDataReportContainer] = useState<any[]>([]);
  const [shippingLine, setShippingLine] = useState<ShippingLine>();
  const [exportFile, setExportFile] = useState<boolean>(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const loadContainers = useCallback(() => {
    getAllContainers().then((response) => {
      if (response && response.length > 0) {
        setContainers(response);
      }
    });
  }, [getAllContainers]);

  const loadClients = useCallback(() => {
    getAllClients().then((response) => {
      if (response.clients && response.clients.length > 0) {
        setClients(response.clients);
      }
    });
  }, [getAllClients]);

  useEffect(() => {
    if (isLoggedIn) {
      loadContainers();
      loadClients();
    }
  }, [isLoggedIn, loadContainers, loadClients]);

  useEffect(() => {
    if (shippingLines && shippingLines.length > 0) {
      const shippingLineDatas = shippingLines.map((shippingLine) => {
        const data = containerTypes.map((containerType) => {
          return {
            assigned:
              containers.filter(
                (container) =>
                  container.containerTypeCode === containerType.code &&
                  container.status === 'assigned' &&
                  container.shippingLineCode === shippingLine.code
              ).length || 0,
            used:
              containers.filter(
                (container) =>
                  container.containerTypeCode === containerType.code &&
                  container.status === 'used' &&
                  container.shippingLineCode === shippingLine.code
              ).length || 0,
            containerType: containerType.name,
          };
        });
        return {
          shippingLine,
          data,
        };
      });
      setShippingLineDatas(shippingLineDatas);
    }
  }, [containers, setShippingLineDatas, shippingLines, containerTypes]);

  useEffect(() => {
    if (shippingLine && dataReportContainer.length > 0 && exportFile) {
      setTimeout(() => {
        csvLinkRef?.current?.link.click();
      }, 200);
      setExportFile(false);
    }
  }, [exportFile, shippingLine, dataReportContainer, setExportFile]);

  const headers = [
    { label: 'Container Code', key: 'containerCode' },
    { label: 'Status', key: 'status' },
    { label: 'Container Type Code', key: 'containerTypeCode' },
    { label: 'Available From', key: 'availableFrom' },
    { label: 'Available Time From', key: 'availableTimeFrom' },
    { label: 'Available To', key: 'availableTo' },
    { label: 'Clean And Acessible', key: 'cleanAndAcessible' },
    { label: 'In Stack', key: 'inStack' },
    { label: 'Internal Use', key: 'internalUse' },
    { label: 'Km Saved', key: 'kmSaved' },
    { label: 'Co2 Saved', key: 'co2Saved' },
    { label: 'Created Time', key: 'createdTime' },
  ];

  const handleCreateReport = (shippingLineCode: string) => {
    const _shippingLine = shippingLines.find((shippingLine) => shippingLine.code === shippingLineCode);
    const containerFilter = containers.filter((container) => container.shippingLineCode === shippingLineCode);
    const containersFormatting = containerFilter.map((container) => {
      let co2Saved = 'N/A';
      let kmSaved = 'N/A';
      if (container.status !== 'pending') {
        const savingValue = container.environmentalSavings || { kgSaved: 0, kmSaved: 0 };
        co2Saved = savingValue.kgSaved.toFixed(1);
        kmSaved = savingValue.kmSaved.toFixed(1);
      }
      return {
        containerCode: container.containerCode || 'N/A',
        status: container.status ? startCase(container.status) : 'N/A',
        containerTypeCode: container.containerTypeCode || 'N/A',
        availableFrom: container.availableFrom ? format(new Date(container.availableFrom), 'd MMM yyyy') : 'N/A',
        availableTimeFrom: container.availableFrom ? format(new Date(container.availableFrom), 'h:mm a') : 'N/A',
        availableTo: container.availableFrom ? format(new Date(container.availableFrom), 'd MMM yyyy') : 'N/A',
        cleanAndAcessible: container.cleanAndAcessible ? 'Yes' : 'No',
        inStack: container.inStack ? 'Yes' : 'No',
        internalUse: container.status === 'internalUse' ? 'Yes' : 'No',
        createdTime: container.created_at ? format(new Date(container.created_at), 'd MMM yyyy') : 'N/A',
        co2Saved: co2Saved ? co2Saved : 0,
        kmSaved: kmSaved ? kmSaved : 0,
      };
    });
    setDataReportContainer(containersFormatting);
    setShippingLine(_shippingLine);
    setExportFile(true);
  };

  return (
    <Box className={classes.container}>
      <ShippingLineTable shippingLineDatas={shippingLineDatas} onCreateReport={handleCreateReport} />
      {dataReportContainer && shippingLine ? (
        <CSVLink
          data={dataReportContainer}
          headers={headers}
          filename={`Report_${shippingLine.name.split(' ').join('_')}_${format(new Date(), 'd_MMM_yyyy')}.csv`}
          className="hidden"
          ref={csvLinkRef}
          target="_blank"
        />
      ) : null}
    </Box>
  );
};

export default ShippingLineActive;
