import { AppBar, Box, Tab, Tabs, Theme, Tooltip, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ModalContext, { ConfirmationServiceContext } from '../../context/modal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import FirebaseContext from '../../context/firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: 'linear-gradient(90.07deg, #0088CF 0%, #00B7B2 100%)',
      justifyContent: 'space-between',
    },
    subtitle: {
      margin: theme.spacing('auto', 8),
    },
    tab: {
      fontSize: 15,
      fontWeight: 600,
      color: '#FFFFFF',
      '&:hover': {
        background: 'rgba(167, 255, 235, 0.5)',
      },
      '&.Mui-disabled': {
        pointerEvents: 'auto',
      },
    },
    appBarRoot: {
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 6%)',
      zIndex: 1,
    },
  })
);

const NavBarAdmin = () => {
  let history = useHistory();
  const location = useLocation();
  const { setDrawerOpen } = useContext(ModalContext);
  const { firebaseApp, currentUser } = useContext(FirebaseContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const [tabValue, setTabValue] = useState('/super-admin'); // default to root tab as it is alwasy available
  const basePath = location.pathname.split('/', 3).join('/'); // only keep the top level path e.g. /settings

  const classes = useStyles();

  const linkTabs = useMemo(
    () => [
      {
        value: '/super-admin',
        label: 'Overview',
        disabled: false,
        tooltipText: '',
      },
      {
        value: '/super-admin/shipping-lines',
        label: 'Shipping Lines',
        disabled: false,
        tooltipText: '',
      },
    ],
    []
  );

  useEffect(() => {
    if (currentUser === null) {
      history.replace(`/${location.search}`);
    }
  }, [currentUser, history, location.search]);

  useEffect(() => {
    setTabValue(basePath);
  }, [basePath, setTabValue]);

  useEffect(() => {
    if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
      setDrawerOpen('signup');
    }
  }, [firebaseApp, setDrawerOpen]);

  const logOut = async () => {
    const res = await showConfirmationModal({
      title: 'Log Out',
      contentText: 'Are you sure you want to Log Out?',
    });
    if (res && res.confirmed === true) {
      setOpenId(undefined);
      firebaseApp?.auth().signOut();
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (newValue === 'login') {
      setDrawerOpen('login');
    } else if (newValue === 'logout') {
      logOut();
    } else {
      setTabValue(newValue);
    }
  };

  return (
    <>
      <AppBar position="static" color="transparent" classes={{ root: classes.appBarRoot }}>
        <Box display="flex" flexDirection="row" className={classes.container}>
          <Typography variant="subtitle1" color="white" classes={{ root: classes.subtitle }}>
            SUPER ADMIN
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            sx={{
              '.Mui-selected': {
                background: 'rgba(167, 255, 235, 0.5)',
                color: '#FFFFFF',
                borderBottom: '2px solid #FFFFFF',
              },
            }}
          >
            {linkTabs.map((linkTab) => {
              return (
                <Tab
                  key={linkTab.value}
                  label={
                    <Tooltip title={linkTab.tooltipText}>
                      <span>{linkTab.label}</span>
                    </Tooltip>
                  }
                  value={linkTab.value}
                  classes={{ root: classes.tab }}
                  component={linkTab.disabled ? 'button' : Link} // use a div so that it cannot be click on when disabled
                  to={linkTab.disabled ? undefined : linkTab.value} // must be undefined when disabled or else it still works!
                  disabled={linkTab.disabled}
                />
              );
            })}
            <Tab label="Logout" value="logout" classes={{ root: classes.tab }} />
          </Tabs>
        </Box>
      </AppBar>
    </>
  );
};

export default NavBarAdmin;
