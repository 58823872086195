import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Box,
  TablePagination,
  TableRow,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext, useEffect, useState } from 'react';
import { TableHeader } from '../../../components/Containers/ContainerTable';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import ModalContext from '../../../context/modal';
import { AccountData } from '../../../../types';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';
import SnackBarContext from '../../../context/snackbar';


const accountTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablePagination: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      borderRadius: 24,
    },
    rowRoot: {
      '& td': {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      '&:nth-of-type(odd)': {
        '& td': {
          backgroundColor: theme.palette.grey[100],
        },
        '& td:first-child': {
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
        },
        '& td:last-child': {
          borderTopRightRadius: 24,
          borderBottomRightRadius: 24,
        },
      },
    },
    container: {
      backgroundColor: 'white',
      borderRadius: 24,
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      boxShadow: '0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    },
    arrowButton: {
      backgroundColor: theme.palette.info.main,
    },
    arrowIcon: {
      color: theme.palette.common.white,
    },
    colorInfoMain: {
      color: theme.palette.info.main,
    },
    colorFrozen: {
      opacity: "0.5"
    },
    accountName: {
      color: "rgba(170, 170, 170, 0.8)"
    }
  })
);

const AccountTabHeader = () => (
  <TableRow>
    <TableHeader sx={{ minWidth: 115 }} label="ACCOUNT NAME" />
    <TableHeader sx={{ minWidth: 60 }} label="TYPE" />
    <TableHeader sx={{ minWidth: 75 }} label="# STACKED" />
    <TableHeader sx={{ minWidth: 85 }} label="# ASSIGNED" />
    <TableHeader sx={{ minWidth: 65 }} label="DISPUTES" />
    <TableHeader sx={{ minWidth: 155 }} label="SAVINGS" />
  </TableRow>
);

const AccountRow = ({ accountData }: { accountData: AccountData }) => {
  const classes = accountTableStyles();
  const { setDrawerOpen, setAccountInfo } = useContext(ModalContext);
  const { updateUserStatus } = useUserApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const moreInfoClient = () => {
    setAccountInfo(pick(accountData, ['user', 'teammateTotal']));
    setDrawerOpen('more-client-info');
  };
  const handleActiveAccount = () => {
    updateUserStatus("activate", accountData.user._id)
      .then((_user) => {
        const _accountInfo = {
          teammateTotal: accountData.teammateTotal || 0,
          user: _user,
        };
        setAccountInfo(_accountInfo);
        showSnack(`Account ${accountData.user.fullName} has been activate`, 'success');
      })
  }

  return (
    <TableRow classes={{ root: classes.rowRoot }}>
      <TableCell>
        <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined} >
          {accountData.user.companyInformation?.companyName}
        </Typography>
        <Typography className={(accountData.user.status === "frozen" ? classes.colorFrozen : undefined) + ' ' + classes.accountName} >
          {accountData.user.fullName}
        </Typography>
      </TableCell>
      <TableCell>
        {accountData.user.accountTypes?.map((accountType, index) => (
          <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined} key={index}>
            {startCase(accountType)}
            {accountData.user.accountTypes && index < accountData.user.accountTypes.length - 1 ? ',' : null}
          </Typography>
        ))}
      </TableCell>
      <TableCell >
        <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined}>
          <span className={classes.colorInfoMain}>40'</span> {accountData.stacked['40']}
        </Typography>
        <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined}>
          <span className={classes.colorInfoMain}>20'</span> {accountData.stacked['20']}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined}>
          <span className={classes.colorInfoMain}>40'</span> {accountData.assigned['40']}
        </Typography>
        <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined}>
          <span className={classes.colorInfoMain}>20'</span> {accountData.assigned['20']}
        </Typography>
      </TableCell>
      <TableCell>
        {/* note when check  penalties fee*/}
        <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined}>
          {accountData.toTotalPenalty > 0 ? `#${accountData.toTotalPenalty}` : '-'}
        </Typography>
        {true && <Typography className={accountData.user.status === "frozen" ? classes.colorFrozen : undefined}>
          {accountData.penalties > 0 ? `$${accountData.penalties}` : "-"}
        </Typography>}
      </TableCell>
      <TableCell >
        {accountData.user.status === "frozen" ? <Box textAlign="center">
          <Button onClick={handleActiveAccount} size="large" color="warning" variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#FFB400' }}>
            Activate
          </Button>
        </Box> : <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={7} textAlign="left" >
            <Box whiteSpace="pre-line">
              <Typography>
                <span className={classes.colorInfoMain}>CO²</span> {accountData.savings.kgO2Saving.toFixed(1)}
              </Typography>
              <Typography>
                <span className={classes.colorInfoMain}>Km</span> {accountData.savings.kmSaving.toFixed(1)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5} textAlign="right">
            <IconButton className={classes.arrowButton} size="medium" onClick={moreInfoClient}>
              <ArrowIcon className={classes.arrowIcon} />
            </IconButton>
          </Grid>
        </Grid>}
      </TableCell>
    </TableRow>
  );
};

const AccountTable = ({ accountTableData }: { accountTableData: AccountData[] }) => {
  const classes = accountTableStyles();
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentAccountTableData, setCurrentAccountTableData] = useState<AccountData[]>([]);

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0);
    setItemsPerPage(Number(value));
  };

  useEffect(() => {
    setTotalItems(accountTableData.length);
    setCurrentAccountTableData(accountTableData.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage));
  }, [accountTableData, page, itemsPerPage, setCurrentAccountTableData, setTotalItems]);
  return (
    <Box className={classes.container} display="flex" flexDirection="column" flex={1} minHeight={0}>
      <TableContainer sx={{ flex: 1, minHeight: 0 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <AccountTabHeader />
          </TableHead>
          <TableBody>
            {currentAccountTableData.map((accountData, index) => (
              <AccountRow key={index} accountData={accountData} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{ root: classes.tablePagination }}
        component="div"
        count={totalItems}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={itemsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default AccountTable;
