import { Alert, AlertTitle, Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { marginBottom: theme.spacing(2) },
  })
);

const AccountPending = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Alert severity="info">
        <AlertTitle>Your Account has been created, but requires approval</AlertTitle>
        An approval request email has been sent, and you should be contacted soon.
      </Alert>
    </Box>
  );
};

export default AccountPending;
