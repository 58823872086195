import React, { useCallback, useEffect, useRef, useState } from 'react';
import { REACT_APP_API_KEY } from '../env';
const GoogleMapsContext = React.createContext<{
  placesService: any;
  autocompleteService: any;
}>({
  placesService: {},
  autocompleteService: {},
});
export default GoogleMapsContext;

export interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
export interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id?: string;
}
export interface PlaceResult {
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
}

export const componentRestrictions = { country: ['nz'] };

export const GoogleMapsProvider = ({ children }: { children: React.ReactNode }) => {
  const loaded = useRef(false);
  const [placesRef, setPlacesRef] = useState<HTMLDivElement | null>(null);
  const [autocompleteService, setAutocompleteService] = useState<any>();
  const [placesService, setPlacesService] = useState<any>();

  const loadScript = useCallback(
    (src: string, position: HTMLElement | null, id: string) => {
      if (!position) {
        return;
      }

      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('id', id);
      script.src = src;
      script.onload = () => {
        if ((window as any).google && placesRef) {
          setPlacesService(new (window as any).google.maps.places.PlacesService(placesRef));
        }
        if ((window as any).google) {
          setAutocompleteService(new (window as any).google.maps.places.AutocompleteService());
        }
      };
      position.appendChild(script);
    },
    [placesRef]
  );

  useEffect(() => {
    if (placesRef) {
      if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_API_KEY}&libraries=geometry,places`,
            document.querySelector('head'),
            'google-maps'
          );
        }
        loaded.current = true;
      }
    }
  }, [loadScript, placesRef]);

  return (
    <GoogleMapsContext.Provider value={{ placesService, autocompleteService }}>
      <div ref={setPlacesRef}></div>
      {children}
    </GoogleMapsContext.Provider>
  );
};
