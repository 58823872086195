import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import ModalContext, { ConfirmationServiceContext } from '../../context/modal';
import { WhiteCheckbox, WhiteInput, WhiteLink } from './AppModals';
import FirebaseContext from '../../context/firebase';
import firebase from 'firebase/app';
import useHandleError from '../../hooks/useHandleError';
import useValidators from '../../hooks/useValidators';
import SnackBarContext from '../../context/snackbar';
import useAccountsApiRoutes from '../../hooks/api/useAccountsApiRoutes';

type Props = {
  // onClose: (
  //   event: {},
  //   reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'
  // ) => void;
};
interface MyFormValues {
  email: string;
  password: string;
  keepMeLoggedIn: boolean;
}

const LoginModal = (props: Props) => {
  // const { onClose } = props;
  const classes = useStyles();
  const { passwordReset } = useAccountsApiRoutes();
  const { setDrawerOpen } = useContext(ModalContext);
  const { firebaseApp } = useContext(FirebaseContext);
  const { showSnack } = useContext(SnackBarContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const handleError = useHandleError();
  const { validateEmail } = useValidators();

  const initialValues: MyFormValues = {
    email: '',
    password: '',
    keepMeLoggedIn: false,
  };
  const login = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    if (firebaseApp) {
      const persistence = values.keepMeLoggedIn
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION;
      firebaseApp
        .auth()
        .setPersistence(persistence)
        .then(() => firebaseApp.auth().signInWithEmailAndPassword(values.email, values.password))
        .then(() => {
          setSubmitting(false);
          setDrawerOpen(undefined);
        })
        .catch((error) => {
          setSubmitting(false);
          handleError(error);
        });
    }
  };

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Typography variant="h4">Login</Typography>
      <Formik initialValues={initialValues} validate={validateEmail} onSubmit={login}>
        {({ values, handleChange, handleSubmit, isSubmitting, isValid }) => {
          const handleForgotPassword = async () => {
            if (!values.email) {
              showSnack('Please enter an email address to send a link to', 'error');
            } else {
            }
            const res = await showConfirmationModal({
              title: 'Forgot your Password',
              contentText: `Send forgot password link to ${values.email}?`,
            });
            if (res && res.confirmed === true) {
              try {
                await passwordReset(values.email);
                setOpenId(undefined);
                showSnack('Please check your email for further instructions', 'success');
              } catch (error) {
                setOpenId(undefined);
              }
            }
          };
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <WhiteInput label="Email" type="email" name="email" />
              <WhiteInput label="Password" type="password" name="password" />
              <WhiteCheckbox
                label="Keep me logged in"
                checked={values.keepMeLoggedIn}
                onChange={handleChange}
                name="keepMeLoggedIn"
              />
              <Box display="flex" marginTop={3}>
                <Button
                  variant="contained"
                  size="large"
                  classes={{ root: classes.loginButtonRoot }}
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Login
                </Button>
                <Button
                  classes={{ root: classes.createButtonRoot }}
                  variant="outlined"
                  size="large"
                  onClick={() => setDrawerOpen('signup')}
                >
                  Create New Account
                </Button>
              </Box>

              <Box display="flex" flex={1} alignItems="flex-end" justifyContent="center">
                <WhiteLink onClick={handleForgotPassword}>Forgot my password</WhiteLink>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default LoginModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginButtonRoot: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.info.main,
      marginRight: theme.spacing(2.25),
    },
    createButtonRoot: {
      backgroundColor: 'unset',
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  })
);
