import { Box, Grid, Link, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useState } from 'react';
import SnackBarContext from '../../../context/snackbar';
import SaveIcon from '@mui/icons-material/Save';
import { ClientForm } from './ClientForm';
import { Client } from '../../../../types';
import useClientApiRoutes from '../../../hooks/api/useClientApiRoutes';
import startCase from 'lodash/startCase';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import { BlackButton, SubmitButton } from '../../../components/Form';
import useValidators, { ClientFormValues } from '../../../hooks/useValidators';
import { ConfirmationServiceContext } from '../../../context/modal';
import UserContext from '../../../context/user';
import useOwnerId from '../../../hooks/useOwnerId';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
    statusIcon: {
      marginRight: theme.spacing(0.5),
    },
    actionLink: {
      marginLeft: theme.spacing(3),
    },
  })
);

export const ClientTable = () => {
  const { clients, setClients } = useContext(UserContext);
  const ownerId = useOwnerId();

  const handleClientUpdate = (client: Client) => {
    setClients((curClients) => {
      const index = curClients.findIndex((c) => c._id === client._id);
      if (index !== -1) {
        curClients.splice(index, 1, client);
      }
      return [...curClients];
    });
  };
  const handleClientDelete = (clientId: string) => {
    setClients((curClients) => {
      return curClients.filter((c) => c._id !== clientId);
    });
  };

  return (
    <Box mb={3}>
      <Typography variant="subtitle1" color="primary">
        Our Sites
      </Typography>
      {clients
        .filter((c) => c.user_id === ownerId && c.clientType !== 'user-fineprint')
        .map((client) => {
          return (
            <ClientRow
              onClientUpdate={handleClientUpdate}
              onClientDelete={handleClientDelete}
              key={client._id}
              client={client}
            />
          );
        })}
    </Box>
  );
};

const ClientRow = ({
  client,
  onClientUpdate,
  onClientDelete,
}: {
  client: Partial<Client>;
  onClientUpdate: (client: Client) => void;
  onClientDelete: (clientId: string) => void;
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const { updateClient, deleteClient } = useClientApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);

  const { validateClient } = useValidators();
  const edit = () => {
    setEditing(true);
  };
  const remove = async () => {
    const res = await showConfirmationModal({
      title: 'Remove Site?',
      contentText: 'Do you wish to permanently delete this site from your database?',
    });

    if (res && res.confirmed === true && client._id) {
      const deletedClient = await deleteClient(client._id);
      showSnack('Site Deleted!', 'success');
      onClientDelete(deletedClient._id);
      setEditing(false);
      setOpenId(undefined);
    }
  };
  const save = (values: ClientFormValues, { setSubmitting, resetForm }: FormikHelpers<ClientFormValues>) => {
    updateClient(values)
      .then((client) => {
        showSnack('Site Updated!', 'success');
        resetForm();
        onClientUpdate(client);
        setEditing(false);
      })
      .catch(() => setSubmitting(false));
  };

  return editing ? (
    <Formik initialValues={client} validate={validateClient} onSubmit={save}>
      {({ handleSubmit, isSubmitting, isValid, dirty, values, initialValues }) => {
        const handleCancel = () => {
          setEditing(false);
        };

        return (
          <form onSubmit={handleSubmit}>
            <ClientForm />
            <SubmitButton startIcon={<SaveIcon />}>Save Edits</SubmitButton>
            <Box ml={1} display="inline">
              <BlackButton startIcon={<Close />} onClick={handleCancel}>
                Cancel
              </BlackButton>
            </Box>
          </form>
        );
      }}
    </Formik>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="body2">{client.companyName}</Typography>
        <Typography variant="body2" color="textSecondary">
          {client.address}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <DocStatus completed={(client.healthAndSafetyDocuments?.length || 0) > 0} text="H&S" />
          </Grid>
          <Grid item>
            <DocStatus completed={(client.siteInstructionsDocuments?.length || 0) > 0} text="Site Instructions" />
          </Grid>
          <Grid item>
            <DocStatus completed={(client.siteMapDocuments?.length || 0) > 0} text="Site Plan" />
          </Grid>
          <Grid item>
            <Typography variant="body2">{startCase(client.clientType)}</Typography>
          </Grid>
          <Grid item xs>
            <Grid container justifyContent="flex-end">
              <Link component="button" variant="body1" type="button" className={classes.actionLink} onClick={edit}>
                Edit
              </Link>
              <Link
                className={classes.actionLink}
                component="button"
                variant="body1"
                color="error"
                type="button"
                onClick={remove}
                underline="always"
              >
                Remove
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DocStatus = ({ completed, text }: { completed: boolean; text: string }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      {completed ? (
        <Done color="primary" className={classes.statusIcon} />
      ) : (
        <Close color="error" className={classes.statusIcon} />
      )}
      <Typography variant="body2" color={completed ? 'textPrimary' : 'textSecondary'}>
        {text}
      </Typography>
    </Grid>
  );
};
