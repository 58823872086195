import React, { useContext, useEffect } from 'react';
import UserContext from '../context/user';
import NavBarAdmin from './super-admin/NavBarAdmin';
import NavBarClient from './client/NavBarClient';
import { useHistory, useLocation } from 'react-router-dom';

const NavBar = () => {
  const { isLoggedIn, user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const basePath = location.pathname.split('/', 3).join('/'); // only keep the top level path e.g. /settings


  useEffect(() => {
    if (isLoggedIn && user && user.roles?.includes('ADMIN')) {
      if (basePath.includes('/super-admin')) {
        history.push(basePath)
      } else history.push('/super-admin');
    }
  }, [isLoggedIn, user, basePath, history]);
  return <>{isLoggedIn && user && user.roles?.includes('ADMIN') ? <NavBarAdmin /> : <NavBarClient />}</>;
};

export default NavBar;
