import { useContext } from 'react';
import { AttachedImage, Client, Container, PointSchema } from '../../types';
import UserContext from '../context/user';
import { differenceInDays, format, isPast, isToday } from 'date-fns';

export default function useUtils() {
  const formattedDate = (date: Date) => {
    return format(date, 'd MMM yyyy');
  };
  const {
    constants: { containerTypes, shippingLines },
    clients,
  } = useContext(UserContext);
  const addressNoStreet = (address: string) => {
    const addressArray = address.split(',');
    if (addressArray.length > 1) {
      addressArray.splice(0, 1); //remove the first element in the array (the street address)
    }
    return addressArray.join(', ');
  };
  const addressSuburb = (address: string) => {
    const addressArray = address.split(',');
    return addressArray.length > 1 ? addressArray[1] : address;
  };
  const getContainerTypeName = (container: Container) => {
    const containerType = containerTypes.find((c) => c.code === container.containerTypeCode);
    return containerType?.name || container.containerTypeCode;
  };
  const getShippingLine = (container: Container) => {
    return shippingLines.find((s) => s.code === container.shippingLineCode);
  };
  const getShippingLineName = (container: Container) => {
    const shippingLine = getShippingLine(container);
    return shippingLine?.name || container.shippingLineCode;
  };
  const getImages = (images: AttachedImage[], size: string): AttachedImage[] => {
    const nonDeletedImages = images.filter((i) => i._deleted !== true);
    let filteredImages = nonDeletedImages.filter((i) => i.size === size);
    if (filteredImages.length === 0 && nonDeletedImages.length > 0) {
      // get the first size that is available
      const availableSize = nonDeletedImages[0].size;
      filteredImages = nonDeletedImages.filter((i) => i.size === availableSize);
    }
    return filteredImages.length > 0 ? filteredImages : [];
  };
  const getDaysAvailableText = (container: Container | undefined) => {
    if (container) {
      const availableFrom = new Date(new Date(container.availableFrom).setHours(0, 0, 0, 0));
      const availableTo = new Date(new Date(container.availableTo).setHours(23, 59, 59, 0));
      const datesAvailableText = `${isToday(availableFrom) ? 'Today' : formattedDate(availableFrom)} - ${formattedDate(
        new Date(availableTo)
      )}`;

      const daysAvailable = differenceInDays(availableTo, new Date());
      const daysLeftText = isPast(availableFrom) ? `${daysAvailable + 1} Days Left` : 'Coming Soon';
      return { datesAvailableText, daysLeftText, daysAvailable };
    } else return { datesAvailableText: '', daysLeftText: '', daysAvailable: 0 };
  };

  const calcDistanceBetween = (fromPoint: PointSchema, toPoint: PointSchema): number => {
    const computeDistanceBetween = (window as any).google.maps.geometry.spherical.computeDistanceBetween;
    let distanceBetween = 0;
    const from =
      fromPoint && fromPoint.coordinates
        ? new (window as any).google.maps.LatLng(fromPoint.coordinates[1], fromPoint.coordinates[0])
        : undefined;
    const to =
      toPoint && toPoint.coordinates
        ? new (window as any).google.maps.LatLng(toPoint.coordinates[1], toPoint.coordinates[0])
        : undefined;
    if (from && to) {
      distanceBetween = computeDistanceBetween(from, to);
    }
    return distanceBetween;
  };

  const getEnvSavingsText = (container: Container, client: Client | undefined) => {
    let savingsText = '';
    if (container.status === 'pending') {
      savingsText = 'Pending';
    } else if (container.goingTo) {
      const { kmSaved, kgSaved } = container.environmentalSavings
        ? container.environmentalSavings
        : { kmSaved: 0, kgSaved: 0 };
      savingsText = `${kgSaved.toFixed(1)} kg co²\n${kmSaved.toFixed(1)} km`;
    } else {
      // goingTo not assigned yet, so don't output any text
      savingsText = '';
    }
    return savingsText;
  };

  const getPenaltyDollarFee = (container: Container | undefined) => {
    const containerType = containerTypes.find((c) => c.code === container?.containerTypeCode);
    return containerType?.futileTripPenalty || 0;
  };

  const isStackedByMe = (container: Container) => clients.map((c) => c._id).includes(container.client_id);

  return {
    addressNoStreet,
    addressSuburb,
    getContainerTypeName,
    getShippingLineName,
    getDaysAvailableText,
    getImages,
    getEnvSavingsText,
    calcDistanceBetween,
    formattedDate,
    isStackedByMe,
    getPenaltyDollarFee,
  };
}
