import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback, useContext } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { Container } from '../../types';
import {
  ClientSelectAsync,
  ContainerTypeSelectAsync,
  ShippingLineSelectAsync,
  SubmitButton,
  ThemedDatePicker,
  ThemedRadioCheck,
  ThemedTextField,
  ThemedTimePicker,
} from './Form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import useContainerApiRoutes from '../hooks/api/useContainerApiRoutes';
import SnackBarContext from '../context/snackbar';
import { ImageUpload } from './FileUploads';
import { LocationSelector } from '../pages/Landing/LocationSelector';
import UserContext from '../context/user';

type Props = DrawerProps & {
  open: boolean;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick' | 'containersAdded') => void;
};

export interface MyFormValues {
  containers: Partial<Container>[];
}

const AddContainerDrawer = (props: Props) => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { createContainers } = useContainerApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { user } = useContext(UserContext);
  const canAddGoingTo =
    user?.accountTypes?.includes('exporter') || user?.accountTypes?.includes('freight-forwarder') ? true : false;

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues | undefined> = {};
    values.containers.forEach((container, index) => {
      (
        [
          'shippingLineCode',
          'containerTypeCode',
          'containerCode',
          'availableFrom',
          'availableTo',
          'client_id',
        ] as (keyof Container)[]
      ).forEach((requiredField) => {
        if (!container[requiredField]) {
          set(errors, `containers.${index}.${requiredField}`, 'Required');
        }
      });

      if (container.inStack && !container.cleanAndAcessible) {
        set(errors, `containers.${index}.cleanAndAcessible`, '*Cannot Stack unless confirmed');
      }
    });
    return errors;
  };
  const save = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    createContainers(values.containers)
      .then(() => {
        showSnack('Containers Created!', 'success');
        onClose({}, 'containersAdded');
      })
      .catch(() => setSubmitting(false));
  };
  const initContainer = useCallback(
    (): Partial<Container> => ({
      shippingLineCode: '',
      containerTypeCode: '',
      containerCode: '',
      availableFrom: '',
      availableTimeFrom: '',
      availableTo: '',
      collectionTime: '',
      client_id: '',
      cleanAndAcessible: false,
      photos: [],
      inStack: false,
    }),
    []
  );
  const initialValues: { containers: Partial<Container>[] } = { containers: [initContainer()] };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: classes.drawerPaper }}>
      <Box className={classes.margins}>
        <IconButton classes={{ root: classes.close }} size="medium" onClick={(e) => onClose({}, 'closeButtonClick')}>
          <CloseIcon fontSize="large" color="primary" />
        </IconButton>

        <Box mb={3} pl={2}>
          <Typography variant="h4" color="primary">
            Add Container/s to the Virtual Stack
          </Typography>
        </Box>
        <Formik initialValues={initialValues} validate={validate} onSubmit={save}>
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
            const addRow = () => {
              const { containers } = values;
              setFieldValue('containers', [...containers, initContainer()]);
            };
            const replicateLast = () => {
              const { containers } = values;
              if (containers.length > 0) {
                // must use cloneDeep so that file object is cloned properly
                setFieldValue('containers', [...containers, cloneDeep(containers[containers.length - 1])]);
              }
            };

            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <TableContainer sx={{ flex: 1, minHeight: 0, paddingBottom: 1, marginLeft: -8 }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}></TableCell>
                        <TableHeader sx={{ minWidth: 145 }} className={classes.tableHeader} label="SHIPPING LINE" />
                        <TableHeader sx={{ minWidth: 145 }} className={classes.tableHeader} label="CONTAINER TYPE" />
                        <TableHeader sx={{ minWidth: 145 }} className={classes.tableHeader} label="CONTAINER #" />
                        <TableHeader
                          sx={{ minWidth: 145 }}
                          className={classes.tableHeader}
                          label="DATE AVAILABLE FROM"
                        />
                        <TableHeader sx={{ minWidth: 145 }} className={classes.tableHeader} label="DATE AVAILABLE TO" />
                        <TableHeader className={classes.tableHeader} label="AVAILABLE FROM" />
                        <TableHeader className={classes.tableHeader} label="CLIENT / LOCATION FROM" />
                        <TableHeader className={classes.tableHeader} label="CLEAN & ACCESSIBLE" center={true} />
                        <TableHeader className={classes.tableHeader} label="UPLOAD PHOTO" center={true} />
                        {canAddGoingTo ? (
                          <>
                            <TableHeader className={classes.tableHeader} label="ADD TO STACK" center={true} />
                            <TableHeader className={classes.tableHeader} label="CLIENT / LOCATION TO" />
                          </>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.containers.map((container, index) => {
                        return (
                          <ContainerRow key={index} container={container} index={index} canAddGoingTo={canAddGoingTo} />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box pl={2}>
                  <Button
                    disabled={isSubmitting || values.containers.length === 0}
                    onClick={replicateLast}
                    startIcon={<AddIcon />}
                    size="large"
                    variant="outlined"
                    color="primary"
                    sx={{ marginRight: 1 }}
                  >
                    Replicate
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={addRow}
                    startIcon={<AddIcon />}
                    size="large"
                    variant="outlined"
                    color="secondary"
                    sx={{ marginRight: 1 }}
                  >
                    Add New
                  </Button>
                  <SubmitButton>Submit</SubmitButton>
                </Box>
              </form>
            );
          }}
        </Formik>
        <Box mt={4} display="flex">
          <InfoIcon color="primary" />
          <Typography variant="subtitle2" fontWeight={400} fontStyle="italic" color="textSecondary" ml={1}>
            Fineprint about confirming containers are clean and adding photo evidence for any future disputes. Prompt to
            confirm user accepts the Terms & Conditions for submitting a container into the VirtualStack™
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

const ContainerRow = ({
  container,
  index,
  canAddGoingTo,
}: {
  container: Partial<Container>;
  index: number;
  canAddGoingTo: boolean;
}) => {
  const { values, setFieldValue, handleChange } = useFormikContext<MyFormValues>();

  const handleRemoveRow = () => {
    const { containers } = values;
    setFieldValue(
      'containers',
      containers.filter((c, i) => i !== index)
    );
  };

  const handleInStackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setFieldValue(`containers.${index}.goingTo`, '');
    }
    handleChange(event);
  };

  return (
    <TableRow>
      <TableCell>
        <Fab disabled={values.containers.length <= 1} color="primary" size="small" onClick={handleRemoveRow}>
          <CloseIcon />
        </Fab>
      </TableCell>
      <TableCell>
        <ShippingLineSelectAsync name={`containers.${index}.shippingLineCode`} variant="standard" />
      </TableCell>
      <TableCell>
        <ContainerTypeSelectAsync name={`containers.${index}.containerTypeCode`} variant="standard" />
      </TableCell>
      <TableCell>
        <ThemedTextField name={`containers.${index}.containerCode`} variant="standard" placeholder="Code" />
      </TableCell>
      <TableCell>
        <ThemedDatePicker name={`containers.${index}.availableFrom`} />
      </TableCell>
      <TableCell>
        <ThemedDatePicker name={`containers.${index}.availableTo`} />
      </TableCell>
      <TableCell>
        <ThemedTimePicker name={`containers.${index}.availableTimeFrom`} />
      </TableCell>
      <TableCell>
        <ClientSelectAsync name={`containers.${index}.client_id`} />
      </TableCell>
      <TableCell align="center">
        <ThemedRadioCheck name={`containers.${index}.cleanAndAcessible`} />
      </TableCell>
      <TableCell align="center">
        <ImageUpload name={`containers.${index}.photos`} />
      </TableCell>
      {canAddGoingTo ? (
        <>
          <TableCell align="center">
            <ThemedRadioCheck onChange={handleInStackChange} name={`containers.${index}.inStack`} />
          </TableCell>
          <TableCell>
            {values.containers[index].inStack === false ? (
              <LocationSelector
                placeholder="Start typing to select Location Going To"
                sx={{ minWidth: 250 }}
                name={`containers.${index}.goingTo`}
              />
            ) : null}
          </TableCell>
        </>
      ) : null}
    </TableRow>
  );
};

const TableHeader = ({
  label,
  center = false,
  ...otherProps
}: { label: string; center?: boolean } & TableCellProps) => {
  return (
    <TableCell sx={{ minWidth: 50 }} {...otherProps}>
      <Typography
        noWrap={!center}
        variant="overline"
        lineHeight={1.5}
        color="textSecondary"
        align={center ? 'center' : 'inherit'}
        display="block"
      >
        {label}
      </Typography>
    </TableCell>
  );
};

export default AddContainerDrawer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    margins: {
      marginTop: theme.spacing(16),
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(14),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: `calc(100% - ${theme.spacing(19)})`,
    },
    tableHeader: {
      backgroundColor: theme.palette.grey[100],
    },
    drawerPaper: {
      backgroundColor: theme.palette.grey[100],
      width: 'calc(100% - 196px)',
      // position: 'relative',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
    },
  })
);
