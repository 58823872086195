import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { ThemedTextField } from './Form';
import { useFormikContext } from 'formik';
import { useContext, useEffect, useRef } from 'react';
import get from 'lodash/get';
import { PropsOf } from '@emotion/react';
import GoogleMapsContext, { componentRestrictions, PlaceResult, PlaceType } from '../context/googleMaps';
import { debounce } from 'lodash';

export default function PlacesAutoComplete({
  name,
  label,
  pointSchemaName,
  ...otherProps
}: Omit<PropsOf<typeof Autocomplete>, 'renderInput' | 'options' | 'onChange'> & {
  name: string;
  label: string;
  pointSchemaName: string;
}) {
  const { placesService, autocompleteService } = useContext(GoogleMapsContext);
  const [inputValue, setInputValue] = React.useState('');
  const { values, handleChange } = useFormikContext();
  const formValue = get(values, name, '');
  const defaultValue: PlaceType = useRef({
    description: formValue,
    structured_formatting: {
      main_text: formValue,
      secondary_text: '',
      main_text_matched_substrings: [],
    },
  }).current;
  const [value, setValue] = React.useState<PlaceType | null>(defaultValue);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const getPredictions = React.useMemo(
    () =>
      debounce(async (request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        const { predictions } = await autocompleteService.getPlacePredictions({ ...request, componentRestrictions });
        return callback(predictions);
      }, 500),
    [autocompleteService]
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getPredictions({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, getPredictions]);

  const handleAutoCompleteChange = (event: any, newValue: PlaceType | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    handleChange({ target: { name, value: newValue?.description } });
    if (newValue?.place_id && placesService) {
      placesService.getDetails(
        { placeId: newValue?.place_id, fields: ['geometry.location'] },
        (placeResult: PlaceResult) => {
          handleChange({
            target: {
              name: pointSchemaName,
              value: {
                type: 'Point',
                coordinates: [placeResult.geometry.location.lng(), placeResult.geometry.location.lat()],
              },
            },
          });
        }
      );
    } else {
      handleChange({
        target: {
          name: pointSchemaName,
          value: {
            type: 'Point',
            coordinates: [0, 0],
          },
        },
      });
    }
  };

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.description)}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={handleAutoCompleteChange as any}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <ThemedTextField {...params} name={name} label={label} fullWidth />}
      renderOption={(props, option: any) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <AutoCompleteOption
            key={props.id}
            listElemProps={props}
            parts={parts}
            secondaryText={option.structured_formatting.secondary_text}
          />
        );
      }}
      {...otherProps}
    />
  );
}

export type Part = {
  text: string;
  highlight: boolean;
};
export const AutoCompleteOption = ({
  listElemProps,
  parts,
  secondaryText,
}: {
  listElemProps: React.HTMLAttributes<HTMLLIElement>;
  parts: Part[];
  secondaryText: string;
}) => {
  return (
    <li {...listElemProps}>
      <Grid container alignItems="center">
        <Grid item>
          <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="text.secondary">
            {secondaryText}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};
