import React, { useMemo, useState } from 'react';
import { TextField, TextFieldProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useGreyOutlinedStyles } from './Form';
import { useFormikContext } from 'formik';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 30,
    },
    input: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    "grey": {
      backgroundColor: theme.palette.grey[200],
    },
    "white": {
      backgroundColor: "white",
    }
  })
);

export const Search = ({ onChange, bgColor = "grey", name, closeBtn, ...otherProps }: { bgColor?: "white" | "grey", closeBtn?: boolean } & TextFieldProps & Required<Pick<TextFieldProps, 'onChange'>>) => {
  const [userQuery, setUserQuery] = useState('');
  const delayedQuery = useMemo(() => debounce(onChange, 500), [onChange]);
  const classes = useStyles();
  const outlinedStyles = useGreyOutlinedStyles();
  const { handleChange } = useFormikContext();
  const handleSearchChange = (e: any) => {
    setUserQuery(e.target.value);
    delayedQuery(e);
    handleChange(e);
  };
  const handleClose = (e: any) => {
    handleSearchChange({ target: { value: "" } });
  }
  return (
    <TextField
      name={name ? name : "searchText"}
      variant="outlined"
      value={userQuery}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: <SearchIcon sx={{ marginRight: 1 }} />,
        classes: { ...outlinedStyles, root: `${outlinedStyles.root} ${classes.root} ${classes[bgColor]}`, input: classes.input },
        endAdornment: (closeBtn && userQuery) ? <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} /> : undefined
      }}
      {...otherProps}
    />
  );
};
