import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ContainerPreferences from './Settings/ContainerPreferences';
import EditProfile from './Settings/EditProfile';
import EditAccount from './Settings/EditAccount';
import OurSites from './Settings/OurSites';
import OurTeammates from './Settings/OurTeammates';
import OurReports from './Settings/OurReports';
import Fineprint from './Settings/Fineprint';
import UserContext from '../context/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
    navlink: {
      fontFamily: 'Oxygen',
      fontSize: 16,
      display: 'block',
      textDecoration: 'none',
      paddingTop: theme.spacing(2.75),
      paddingBottom: theme.spacing(2.75),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.text.secondary,
      '&:visited': { color: 'unset' },
      minWidth: '250px',
    },
    activeLink: {
      color: theme.palette.primary.main,
      '&:visited': { color: theme.palette.primary.main },
    },
    greyTitle: {
      color: theme.palette.grey[500],
      fontWeight: 400,
    },
    divider: {
      margin: 0,
    },
  })
);

const Settings = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  let { path, url } = useRouteMatch();
  let location = useLocation();

  const settingRoutes = useMemo(
    () =>
      [
        { name: 'Edit Profile', path: 'edit-profile', component: EditProfile, visible: true },
        { name: 'Edit Account', path: 'edit-account', component: EditAccount, visible: true },
        {
          name: 'Our Sites',
          path: 'our-sites',
          component: OurSites,
          visible: true,
        },
        { name: 'Our Teammates', path: 'our-teammates', component: OurTeammates, visible: true },
        {
          name: 'Fineprint',
          path: 'fineprint',
          component: Fineprint,
          visible: user?.accountTypes?.includes('freight-forwarder') || user?.accountTypes?.includes('importer'),
        },
        {
          name: 'Container Preferences',
          path: 'container-preferences',
          component: ContainerPreferences,
          visible: true,
        },
        { name: 'Our Reports', path: 'our-reports', component: OurReports, visible: true },
      ].filter((r) => r.visible),
    [user?.accountTypes]
  );

  const [activeRoute, setActiveRoute] = useState(settingRoutes[0]);

  useEffect(() => {
    const settingPath = location.pathname.replace(`${path}/`, '');
    const settingRoute = settingRoutes.find((sr) => sr.path === settingPath);
    if (settingRoute) {
      setActiveRoute(settingRoute);
    }
  }, [location, path, settingRoutes]);

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" color="primary">
            Settings <span className={classes.greyTitle}>/ {activeRoute.name}</span>
          </Typography>
        </Grid>
      </Grid>

      <Box display="flex" flexDirection="row" my={5}>
        <Box>
          {settingRoutes.map((route, index) => {
            const isActive = route === activeRoute;
            const divider = index < settingRoutes.length - 1;
            return (
              <React.Fragment key={route.path}>
                <Link
                  to={`${url}/${route.path}`}
                  className={`${classes.navlink} ${isActive ? classes.activeLink : ''}`}
                >
                  {route.name}
                </Link>
                {divider ? <Divider className={classes.divider} /> : null}
              </React.Fragment>
            );
          })}
        </Box>
        <Box flex={1}>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/${settingRoutes[0].path}`} />
            </Route>
            {settingRoutes.map((route) => {
              var SettingsComponent = route.component || undefined;
              return (
                <Route key={route.path} path={`${path}/${route.path}`}>
                  {SettingsComponent ? <SettingsComponent /> : <h3>{route.name}</h3>}
                </Route>
              );
            })}
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
