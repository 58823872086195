import { Box, Drawer, DrawerProps, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import InfoMainLogo from '../../images/InfoMainLogo.svg';
import SecondaryMainLogo from '../../images/SecondaryMainLogo.svg';
import { DrawerType } from '../../../types';
// import OpaqueLogo from '../../images/OpaqueLogo.png';
// import OpaqueLogo2 from '../../images/OpaqueLogo2.png';

type Props = DrawerProps & {
  // open: boolean;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void;
  modalType?: 'infoMain' | 'secondaryMain' | 'primaryLight' | 'infoMainInverted' | 'transparent';
  drawerOpen?: DrawerType;
};

const AppDrawer = (props: Props) => {
  const theme = useTheme();
  const { open, onClose, children, modalType = 'infoMain', drawerOpen } = props;
  const classes = useStyles();
  const modalLogoMap = useRef({
    infoMain: { imageSrc: InfoMainLogo, textColor: theme.palette.common.white },
    infoMainInverted: { imageSrc: undefined, textColor: theme.palette.info.main },
    secondaryMain: { imageSrc: SecondaryMainLogo, textColor: theme.palette.secondary.main },
    primaryLight: { imageSrc: undefined, textColor: theme.palette.primary.light },
    transparent: { imageSrc: undefined, textColor: theme.palette.primary.main },
  }).current;

  const { imageSrc, textColor } = modalLogoMap[modalType];

  return (
    <Drawer
      hideBackdrop={drawerOpen === 'login' ? true : false}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes[modalType] }}
    >
      <Box color={textColor} flex={1} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="flex-end" position="relative">
          <IconButton size="medium" onClick={(e) => onClose({}, 'closeButtonClick')}>
            <CloseIcon fontSize="large" style={{ color: textColor }} />
          </IconButton>
        </Box>
        <Box padding={8} paddingBottom={4} flex={1} display="flex" justifyContent="space-evenly">
          {children}
        </Box>
        {imageSrc ? <img src={imageSrc} alt="Logo" className={classes.image} /> : null}
      </Box>
    </Drawer>
  );
};

export default AppDrawer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: -1,
    },
    infoMain: {
      backgroundColor: theme.palette.info.main,
      minWidth: '420px',
    },
    infoMainInverted: {
      backgroundColor: theme.palette.grey[100],
      minWidth: '420px',
    },
    secondaryMain: {
      backgroundColor: theme.palette.grey[100],
      minWidth: '420px',
    },
    primaryLight: {
      backgroundColor: theme.palette.grey[100],
      width: '60%',
      minWidth: '420px',
    },
    transparent: {
      width: '830px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      zIndex: 10000,
    },
  })
);
