import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { BlackButton } from '../../../components/Form';
import UserContext from '../../../context/user';
import Stripe from '../../../images/Stripe.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stripeButton: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    stripeLogo: {
      marginLeft: '5px',
    },
  })
);

export const PaymentDetails = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const disabled = user?.isTeammate;

  return (
    <Grid container>
      <Grid item>
        <Box mb={1}>
          <Typography variant="subtitle1" color="primary">
            PAYMENT DETAILS
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          Setup your payment details by connecting your Stripe account.
        </Typography>
        <BlackButton className={classes.stripeButton} disabled={disabled}>
          <Box display="inline-flex">
            CONNECT TO
            <img src={Stripe} alt="stripe" className={classes.stripeLogo} />
          </Box>
        </BlackButton>
      </Grid>
    </Grid>
  );
};
