import { Box, Grid, Link, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import SnackBarContext from '../../../context/snackbar';
import { User } from '../../../../types';
import useTeammateApiRoutes from '../../../hooks/api/useTeammateApiRoutes';
import { ConfirmationServiceContext } from '../../../context/modal';
import useOwnerId from '../../../hooks/useOwnerId';
import UserContext from '../../../context/user';
import capitalize from 'lodash/capitalize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
    statusIcon: {
      marginRight: theme.spacing(0.5),
    },
    actionLink: {
      marginLeft: theme.spacing(3),
    },
  })
);

export const TeammatesTable = () => {
  const { getTeammates } = useTeammateApiRoutes();
  const { user: currentUser } = useContext(UserContext);
  const ownerId = useOwnerId();
  const [users, setUsers] = useState<User[]>([]);
  const canEdit = !!(currentUser?.roles?.includes('OWNER') || currentUser?.roles?.includes('ADMIN'));

  const loadUsers = useCallback(() => {
    if (ownerId) {
      getTeammates(ownerId).then(({ users }) => {
        // setUsers((curUsers) => [...curUsers, ...users]);
        setUsers([...users]);
      });
    }
  }, [getTeammates, ownerId]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const handleUserDelete = (userId: string) => {
    setUsers((curUsers) => {
      return curUsers.filter((c) => c._id !== userId);
    });
  };

  return (
    <Box mb={3}>
      <Typography variant="subtitle1" color="primary">
        Our Teammates
      </Typography>
      {users.map((user) => {
        return <UserRow canEdit={canEdit} onUserDelete={handleUserDelete} key={user._id} user={user} />;
      })}
    </Box>
  );
};

const UserRow = ({
  canEdit,
  user,
  onUserDelete,
}: {
  canEdit: boolean;
  user: Partial<User>;
  onUserDelete: (userId: string) => void;
}) => {
  const classes = useStyles();
  const { user: currentUser } = useContext(UserContext);
  const isCurrentUser = user._id === currentUser?._id;
  const { deleteTeammate } = useTeammateApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);

  const remove = async () => {
    const res = await showConfirmationModal({
      title: "Remove member's account",
      contentText:
        'Do you wish to remove this user from your team? They will no longer be about to access and edit your Virtual Stack.',
    });
    if (res && res.confirmed === true && currentUser?._id && user._id) {
      if (user.roles?.includes('TEAMMATE')) {
        const deletedUser = await deleteTeammate(currentUser._id, user._id);
        showSnack('Teammate Deleted!', 'success');
        onUserDelete(deletedUser._id);
      } else if (user.roles?.includes('ADMIN')) {
        // TODO remove Admin
        console.log('TODO remove admin user from project');
      }
      setOpenId(undefined);
    }
  };
  const leave = async () => {
    if (currentUser?.roles?.includes('OWNER')) {
      const res = await showConfirmationModal({
        title: 'Leave the Virtual Stack',
        contentText:
          'Do you wish to cancel your Virtual Stack account? By doing so, all data will be removed immediately from the Virtual Stack including all admin accounts. Please contact Support if you wish to transfer ownership to another  staff member in your company.',
      });
      if (res && res.confirmed === true && user._id) {
        // TODO Leave Virtual stack
        console.log('TODO leave!!!');
        // const deletedUser = await deleteUser(user._id);
        // showSnack('User Deleted!', 'success');
        // onUserDelete(deletedUser._id);
        // setEditing(false);
        setOpenId(undefined);
      }
    } else if (currentUser?.roles?.includes('ADMIN')) {
      const res = await showConfirmationModal({
        title: 'Leave the Virtual Stack',
        contentText: 'Do you wish to leave this Virtual Stack account?',
      });
      if (res && res.confirmed === true && user._id) {
        // TODO admin leave Virtual stack
        console.log('TODO leave!!!');
        // const deletedUser = await deleteUser(user._id);
        // showSnack('User Deleted!', 'success');
        // onUserDelete(deletedUser._id);
        // setEditing(false);
        setOpenId(undefined);
      }
    }
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2">
            {user.fullName}
            {isCurrentUser ? (
              <Typography component="span" variant="body2" color="textSecondary">
                {' '}
                (You)
              </Typography>
            ) : null}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {user.email}
          </Typography>
        </Grid>
        <Grid item xs>
          {user.uid ? (
            <Typography variant="body2">{capitalize(user?.roles?.join(', '))}</Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Invitation Sent
            </Typography>
          )}
        </Grid>
        <Grid item xs>
          {canEdit ? (
            <Grid container justifyContent="flex-end">
              {isCurrentUser ? (
                <Link
                  className={classes.actionLink}
                  component="button"
                  variant="body1"
                  type="button"
                  onClick={leave}
                  underline="always"
                >
                  Leave
                </Link>
              ) : (
                <Link
                  className={classes.actionLink}
                  component="button"
                  variant="body1"
                  color="error"
                  type="button"
                  onClick={remove}
                  underline="always"
                >
                  Remove
                </Link>
              )}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
