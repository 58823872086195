import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    textBlur: {
      filter: 'blur(3px)',
    },
  })
);

const BlurredText = () => {
  const classes = useStyles();
  let randomText = (Math.random() + 1).toString(36);
  return <p className={classes.textBlur}>{randomText}</p>;
};

export default BlurredText;
