import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import useConstantsApiRoutes from '../../hooks/api/useConstantsApiRoutes';

type Props = {};

const EnvSavingModal = (props: Props) => {
  const classes = useStyles();
  const { getEnvSavings } = useConstantsApiRoutes();
  const [timeLocalNZ, setTimeLocalNZ] = useState('');
  const [savingValue, setSavingValue] = useState({
    tripsSaved: 0,
    kmSaved: 0,
    kgSaved: 0,
  });

  useEffect(() => {
    const timezoneNz = 13;
    const timezoneOffset = new Date().getTimezoneOffset();
    const timeNowNZ = Date.now() + timezoneOffset * 60 * 1000 + timezoneNz * 60 * 60 * 1000;
    setTimeLocalNZ(format(new Date(timeNowNZ), 'h:mma').toLocaleLowerCase());
    getEnvSavings().then((result) => {
      setSavingValue(result);
    });
  }, [getEnvSavings]);

  return (
    <Box display="flex" sx={{ width: '100%' }}>
      <Box className={classes.constaner} flex={1} display="flex" flexDirection="column">
        <Typography fontWeight="800" color="white" variant="h5">
          THE VIRTUAL STACK IMPACT
        </Typography>
        <Typography fontStyle="italic" color="white">
          as at {timeLocalNZ} NZ local time
        </Typography>
        <Typography className={classes.textCoSave} sx={{ marginTop: '50px' }} fontWeight="800" variant="h2">
          {Math.round(savingValue.kgSaved)}
        </Typography>
        <Typography fontWeight="600" className={classes.textCoSave} variant="h6">
          CO²/kg Saved
        </Typography>
        <Typography sx={{ marginTop: '50px' }} fontWeight="800" variant="h2">
          {Math.round(savingValue.kmSaved)}
        </Typography>
        <Typography fontWeight="600">Km (milage) Saved</Typography>
        <Typography className={classes.textTrip} sx={{ marginTop: '50px' }} fontWeight="800" variant="h2">
          {Math.round(savingValue.tripsSaved)}
        </Typography>
        <Typography fontWeight="600" className={classes.textTrip}>
          Total Trips Saved
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    constaner: {
      backgroundColor: theme.palette.primary.dark,
      maxHeight: '770px',
      width: '100%',
      borderRadius: '24px 0px 0px 24px',
      padding: '60px',
      marginRight: '48%',
      zIndex: 3000,
      positon: '',
      boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    },
    textCoSave: {
      color: '#B2EBF2',
    },
    textKmSave: {
      color: 'rgba(64, 196, 255, 0.7)',
    },
    textTrip: {
      color: 'rgb(197 245 251)',
    },
  })
);

export default EnvSavingModal;
