import { Box, Theme, Typography, Divider, DialogContent } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import firebase from 'firebase/app';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import SnackBarContext from '../../context/snackbar';
import UserContext from '../../context/user';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';
import SaveIcon from '@mui/icons-material/Save';
import FirebaseContext from '../../context/firebase';
import { VerifyMobileForm } from '../../components/Modals/VerifyMobileModal';
import { ConfirmationServiceContext } from '../../context/modal';
import BlockingDialog from '../../components/Modals/BlockingDialog';
import useHandleError from '../../hooks/useHandleError';
import useVerifyPhoneNumber from '../../hooks/useVerifyPhoneNumber';
import { User } from '../../../types';
import { UserProfile } from './EditAccountComponents/UserProfile';
import useValidators from '../../hooks/useValidators';
import { SubmitButton } from '../../components/Form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
    dialogContainer: {
      background: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  })
);

export interface MyFormValues extends Required<Pick<User, 'fullName' | 'email' | 'phoneNumber' | 'password'>> {
  passwordConfirm: string;
}

const EditProfile = () => {
  const { user, saveUser } = useContext(UserContext);
  const { showSnack } = useContext(SnackBarContext);
  const { firebaseApp, currentUser, reloadCurrentUser } = useContext(FirebaseContext);
  const handleError = useHandleError();
  const { updateUser } = useUserApiRoutes();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState<MyFormValues | undefined>();
  const { showBockingModal, openId, setOpenId } = useContext(ConfirmationServiceContext);
  const { verifyPhoneNumber } = useVerifyPhoneNumber();
  const { isEmailValid, validatePasswords } = useValidators();

  const passwordChanged = useRef(false);
  const newPassword = useRef('');
  const newEmail = useRef<User['email']>('');

  useEffect(() => {
    if (user) {
      setInitialValues({
        fullName: user?.fullName || '',
        email: user?.email || '',
        phoneNumber: user?.phoneNumber || '',
        password: '',
        passwordConfirm: '',
      });
    }
  }, [user]);

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = { ...validatePasswords(values) };
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmailValid(values.email)) {
      errors.email = 'Invalid email address';
    } else if (!values.phoneNumber) {
      errors.phoneNumber = 'Required';
    } else if (values.password === '' && !user?.providerData?.find((pd) => pd?.providerId === 'password')) {
      errors.password = 'Password requried to be set before initial login';
    }
    return errors;
  };

  const saveUserToDb = async (values: MyFormValues) => {
    const updatedUser = await updateUser({ ...values, uid: user?.uid });
    saveUser(updatedUser);
    showSnack('Profile saved!', 'success');
  };

  const save = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    // must run async in a function otherwise setSubmitting will not work. See https://github.com/formium/formik/issues/2442
    async function runAsync() {
      try {
        const fullNameChanged = values.fullName !== user?.fullName;
        const emailChanged = values.email !== user?.email;
        const phoneNumberChanged = values.phoneNumber !== user?.phoneNumber;
        passwordChanged.current = Boolean(values.password && values.passwordConfirm);
        newPassword.current = values.password; // save so that is is not lost in a re-render
        newEmail.current = values.email; // save so that is is not lost in a re-render
        if (
          currentUser &&
          user &&
          user.phoneNumber &&
          values.phoneNumber &&
          values.email &&
          (emailChanged || phoneNumberChanged || passwordChanged.current)
        ) {
          await verifyPhoneNumber(values.phoneNumber, values.email);
        }
        if (phoneNumberChanged) {
          await showBockingModal('verify-phone-number-modal');
        }
        if (phoneNumberChanged === false && passwordChanged.current) {
          await showBockingModal('verify-phone-number-password-changed-modal');
        }
        if (emailChanged) {
          await showBockingModal('verify-phone-number-email-changed-modal');
        }
        if (fullNameChanged) {
          await currentUser?.updateProfile({ displayName: values.fullName });
          await reloadCurrentUser();
        }
        await saveUserToDb(values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        handleError(error);
      }
    }
    runAsync();
  };

  const handlePhoneNumberVerify = async (values: MyFormValues, credential: firebase.auth.AuthCredential | null) => {
    try {
      if (currentUser && credential) {
        await currentUser.updatePhoneNumber(credential);
      }
      if (openId === 'verify-phone-number-modal') {
        setOpenId(undefined);
      }
      if (passwordChanged.current) {
        // also update the password without re-showing the phone number modal
        await handlePasswordChangeVerify(values, credential);
      }
    } catch (error) {
      handleError(error);
    }
  };
  const handlePasswordChangeVerify = async (values: MyFormValues, credential: firebase.auth.AuthCredential | null) => {
    try {
      if (currentUser && credential) {
        await currentUser.reauthenticateWithCredential(credential);
        await currentUser.updatePassword(newPassword.current);
        newPassword.current = '';
      }
      if (openId === 'verify-phone-number-password-changed-modal') {
        setOpenId(undefined);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleEmailChangeVerify = async (credential: firebase.auth.AuthCredential | null) => {
    try {
      if (currentUser && credential && newEmail.current) {
        await firebaseApp?.auth().signInWithCredential(credential);
        newEmail.current = '';
      }
      if (openId === 'verify-phone-number-email-changed-modal') {
        setOpenId(undefined);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Box className={classes.container}>
      {initialValues ? (
        <Formik initialValues={initialValues} validate={validate} onSubmit={save} enableReinitialize>
          {({ handleSubmit, values }) => (
            <>
              <form onSubmit={handleSubmit}>
                <UserProfile />
                <Divider />

                <Box display="flex" marginTop={3}>
                  <SubmitButton startIcon={<SaveIcon />}>Save</SubmitButton>
                </Box>
              </form>

              <BlockingDialog id="verify-phone-number-email-changed-modal">
                <DialogContent classes={{ root: classes.dialogContainer }}>
                  <Typography variant="h4" color="inherit">
                    Verify Mobile #
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1">
                      Before resetting your email you are asked to re-verify your mobile number to ensure your security.
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body1">A verification code has been sent to your mobile number</Typography>
                  </Box>
                  <VerifyMobileForm onVerify={handleEmailChangeVerify} />
                </DialogContent>
              </BlockingDialog>
              <BlockingDialog id="verify-phone-number-password-changed-modal">
                <DialogContent classes={{ root: classes.dialogContainer }}>
                  <Typography variant="h4" color="inherit">
                    Verify Mobile #
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1">
                      Before resetting your password you are asked to re-verify your mobile number to ensure your
                      security.
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body1">A verification code has been sent to your mobile number</Typography>
                  </Box>
                  <VerifyMobileForm onVerify={(credential) => handlePasswordChangeVerify(values, credential)} />
                </DialogContent>
              </BlockingDialog>
              <BlockingDialog id="verify-phone-number-modal">
                <DialogContent classes={{ root: classes.dialogContainer }}>
                  <Typography variant="h4" color="inherit">
                    Verify Mobile #
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1">Please Verify your new mobile number</Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body1">A verification code has been sent to your mobile number</Typography>
                  </Box>
                  <VerifyMobileForm onVerify={(credential) => handlePhoneNumberVerify(values, credential)} />
                </DialogContent>
              </BlockingDialog>
            </>
          )}
        </Formik>
      ) : null}
    </Box>
  );
};

export default EditProfile;
