import { useContext } from 'react';
import { User } from '../../types';
import UserContext from '../context/user';

export default function useOwnerId(_user?: Partial<User>) {
  // allow user to be passed in, otherwise default to the user context
  const { user } = useContext(UserContext);
  if (_user === undefined) {
    _user = user;
  }
  return _user?.roles?.includes('OWNER') ? _user?._id : _user?.created_by_user_id;
}
