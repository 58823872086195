import { Box, Theme, Divider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { TeammatesTable } from './OurTeammatesComponents/TeammatesTable';
import { AddTeammate } from './OurTeammatesComponents/AddTeammate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
  })
);

const OurTeammates = () => {
  const classes = useStyles();
  const [lastRefeshedAt, setLastRefeshedAt] = useState(new Date().getTime().toString());
  const handleAddTeammate = () => {
    setLastRefeshedAt(new Date().getTime().toString());
  };

  return (
    <Box className={classes.container}>
      <AddTeammate onAddTeammate={handleAddTeammate} />
      <Divider />
      <TeammatesTable key={lastRefeshedAt} />
    </Box>
  );
};

export default OurTeammates;
