import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import {
  REACT_APP_API_KEY,
  REACT_APP_APP_ID,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_MEASUREMENT_ID,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
} from '../env';
import useUserApiRoutes from '../hooks/api/useUserApiRoutes';
import UserContext from './user';

const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID,
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
const FirebaseContext = React.createContext<{
  firebaseApp: firebase.app.App;
  verifier?: firebase.auth.RecaptchaVerifier;
  currentUser: firebase.User | null;
  reloadCurrentUser: () => Promise<void>;
  firebaseLoaded: boolean;
}>({ firebaseApp: firebaseApp, currentUser: null, reloadCurrentUser: () => Promise.resolve(), firebaseLoaded: false });
export default FirebaseContext;

export const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  const { getUser } = useUserApiRoutes();
  const { saveUser, setIsLoggedIn } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  const [verifier, setVerifier] = useState<firebase.auth.RecaptchaVerifier | undefined>(undefined);
  const recaptchaRef = useRef<HTMLDivElement | null>();

  const reloadCurrentUser = useCallback(async () => {
    if (firebaseApp.auth().currentUser) {
      // must set to null, then relaod otherwise compenent relying on currentUser are not updated properly
      setCurrentUser(null);
      await firebaseApp.auth().currentUser?.reload();
      setCurrentUser(firebaseApp.auth().currentUser);
    }
  }, []);

  useEffect(() => {
    if (verifier === undefined) {
      setVerifier(new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' }));
    }
    return () => {
      if (verifier) verifier.clear();
    };
  }, [recaptchaRef, verifier]);

  useEffect(() => {
    const unsubscribe = firebaseApp?.auth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        const dbUser = await getUser(user.uid);
        saveUser({ ...user, ...dbUser });
        // only log in if a user is returned from the db.
        // The very first time a user is created the dbUser may be undefined as the protectedRoute
        // may not have the currentUser set yet so cannot set the access token
        setIsLoggedIn(dbUser !== undefined);
      } else {
        setIsLoggedIn(user !== null);
      }
      setFirebaseLoaded(true);
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [setIsLoggedIn, saveUser, getUser]);

  return (
    <>
      <div ref={(ref) => (recaptchaRef.current = ref)} id="recaptcha-container"></div>
      {verifier ? (
        <FirebaseContext.Provider value={{ firebaseApp, verifier, currentUser, reloadCurrentUser, firebaseLoaded }}>
          {children}
        </FirebaseContext.Provider>
      ) : null}
    </>
  );
};
