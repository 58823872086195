import { Alert, AlertTitle, Box, Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import SnackBarContext from '../../context/snackbar';
import UserContext from '../../context/user';
import useAccountsApiRoutes from '../../hooks/api/useAccountsApiRoutes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { marginBottom: theme.spacing(2) },
  })
);

const EmailNotVerified = () => {
  const { showSnack } = useContext(SnackBarContext);
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { emailVerification } = useAccountsApiRoutes();

  const resend = async () => {
    if (user?.email) {
      await emailVerification(user.email);
      showSnack('Please check your email for further instructions', 'success');
    }
  };

  return (
    <Box className={classes.container}>
      <Alert severity="info">
        <AlertTitle>Your Email has not been verified</AlertTitle>
        Please check your email ({user?.email}) for your verification link
        <Button onClick={resend} disabled={!user?.email}>
          Resend Email
        </Button>
      </Alert>
    </Box>
  );
};

export default EmailNotVerified;
