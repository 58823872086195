import { Box, Theme, Divider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import SnackBarContext from '../../context/snackbar';
import UserContext from '../../context/user';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';
import SaveIcon from '@mui/icons-material/Save';
import useHandleError from '../../hooks/useHandleError';
import { BaseFields, User } from '../../../types';
import { AccountOptions } from './EditAccountComponents/AccountOptions';
import { CompanyInformation } from './EditAccountComponents/CompanyInformation';
import { PaymentDetails } from './EditAccountComponents/PaymentDetails';
import { SubmitButton } from '../../components/Form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
    },
  })
);

export interface MyFormValues
  extends Required<
    Omit<
      User,
      | 'uid'
      | 'emailVerified'
      | 'firebase'
      | 'isTeammate'
      | 'providerData'
      | 'status'
      | 'fullName'
      | 'email'
      | 'phoneNumber'
      | 'password'
      | keyof BaseFields
    >
  > {}

const EditAccount = () => {
  const { user, saveUser } = useContext(UserContext);
  const { showSnack } = useContext(SnackBarContext);
  const handleError = useHandleError();
  const { updateUser } = useUserApiRoutes();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState<MyFormValues | undefined>();

  useEffect(() => {
    if (user) {
      setInitialValues({
        created_by_user_id: '',
        accountTypes: user?.accountTypes || [],
        companyInformation: {
          companyName: user?.companyInformation?.companyName || '',
          billingName: user?.companyInformation?.billingName || '',
          billingEmail: user?.companyInformation?.billingEmail || '',
          address: user?.companyInformation?.address || '',
          companyPhone: user?.companyInformation?.companyPhone || '',
        },
        roles: [],
        settings: {
          searchPreferences: {
            containerTypeCodes: user.settings?.searchPreferences.containerTypeCodes || [],
            shippingLineCodes: user.settings?.searchPreferences.shippingLineCodes || [],
          },
        },
        verifyEmail: !user.emailVerified,
      });
    }
  }, [user]);

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    return errors;
  };

  const saveUserToDb = async (values: MyFormValues) => {
    const updatedUser = await updateUser({ ...values, uid: user?.uid });
    saveUser(updatedUser);
    showSnack('Account Details saved!', 'success');
  };

  const save = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    // must run async in a function otherwise setSubmitting will not work. See https://github.com/formium/formik/issues/2442
    async function runAsync() {
      try {
        await saveUserToDb(values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        handleError(error);
      }
    }
    runAsync();
  };

  return (
    <Box className={classes.container}>
      {initialValues ? (
        <Formik initialValues={initialValues} validate={validate} onSubmit={save} enableReinitialize>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AccountOptions />
              <Divider />

              <CompanyInformation />
              <Divider />

              <PaymentDetails />
              <Divider />

              <Box display="flex" marginTop={3}>
                <SubmitButton startIcon={<SaveIcon />}>Save</SubmitButton>
              </Box>
            </form>
          )}
        </Formik>
      ) : null}
    </Box>
  );
};

export default EditAccount;
