import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext, useCallback } from 'react';
import { Formik, FormikHelpers } from 'formik';
import ModalContext from '../../context/modal';
import { SubmitButton, ThemedTextField } from '../Form';
import useUtils from '../../hooks/useUtils';
import { FileUpload } from '../../components/FileUploads';
import { AttachedFile } from '../../../types';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';

type Props = {};
interface MyFormValues {
  description: string;
  evidenceOfDispute?: AttachedFile[];
}

const ClaimFutileTripModal = (props: Props) => {
  const { setDrawerOpen, container } = useContext(ModalContext);
  const { getContainerTypeName } = useUtils();
  const { createClaimFutileTrip } = useContainerApiRoutes();
  const classes = useStyles();

  const validate = useCallback((values: MyFormValues) => {
    const errors: { [Property in keyof Partial<MyFormValues>]: string } = {};
    const requiredFields: (keyof MyFormValues)[] = ['description'];
    for (const field of requiredFields) {
      if (!values[field]) {
        errors[field] = 'Required';
        break;
      }
    }
    return errors;
  }, []);
  const handleSubmit = (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
    if (container?._id) {
      createClaimFutileTrip(container?._id, values)
        .then((returnedContainer) => {
          setSubmitting(false);
          setDrawerOpen('thank-you-dispute');
        })
        .catch(() => setSubmitting(false));
    }
  };

  return container ? (
    <Box>
      <Box mb={2.75}>
        <Typography lineHeight={1.235} variant="h4">
          Claim Futile Trip
        </Typography>
        <Typography lineHeight={1.235} variant="h4">
          #{container?.containerCode}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography className={classes.body2} variant="body2">
          Type: <strong>{getContainerTypeName(container)}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2">
          Reference # <strong>{container.referenceId}</strong>
        </Typography>
      </Box>
      <Formik
        initialValues={{
          description: '',
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, values }) => (
          <form onSubmit={handleSubmit}>
            <ThemedTextField
              fullWidth
              name="description"
              label="Add Reason For Claim"
              placeholder="Lorem ipsum may be used as a placeholder before final copy is available."
              multiline
              minRows={4}
            ></ThemedTextField>
            <Box mb={3}>
              <FileUpload
                color="secondary"
                name="evidenceOfDispute"
                text="Add evidence of claim e.g Transport invoice"
              />
            </Box>
            <SubmitButton color="secondary" sx={{ marginRight: 3 }}>
              Submit Claim
            </SubmitButton>
          </form>
        )}
      </Formik>
      <Box mt={5}>
        <Typography className={classes.caption}>
          On submit, an email will be sent to a <br />
          VirtualStack™ support team member <br />
          who will follow up on your behalf.
        </Typography>
      </Box>
    </Box>
  ) : null;
};

export default ClaimFutileTripModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body2: {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 14,
      color: '#616161',
      fontStyle: 'italic',
    },
  })
);
