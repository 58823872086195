import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import ModalContext from '../../context/modal';
import { SubmitButton, ThemedTextField, ThemedDatePicker, ThemedCheckbox } from '../Form';
import useUtils from '../../hooks/useUtils';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import SnackBarContext from '../../context/snackbar';
import { FileUpload } from '../FileUploads';
import { AttachedFile } from '../../../types';

type Props = {};
interface MyFormValues {
  confirmedAt: string;
  inDispute: boolean;
  dispute: {
    description: '';
  };
  evidenceOfDispute?: AttachedFile[];
}

const ConfirmContainerModal = (props: Props) => {
  const { setDrawerOpen, container } = useContext(ModalContext);
  const { confirmContainer } = useContainerApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { getContainerTypeName } = useUtils();
  const classes = useStyles();

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    if (!values.confirmedAt) {
      errors.confirmedAt = 'Required';
    }
    return errors;
  };
  const handleSubmit = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    if (container) {
      confirmContainer({ _id: container._id, ...values })
        .then((returnedContainer) => {
          setSubmitting(false);
          if (returnedContainer.status === 'dispute') {
            setDrawerOpen('thank-you-dispute');
          } else {
            showSnack('Container now in use! Container may still be viewed in Archive tab', 'success');
            setDrawerOpen(undefined);
          }
        })
        .catch(() => setSubmitting(false));
    }
  };

  return container ? (
    <Box>
      <Box mb={2.75}>
        <Typography lineHeight={1.235} variant="h4">
          Container is now in use
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography className={classes.body2} variant="body2">
          Container # <strong>{container.containerCode}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2">
          Reference # <strong>{container.referenceId}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2">
          Type: <strong>{getContainerTypeName(container)}</strong>
        </Typography>
      </Box>
      <Formik
        initialValues={{ dispute: { description: '' }, inDispute: false, confirmedAt: new Date().toString() }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, values, isValid, isSubmitting, setFieldValue }) => {
          const handleInDisputeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target;
            if (checked === false) {
              // clear dispute fields
              setFieldValue('dispute', { description: ''});
              setFieldValue('evidenceOfDispute', []);
            }
            handleChange(event);
          };
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <ThemedDatePicker
                textFieldVariant="outlined"
                fullWidth
                name="confirmedAt"
                label="Date"
              ></ThemedDatePicker>
              <Box my={2}>
                <ThemedCheckbox
                  onChange={handleInDisputeChange}
                  color="secondary"
                  name="inDispute"
                  label="Dispute Container was not clean."
                />
              </Box>
              {values.inDispute ? (
                <Box>
                  <ThemedTextField
                    fullWidth
                    name="dispute.description"
                    label="Add a description"
                    multiline
                    minRows={5}
                  ></ThemedTextField>
                  <FileUpload color="secondary" name="evidenceOfDispute" text="Add Photo Evidence to your Dispute" />
                </Box>
              ) : null}
              <SubmitButton
                disabled={!isValid || isSubmitting}
                color="secondary"
                sx={{ marginRight: 3, marginTop: 2, marginBottom: 4.6 }}
              >
                {values.inDispute ? 'Archive & Send Dispute' : 'Confirm & Archive'}
              </SubmitButton>

              {values.inDispute ? (
                <Typography className={classes.caption}>
                  On submit, an email will be sent to a <br />
                  VirtualStack™ support team member.
                  <br />
                  We will then follow up on your behalf.
                </Typography>
              ) : null}
            </form>
          );
        }}
      </Formik>
    </Box>
  ) : null;
};

export default ConfirmContainerModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body2: {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 14,
      color: '#616161',
      fontStyle: 'italic',
    },
    form: {
      color: theme.palette.text.primary,
    },
  })
);
