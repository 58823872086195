import { useCallback } from 'react';
import { Client } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { useProtectedRoute } from './useProtectedRoute';
import { usePublicRoute } from './usePublicRoute';

export default function useClientApiRoutes() {
  const protectedRoute = useProtectedRoute();
  const publicRoute = usePublicRoute();

  const updateClient = useCallback(
    async (client: Partial<Client>): Promise<Client> => {
      const response = await protectedRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/clients/${client._id}`,
        data: client,
      });
      return response;
    },
    [protectedRoute]
  );

  const getClient = useCallback(
    async (id: string): Promise<Client | undefined> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/clients/${id}`,
      });
      return response;
    },
    [publicRoute]
  );

  const getClients = useCallback(
    async (
      currentPage = 0,
      itemsPerPage = 10,
      where: {
        user_id?: string;
        address?: { $regex: string; $options: string };
        $or?: any;
      } = {}
    ): Promise<{ clients: Client[] | undefined }> => {
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/clients`,
        params: { itemsPerPage, currentPage, where },
      });
      return response;
    },
    [protectedRoute]
  );

  const getAllClients = useCallback(async (): Promise<{ clients: Client[] | undefined }> => {
    const response = await protectedRoute({
      method: 'get',
      url: `${REACT_APP_API_BASE_URL}/clients`,
      params: { isUnlimited: true },
    });
    return response;
  }, [protectedRoute]);

  const createClient = useCallback(
    async (client: Partial<Client>): Promise<Client> => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/clients`,
        data: client,
      });
      return response;
    },
    [protectedRoute]
  );

  const deleteClient = useCallback(
    async (id: string): Promise<{ _id: string }> => {
      const response = await protectedRoute({
        method: 'delete',
        url: `${REACT_APP_API_BASE_URL}/clients/${id}`,
      });
      return response;
    },
    [protectedRoute]
  );

  return { updateClient, getClient, createClient, getClients, deleteClient, getAllClients };
}
