import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SavingData } from '../../../../types';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
    },
    textColorInfo: {
      color: theme.palette.info.main,
    },
    boxSmall: {
      backgroundColor: "white",
      borderRadius: "24px",
      boxShadow: "0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2.5),
      textAlign: "center"
    },
    boxNormal: {
      backgroundColor: "white",
      borderRadius: "24px",
      boxShadow: "0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(1),
      paddingTop: theme.spacing(12),
      textAlign: "center"
    }
  })
);

const Savings = ({ savingData }: { savingData: SavingData }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid mt={3} container columns={15} spacing={2} alignItems="flex-end">
        <Grid item xs={7}>
          <Typography variant="h6" fontWeight={600} color="secondary">
            Kg CO<sup>2</sup> Savings
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.boxSmall}>
            <Typography variant="h6" fontWeight={600} color="secondary">
              {savingData.kgO2Saving.lastWeek.toFixed(1)}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              Last Week
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.boxNormal} >
            <Typography variant="h6" fontWeight={600} color="secondary">
              {savingData.kgO2Saving.lastMonth.toFixed(1)}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              Last Month
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid mt={3} container columns={15} spacing={2} alignItems="flex-end">
        <Grid item xs={7}>
          <Typography variant="h6" fontWeight={600} color="primary">
            Mileage<sup>km</sup> Savings
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.boxSmall}>
            <Typography variant="h6" fontWeight={600} color="primary">
              {savingData.kmSaving.lastWeek.toFixed(1)}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              Last Week
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.boxNormal} >
            <Typography variant="h6" fontWeight={600} color="primary">
              {savingData.kmSaving.lastMonth.toFixed(1)}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              Last Month
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Savings;