import { Box, Theme, Typography, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import NavLogo from '../../images/NavLogo.svg';
import { Search } from '../../components/Search';
import { ThemedDatePicker } from '../../components/Form';
import { Formik } from 'formik';
import { HeaderFilter } from '../../../types';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBottom: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(13),
      flexDirection: 'column',
      minHeight: 0,
      backgroundColor: theme.palette.grey[100],
    },
    subtitle: {
      margin: theme.spacing('auto', 8),
    },
    dateRoot: {
      backgroundColor: 'white',
    },
  })
);

const HeaderBottom = ({ subtext }: { subtext: string }) => {
  const classes = useStyles();
  const { setHeaderFilter } = useContext(UserContext);
  const [filter, setFilter] = useState<HeaderFilter>({
    fromDate: '',
    toDate: '',
    searchText: '',
  });
  useEffect(() => {
    setHeaderFilter(filter)
  },[filter, setHeaderFilter])

  return (
    <>
      <Box display="block" className={classes.navBottom}>
        <Grid container justifyContent="space-between" columns={12}>
          <Grid item xs={7}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <img src={NavLogo} alt="Logo" />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ color: '#00ACC1' }}>
                  {subtext}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid columns={14} container spacing={2.5} alignItems="center" justifyContent="flex-end">
              <Formik validationDisabled={true} initialValues={filter} onSubmit={() => { }} enableReinitialize>
                {({ values, handleSubmit }) => {
                  const handleSelectChange = (e: { target: { name: string, value: string } }) => {
                    if(e.target.name === "fromDate" || e.target.name === "toDate") {
                      const _filter = {...filter};
                      _filter[`${e.target.name}`] = e.target.value;
                      setFilter(_filter);
                    }
                  };
                  const handleSearchChange = (e: any) => {
                    // setHeaderFilter(values);
                  };
                  return (
                    <>
                      <Grid item xs={4}>
                        <ThemedDatePicker
                          label="To From Date"
                          textFieldVariant="outlined"
                          name="fromDate"
                          textFieldClassName={classes.dateRoot}
                          textFieldSize="small"
                          onChange={handleSelectChange}
                        ></ThemedDatePicker>
                      </Grid>
                      <Grid item xs={4}>
                        <ThemedDatePicker
                          label="To To Date"
                          textFieldVariant="outlined"
                          name="toDate"
                          textFieldClassName={classes.dateRoot}
                          textFieldSize="small"
                          onChange={handleSelectChange}
                        ></ThemedDatePicker>
                      </Grid>
                      <Grid item xs={6}>
                        <Search
                          bgColor="white"
                          name="searchText"
                          placeholder="Search..."
                          onChange={handleSearchChange}
                        />
                      </Grid>
                    </>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HeaderBottom;
