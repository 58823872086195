import { Box, Divider } from '@mui/material';
import { useContext } from 'react';
import ModalContext from '../../context/modal';
import { ClientInfo } from '../../components/super-admin/ClientInfo';
import DisputesTable from '../../components/super-admin/DisputesTable';

type Props = {};

const MoreClientInfoModal = (props: Props) => {
  const { accountInfo } = useContext(ModalContext);
  return accountInfo ? (
    <Box sx={{ width: '100%' }}>
      <ClientInfo user={accountInfo.user} teammateTotal={accountInfo.teammateTotal} color={'info'} />
      <Divider sx={{ marginTop: "40px", backgroundColor: "rgba(0, 188, 212, 0.5)" }} />
      <DisputesTable userId={accountInfo.user._id} />
    </Box>) : null
};

export default MoreClientInfoModal;
