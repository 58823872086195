import { Button, DialogActions, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Container, DrawerType, AccountInfo, Dispute } from '../../types';
import AppModals from '../components/Modals/AppModals';
import BlockingDialog from '../components/Modals/BlockingDialog';
const ModalContext = React.createContext<{
  drawerOpen: DrawerType;
  setDrawerOpen: React.Dispatch<React.SetStateAction<DrawerType>>;
  container: Container | undefined;
  setContainer: React.Dispatch<React.SetStateAction<Container | undefined>>;
  accountInfo: AccountInfo | undefined;
  setAccountInfo: React.Dispatch<React.SetStateAction<AccountInfo | undefined>>;
  dispute: Dispute | undefined;
  setDispute: React.Dispatch<React.SetStateAction<Dispute | undefined>>;
}>({
  drawerOpen: undefined,
  setDrawerOpen: () => { },
  container: undefined,
  setContainer: () => { },
  accountInfo: undefined,
  setAccountInfo: () => { },
  dispute: undefined,
  setDispute: () => { },
});
export default ModalContext;

export const ModalServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = useState<DrawerType>(undefined);
  const [container, setContainer] = useState<Container | undefined>(undefined);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | undefined>(undefined);
  const [dispute, setDispute] = useState<Dispute | undefined>(undefined);
  return (
    <>
      <ModalContext.Provider value={{ drawerOpen, setDrawerOpen, container, setContainer, accountInfo, setAccountInfo, dispute, setDispute }}>
        <ConfirmationServiceProvider>
          {children}
          <AppModals />
        </ConfirmationServiceProvider>
      </ModalContext.Provider>
    </>
  );
};

export const ConfirmationServiceContext = React.createContext<{
  showBockingModal: (id: string) => Promise<void>;
  showConfirmationModal: (confirmationOptions: ConfirmationOptions) => Promise<{ confirmed: boolean } | void>;
  openId: string | undefined;
  setOpenId: (id: string | undefined) => void;
}>({
  showConfirmationModal: Promise.reject,
  showBockingModal: Promise.reject,
  openId: undefined,
  setOpenId: (id) => { },
});

type ConfirmationOptions = {
  title: string;
  contentText: string;
};
const ConfirmationServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const [openId, setOpenId] = useState<string | undefined>(undefined);
  const [confirmationOptions, setConfirmationOptions] = useState<ConfirmationOptions>({
    title: '',
    contentText: '',
  });
  const awaitingPromiseRef = React.useRef<{
    resolve: (payload?: any) => void;
    reject: () => void;
  }>();

  useEffect(() => {
    if (openId === undefined && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
  }, [openId]);

  const showBockingModal = (id: string) => {
    setOpenId(id);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const showConfirmationModal = (confirmationOptions: ConfirmationOptions) => {
    setConfirmationOptions(confirmationOptions);
    setOpenId('confirmation-modal');
    return new Promise<{ confirmed: boolean } | void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve({ confirmed: true });
    }
  };

  return (
    <ConfirmationServiceContext.Provider value={{ showBockingModal, openId, setOpenId, showConfirmationModal }}>
      {children}
      <ConfirmationModal
        confirmationOptions={confirmationOptions}
        onConfirm={handleConfirm}
        onCancel={() => setOpenId(undefined)}
      />
    </ConfirmationServiceContext.Provider>
  );
};

const confirmationStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: 0,
    },
    errorButton: {
      color: theme.palette.error.main,
    },
  })
);

const ConfirmationModal = ({
  confirmationOptions,
  onConfirm,
  onCancel,
}: {
  confirmationOptions: ConfirmationOptions;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const classes = confirmationStyles();
  return (
    <BlockingDialog id="confirmation-modal" classes={{ paper: classes.paper }}>
      <DialogTitle>{confirmationOptions.title}</DialogTitle>
      <DialogContent>
        <Typography align="center" variant="body1" color="textSecondary" sx={{whiteSpace : 'pre-line'}}>
          {confirmationOptions.contentText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" className={classes.errorButton} onClick={onCancel}>
          No, Go Back
        </Button>
        <Button variant="text" color="primary" autoFocus onClick={onConfirm}>
          Yes
        </Button>
      </DialogActions>
    </BlockingDialog >
  );
};
