import { useCallback } from 'react';
import { Client, Container } from '../../types';

import useSWR from 'swr';
import useClientApiRoutes from './api/useClientApiRoutes';

export default function useGetClient(container: Container | undefined): Client | undefined {
  const { getClient } = useClientApiRoutes();

  const fetcher = useCallback(
    async (url: string, clientId?: string): Promise<Client | undefined> => (clientId ? getClient(clientId) : undefined),
    [getClient]
  );

  const { data: client } = useSWR([`/clients/${container?.client_id}`, container?.client_id], fetcher);
  return client;
}
