import { Box, Button, Grid, Link, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import ModalContext, { ConfirmationServiceContext } from '../../context/modal';
import { ContainerInfo } from '../Containers/ContainerInfo';
import { InfoField } from '../Containers/InfoField';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import { BlackButton } from '../Form';
import useGetClient from '../../hooks/useGetClient';
import useUtils from '../../hooks/useUtils';
import SnackBarContext from '../../context/snackbar';
import UserContext from '../../context/user';
import { format } from 'date-fns';

type Props = {};

const ViewContainerAssignedModal = (props: Props) => {
  const { setDrawerOpen, container, setContainer } = useContext(ModalContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const { getContainer } = useContainerApiRoutes();
  const { getContainerTypeName } = useUtils();
  const { cancelRequestContainer } = useContainerApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const client = useGetClient(container);
  const { clients } = useContext(UserContext);
  const internalUse = container?.status === 'internalUse' ? true : false;
  const siteToName = container?.goingTo?.client_id
    ? clients.find((c) => c._id === container?.goingTo?.client_id)?.companyName
    : undefined;

  const basicSiteInstructions = `Site open ${client?.daysOperating?.join(', ')}. Access hours ${client?.accessHoursFrom} to ${client?.accessHoursTo
    }. ${client?.containerDoorOptions} Site Contact ${client?.siteContact}. ${client?.additionalSiteInstructions}`;


  useEffect(() => {
    if (container?._id) {
      getContainer(container._id).then((returnedContainer) => {
        setContainer(returnedContainer);
      });
    }
  }, [container?._id, getContainer, setContainer]);

  const claimFutileTripContainer = () => {
    setDrawerOpen('claim-futile-trip');
  };
  //cancel-request-container
  const cancelRequest = async () => {
    if (container && internalUse) {
      const res = await showConfirmationModal({
        title: `Cancel Request Container ${getContainerTypeName(container)} #${container.containerCode}`,
        contentText:
          'Do you wish to cancel "Internal Use" of this container and add it back into your Stack for someone else to request?',
      });
      if (res && res.confirmed === true) {
        setOpenId(undefined);
        const returnedContainer = await cancelRequestContainer(container?._id, {});
        if (returnedContainer.status === 'available') {
          showSnack(
            `Request Cancel Accepted. Container ${returnedContainer.containerCode} in now available for use.`,
            'success'
          );
          setDrawerOpen(undefined);
        } else {
          showSnack(`Error, container status is ${returnedContainer.status}`, 'error');
        }
      }
    } else {
      setDrawerOpen('cancel-request-container');
    }
  };
  const confirmInUse = () => {
    setDrawerOpen('confirm-container');
  };
  const editContainer = () => {
    setDrawerOpen('edit-container');
  };

  return container ? (
    <Box>
      <ContainerInfo container={container} client={client} color="secondary" referenceVisible={true} />
      <Grid container spacing={4} color="unset">
        {internalUse ? (
          <Grid item xs={4}>
            {client && <InfoField heading="SITE STACKED" detail={client.companyName} />}
          </Grid>
        ) : null}
        <Grid item xs={4}>
          {client ? <InfoField heading="LOCATION FROM" detail={client.address} /> : null}
        </Grid>
        <Grid item xs={4}>
          {client ? <InfoField heading="LOCATION TO" detail={container.goingTo?.address} /> : null}
        </Grid>
        {!internalUse ? (
          <Grid item xs={4}>
            <InfoField heading="SITE TO" detail={siteToName ? siteToName : "-"} />
          </Grid>
        ) : null}

      </Grid>
      <Grid container spacing={4} color="unset">
        {!internalUse && <Grid item xs={4}>
          <InfoField heading="AVAILABLE FROM" detail={format(new Date(container.availableTimeFrom), 'h:mm a')} />
        </Grid>}
        <Grid item xs>
          {client ? <InfoField heading="BASIC SITE INSTRUCTIONS" detail={basicSiteInstructions} /> : null}
        </Grid>
      </Grid>
      {client && internalUse ? (
        <Grid container spacing={4} color="unset">
          <Grid item xs={12}>
            <InfoField
              heading="MIMIMUM HEATH AND SAFETY REQUIREMENTS"
              detail={client.minimumHealthSafetyRequirements}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={4} color="unset">
        <Grid item xs={12}>
          <Button size="large" variant="contained" color="secondary" sx={{ mr: 2 }} onClick={confirmInUse}>
            Confirm Now In Use
          </Button>
          {!internalUse ? (
            <Button
              disabled={container.status === 'dispute'}
              size="large"
              variant="outlined"
              color="secondary"
              sx={{ mr: 2 }}
              onClick={claimFutileTripContainer}
            >
              Claim Futile Trip
            </Button>
          ) : (
            <Button size="large" variant="contained" color="primary" sx={{ mr: 2 }} onClick={editContainer}>
              Edit Container
            </Button>
          )}
          <BlackButton disabled={container.status === 'dispute'} onClick={cancelRequest}>
            Cancel Request
          </BlackButton>
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={4} color="unset">
        <Grid item xs={12}>
          <Typography sx={{ fontStyle: 'italic' }} variant="body2" color="textSecondary">
            Is your container not ready or not at the site? Please
            <Link ml={1} sx={{ fontStyle: 'bold' }} href="#" color="secondary">
              contact us
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

export default ViewContainerAssignedModal;
