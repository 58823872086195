import { Box, Typography, Link, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import ModalContext from '../../context/modal';
import { SubmitButton, ThemedTextField } from '../Form';
import useUtils from '../../hooks/useUtils';
import { GreyCheckbox } from './AppModals';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import SnackBarContext from '../../context/snackbar';
import UserContext from '../../context/user';
import useGetClient from '../../hooks/useGetClient';

type Props = {};
interface MyFormValues {
  shippingLineBookingRef: string;
  agreeToTerms: boolean;
}

const ReserveContainerModal = (props: Props) => {
  const { clients, user } = useContext(UserContext);
  const { setDrawerOpen, container, setContainer } = useContext(ModalContext);
  const { getContainerTypeName, addressNoStreet } = useUtils();
  const { createReserveRequest } = useContainerApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const classes = useStyles();

  const client = useGetClient(container);
  const clientGoingTo = container?.goingTo?.client_id
    ? clients.find((c) => c._id === container?.goingTo?.client_id)
    : undefined;

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    if (!values.agreeToTerms) {
      errors.agreeToTerms = false;
    }
    // (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
    //   if (!values[k]) {
    //     errors[k] = 'Required';
    //   }
    // });
    return errors;
  };
  const handleSubmit = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    createReserveRequest(container!._id, {
      shippingLineBookingRef: values.shippingLineBookingRef,
      goingTo: container?.goingTo,
    })
      .then((returnedContainer) => {
        if (returnedContainer.status === 'requestDeclined') {
          showSnack('Request Declined. Please select another conatiner', 'error');
        } else if (returnedContainer.status === 'pending') {
          showSnack('Request pending approved from Shipping Line.', 'info');
        } else if (returnedContainer.status === 'assigned') {
          showSnack(
            `Request Accepted. Container ${returnedContainer.containerCode} in now assigned to ${user?.companyInformation?.companyName || 'you'}.`,
            'success'
          );
        } else {
          showSnack(`Error, container status is ${returnedContainer.status}`, 'error');
        }
        handleCancel();
      })
      .catch(() => setSubmitting(false));
  };

  const handleCancel = () => {
    setDrawerOpen(undefined);
    setContainer(undefined);
  };

  return container ? (
    <Box className={classes.root}>
      <Box mb={2.75}>
        <Typography lineHeight={1.235} variant="h4">
          Reserve {getContainerTypeName(container)}
        </Typography>
        <Typography lineHeight={1.235} variant="h4">
          Container
        </Typography>
        <Typography lineHeight={1.235} variant="h4">
          #{container?.containerCode}
        </Typography>
      </Box>
      <Box mb={3}>
        {clientGoingTo ? (
          <Typography className={classes.body2} variant="body2">
            For: <strong>{clientGoingTo.companyName}</strong>
          </Typography>
        ) : null}
        <Typography className={classes.body2} variant="body2" noWrap>
          Currently at: <strong>{client?.address ? addressNoStreet(client?.address) : ''}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2" noWrap>
          Going to: <strong>{container.goingTo?.address ? addressNoStreet(container.goingTo?.address) : ''}</strong>
        </Typography>
      </Box>
      <Formik
        initialValues={{ agreeToTerms: false, shippingLineBookingRef: '' }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, values }) => (
          <form onSubmit={handleSubmit}>
            <ThemedTextField
              name="shippingLineBookingRef"
              label="Shipping Line Export Booking"
              helperText="If Available"
              fullWidth
            ></ThemedTextField>
            <Box mb={3}>
              <GreyCheckbox
                label={
                  <Typography>
                    I agree to the{' '}
                    <Link href="#" underline="always" color="secondary">
                      Terms and Conditions
                    </Link>
                  </Typography>
                }
                checked={values.agreeToTerms}
                onChange={handleChange}
                name="agreeToTerms"
              />
            </Box>
            <SubmitButton color="secondary" sx={{ marginRight: 2 }}>
              Submit
            </SubmitButton>
            <Button color="secondary" variant="outlined" size="large" onClick={handleCancel}>
              Cancel
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  ) : null;
};

export default ReserveContainerModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 435,
    },
    body2: {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
    },
  })
);
