import { Box, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext } from 'react';
import SnackBarContext from '../../../context/snackbar';
import SendIcon from '@mui/icons-material/Send';
import useValidators from '../../../hooks/useValidators';
import { SubmitButton, ThemedTextField } from '../../../components/Form';
import UserContext from '../../../context/user';
import useHandleError from '../../../hooks/useHandleError';
import useTeammateApiRoutes from '../../../hooks/api/useTeammateApiRoutes';

export interface MyFormValues {
  email: string;
}

export const AddTeammate = ({ onAddTeammate }: { onAddTeammate: () => void }) => {
  const { user } = useContext(UserContext);
  const { showSnack } = useContext(SnackBarContext);
  const { validateEmail } = useValidators();
  const { createTeammate } = useTeammateApiRoutes();

  const handleError = useHandleError();

  const save = (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
    if (user?._id) {
      createTeammate(user._id, values)
        .then(() => {
          showSnack('Invitation Sent!', 'success');
          resetForm();
          onAddTeammate();
        })
        .catch((error) => {
          setSubmitting(false);
          handleError(error);
        });
    }
  };

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography variant="subtitle1" color="primary">
          ADD A NEW TEAMMATE
        </Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
        Send an invite request. Your teammate can create an admin login into your company’s Virtual Stack account.
      </Typography>
      <Formik initialValues={{ email: '' }} validate={validateEmail} onSubmit={save}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} mb={4}>
              <ThemedTextField name="email" label="Email" placeholder="Add Teammate Email"></ThemedTextField>
            </Box>
            <SubmitButton startIcon={<SendIcon />}>Send Invitation</SubmitButton>
          </form>
        )}
      </Formik>
    </Box>
  );
};
