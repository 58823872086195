import { Box, ButtonBase, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import React, { useContext, useRef } from 'react';
import ImporterIcon from '../../../images/ImporterIcon.svg';
import ExorterIcon from '../../../images/ExporterIcon.svg';
import FreightForwarderIcon from '../../../images/FreightForwarderIcon.svg';
import CheckCircle from '@mui/icons-material/CheckCircle';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { AccountType } from '../../../../types';
import { MyFormValues } from '../EditAccount';
import UserContext from '../../../context/user';

const containerColor = 'rgba(224, 224, 224, 1)';
const useAccountOptionStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid ${containerColor}`,
      borderRadius: '5px',
      marginRight: theme.spacing(3.75),
      marginBottom: theme.spacing(3.75),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(3.375),
      paddingRight: theme.spacing(2),
      width: '310px',
      height: '230px',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      textAlign: 'left',
    },
    image: {
      marginRight: theme.spacing(4),
    },
    bottomIcon: {
      position: 'absolute',
      bottom: '-12px',
      left: 0,
      right: 0,
      margin: 'auto',
      background: theme.palette.common.white,
      color: containerColor,
    },
  })
);
type AccountOption = {
  accountType: AccountType;
  image: string;
  title: string;
  subTitle: string;
  detail: string;
  color: string;
};
export const AccountOptions = () => {
  const theme = useTheme();
  const accountOptions = useRef<AccountOption[]>([
    {
      accountType: 'importer',
      image: ImporterIcon,
      title: 'Importer',
      subTitle: 'who has containers ready for removal',
      detail: 'List containers you no longer require.',
      color: theme.palette.secondary.main,
    },
    {
      accountType: 'exporter',
      image: ExorterIcon,
      title: 'Exporter',
      subTitle: 'who is looking for a shipping container',
      detail: 'Request to collect clean containers close to your location.',
      color: theme.palette.info.main,
    },
    {
      accountType: 'freight-forwarder',
      image: FreightForwarderIcon,
      title: 'Freight Forwarder',
      subTitle: 'or ...',
      detail: 'Request or add your own containers for others or your own clients use.',
      color: theme.palette.primary.main,
    },
  ]);
  return (
    <>
      <Box mb={1}>
        <Typography variant="subtitle1" color="primary">
          ACCOUNT OPTIONS
        </Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
        Select any that apply to you and your company
      </Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap" my={2}>
        {accountOptions.current.map((accountOption) => (
          <AccountOptionButton key={accountOption.accountType} accountOption={accountOption} />
        ))}
      </Box>
    </>
  );
};

const AccountOptionButton = ({ accountOption }: { accountOption: AccountOption }) => {
  const { values, setFieldValue } = useFormikContext<MyFormValues>();
  const classes = useAccountOptionStyles();
  const selected = values.accountTypes.includes(accountOption.accountType);
  const { user } = useContext(UserContext);
  const disabled = user?.isTeammate;

  const handleClick = () => {
    if (selected) {
      setFieldValue(
        'accountTypes',
        values.accountTypes.filter((at) => at !== accountOption.accountType)
      );
    } else {
      setFieldValue('accountTypes', [...values.accountTypes, accountOption.accountType]);
    }
  };

  return (
    <ButtonBase
      key={accountOption.accountType}
      className={classes.container}
      type="button"
      disabled={disabled}
      onClick={handleClick}
      style={selected ? { borderWidth: '2px', borderColor: accountOption.color } : {}}
    >
      <img
        src={accountOption.image}
        alt={accountOption.title}
        className={classes.image}
        style={selected ? {} : { paddingLeft: '1px' }} // account for border width change so that image an text do not move
      />
      <Box>
        <Typography variant="subtitle1" style={{ color: accountOption.color }}>
          {accountOption.title}
        </Typography>
        <Typography variant="subtitle1" style={{ color: accountOption.color }}>
          {accountOption.subTitle}
        </Typography>
        <Box mt={1.5}>
          <Typography variant="body2" color="textSecondary">
            {accountOption.detail}
          </Typography>
        </Box>
      </Box>
      {selected ? (
        <CheckCircle className={classes.bottomIcon} style={{ color: accountOption.color }} />
      ) : (
        <RadioButtonUnchecked className={classes.bottomIcon} />
      )}
    </ButtonBase>
  );
};
