import { Box, Grid, IconButton, TableCell, TableRow, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import ContainerTable, {
  ContainerFilters,
  containerStyles,
  TableHeader,
} from '../components/Containers/ContainerTable';
import { Formik } from 'formik';
import { Search } from '../components/Search';
import AddContainer from '../components/AddContainer';
import ModalContext from '../context/modal';
import { Container, DrawerType } from '../../types';
import useUtils from '../hooks/useUtils';
import { StatusBadge } from '../components/Containers/StatusBadge';
import { PickUpLocation } from '../components/Containers/PickUpLocation';
import { AvailableFrom } from '../components/Containers/AvailableFrom';
import InfoIcon from '@mui/icons-material/Info';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import useOwnerId from '../hooks/useOwnerId';
import usePrevious from '../hooks/usePrevious';
import useGetClient from '../hooks/useGetClient';
import UserContext from '../context/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3.25),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
    },
    arrowButton: {
      backgroundColor: theme.palette.primary.main,
    },
    arrowIcon: {
      color: theme.palette.common.white,
    },
  })
);

const StackedTableHead = () => (
  <TableRow>
    <TableHeader label="STATUS" sortField="status" />
    <TableHeader sx={{ minWidth: 190 }} label="DATE AVAILABLE" sortField="availableFrom" />
    <TableHeader sx={{ minWidth: 120 }} label="CONTAINER #" sortField="containerCode" />
    <TableHeader sx={{ minWidth: 65 }} label="TYPE" sortField="containerTypeCode" />
    <TableHeader sx={{ minWidth: 130 }} label="SHIPPING LINE" sortField="shippingLineCode" />
    <TableHeader sx={{ minWidth: 220 }} label="PICK UP LOCATION" />
    <TableHeader sx={{ minWidth: 200 }} label="REFERENCE#" sortField="referenceId" />
    <TableHeader
      sx={{ minWidth: 220 }}
      startIcon={<InfoIcon color="primary" sx={{ mr: 1 }} />}
      label="ENVIRONMENTAL SAVINGS"
      colSpan={2}
    />
  </TableRow>
);

const ContainerRow = ({ container }: { container: Container }) => {
  const stackedClasses = useStyles();
  const classes = containerStyles();
  const { setDrawerOpen, setContainer } = useContext(ModalContext);
  const { getContainerTypeName, getShippingLineName, getEnvSavingsText } = useUtils();
  const client = useGetClient(container);

  const editContainer = () => {
    setContainer(container);
    setDrawerOpen('view-container-stacked');
  };

  return (
    <TableRow classes={{ root: classes.rowRoot }}>
      <TableCell>
        <StatusBadge status={container.status} />
      </TableCell>
      <TableCell>
        <AvailableFrom container={container} />
      </TableCell>
      <TableCell>{container.containerCode}</TableCell>
      <TableCell>{getContainerTypeName(container)}</TableCell>
      <TableCell>{getShippingLineName(container)}</TableCell>
      <TableCell sx={{ maxWidth: 220 }}>
        <PickUpLocation client={client} />
      </TableCell>
      <TableCell>{container.referenceId}</TableCell>
      <TableCell>
        <Box whiteSpace="pre-line">{getEnvSavingsText(container, client)}</Box>
      </TableCell>
      <TableCell align="right">
        <IconButton className={stackedClasses.arrowButton} size="medium" onClick={editContainer}>
          <ArrowIcon className={stackedClasses.arrowIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const Stacked = () => {
  const [lastRefeshedAt, setLastRefeshedAt] = useState(new Date().getTime().toString());
  const { drawerOpen } = useContext(ModalContext);
  const prevDrawerOpen: DrawerType = usePrevious(drawerOpen);
  const owner_id = useOwnerId();
  const { user } = useContext(UserContext);
  const [containerFilters, setContainerFilters] = useState<ContainerFilters>({
    userId: owner_id,
    clientId: undefined,
    shippingLineCodes: undefined,
    containerTypeCodes: undefined,
    searchText: undefined,
    statuses: ['requestDeclined', 'available', 'pending', 'assigned', 'timesUp'],
  });
  const classes = useStyles();

  const refreshTable = () => {
    setLastRefeshedAt(new Date().getTime().toString());
  };

  useEffect(() => {
    const prevDrawerOpens = ['view-container-stacked', 'edit-container'];
    if (prevDrawerOpens.includes(prevDrawerOpen || '') && drawerOpen === undefined) {
      // view-container-stacked modal has just been closed, so refresh the table to updated statuses etc
      refreshTable();
    }
  }, [drawerOpen, prevDrawerOpen]);

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="space-between" mb={7}>
        <Grid item>
          <Typography variant="h4" color="primary">
            Stacked By Us
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2.5} alignItems="center">
            <Formik initialValues={containerFilters} onSubmit={setContainerFilters}>
              {({ handleSubmit, values, handleChange }) => {
                const handleSearchChange = (e: any) => {
                  handleChange(e);
                  handleSubmit(e);
                };
                return (
                  <Grid item>
                    <Search name="searchText" placeholder="Search..." onChange={handleSearchChange} />
                  </Grid>
                );
              }}
            </Formik>
            {!(user && user.accountTypes?.length === 1 && user.accountTypes[0] === 'exporter') ? (
              <Grid item>
                <AddContainer onContainerAdd={refreshTable} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <ContainerTable
        lastRefeshedAt={lastRefeshedAt}
        {...containerFilters}
        tableHead={<StackedTableHead />}
        ContainerRow={ContainerRow}
      />
    </Box>
  );
};

export default Stacked;
