import { Box, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ContainerDisputesData } from '../../../../types';
import { styled } from '@mui/material/styles';
import ContainerIcon from '../../../images/ContainerIcon.svg';
import TruckIcon from '../../../images/TruckIcon.svg';
import Chip, { ChipProps } from '@mui/material/Chip';

const StyledChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-colorSecondary': {
    backgroundColor: 'rgba(167, 255, 235, 0.5)',
  },
  '&.MuiChip-colorPrimary': {
    backgroundColor: 'rgba(64, 196, 255, 0.7)',
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(7),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
      background: 'linear-gradient(90.07deg, #0088CF 0%, #00B7B2 100%)',
      borderRadius: '24px',
    },
    title: {
      color: theme.palette.primary.contrastText,
    },
    icon: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
    },
    circle: {
      background: 'rgba(167, 255, 235, 0.5)',
      border: '2px solid #FFFFFF',
      paddingTop: theme.spacing(2.4),
      paddingBottom: theme.spacing(2.4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: '50%',
      display: 'inline-block',
    },
    text: {
      color: theme.palette.primary.contrastText,
    },
  })
);

const ContainerDisputes = ({ containerDisputesData }: { containerDisputesData: ContainerDisputesData }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={600} className={classes.title}>
            Container Disputes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box component="span" className={classes.circle}>
                <img src={ContainerIcon} alt="Logo" className={classes.icon} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <StyledChip size="small" label="Unclean" color="secondary" />
              <Typography pt={1} variant="h4" fontWeight={600} className={classes.text}>
                {containerDisputesData.unclean.total}
              </Typography>
              <Typography variant="body2" fontWeight={400} className={classes.text}>
                Total #
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography pt={1} variant="h4" fontWeight={600} className={classes.text}>
                ${containerDisputesData.unclean.totalFinesPaid}
              </Typography>
              <Typography variant="body2" fontWeight={400} className={classes.text}>
                Total Fines Paid
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box component="span" className={classes.circle}>
                <img src={TruckIcon} alt="Logo" className={classes.icon} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <StyledChip size="small" label="Futile Trip" color="primary" />
              <Typography pt={1} variant="h4" fontWeight={600} className={classes.text}>
                {containerDisputesData.futileTrip.total}
              </Typography>
              <Typography variant="body2" fontWeight={400} className={classes.text}>
                Total #
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography pt={1} variant="h4" fontWeight={600} className={classes.text}>
                ${containerDisputesData.futileTrip.totalFinesPaid}
              </Typography>
              <Typography variant="body2" fontWeight={400} className={classes.text}>
                Total Fines Paid
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContainerDisputes;
