import { Button } from '@mui/material';
// import createStyles from '@mui/styles/createStyles';
// import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import React, { useContext, useState } from 'react';
import ModalContext from '../context/modal';
import UserContext from '../context/user';
import AddContainerDrawer from './AddContainerDrawer';

type Props = {
  onContainerAdd: () => void;
};
const AddContainer = ({ onContainerAdd }: Props) => {
  const { isLoggedIn } = useContext(UserContext);
  const { setDrawerOpen } = useContext(ModalContext);
  const [containerDrawerOpen, setContainerDrawerOpen] = useState(false);

  const handleAddContainer = () => {
    if (isLoggedIn) {
      setContainerDrawerOpen(true);
    } else {
      setDrawerOpen('login');
    }
  };

  const handleContainerDrawerClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick' | 'containersAdded'
  ) => {
    setContainerDrawerOpen(false);
    if (reason === 'containersAdded') {
      onContainerAdd();
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" size="large" startIcon={<AddIcon />} onClick={handleAddContainer}>
        Add your container
      </Button>
      <AddContainerDrawer open={containerDrawerOpen} onClose={handleContainerDrawerClose} />
    </>
  );
};

export default AddContainer;
