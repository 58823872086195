import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import FirebaseContext from '../../context/firebase';
import UserContext from '../../context/user';
import Archived from '../../pages/Archived';
import Assigned from '../../pages/Assigned';
import Landing from '../../pages/Landing';
import Settings from '../../pages/Settings';
import Stacked from '../../pages/Stacked';

const AppRouterClient = () => {
  const { isLoggedIn, user } = useContext(UserContext);
  const { currentUser } = useContext(FirebaseContext);

  return (
    <Switch>
      <Route exact path="/">
        <Landing />
      </Route>
      ,
      {isLoggedIn && user?.emailVerified && currentUser && currentUser.phoneNumber && user.status === 'activate'
        ? [
            <Route key="stacked" path="/stacked">
              <Stacked />
            </Route>,
            <Route key="assigned" path="/assigned">
              <Assigned />
            </Route>,
            <Route key="archived" path="/archived">
              <Archived />
            </Route>,
            <Route key="settings" path="/settings">
              <Settings />
            </Route>,
          ]
        : null}
    </Switch>
  );
};

export default AppRouterClient;
