import React, { useCallback, useEffect, useState } from 'react';
import { Client, Constants, User, HeaderFilter } from '../../types';
import useConstantsApiRoutes from '../hooks/api/useConstantsApiRoutes';
import useClientApiRoutes from '../hooks/api/useClientApiRoutes';
import useOwnerId from '../hooks/useOwnerId';
const UserContext = React.createContext<{
  user?: Partial<User>;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  isFrozen: boolean;
  setIsFrozen: (isFrozen: boolean) => void;
  saveUser: (user: Partial<User>, merge?: boolean) => void;
  constants: Constants;
  clients: Client[];
  setClients: React.Dispatch<React.SetStateAction<Client[]>>;
  headerFilter: HeaderFilter,
  setHeaderFilter: React.Dispatch<React.SetStateAction<HeaderFilter>>;
}>({
  user: undefined,
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn: boolean) => { },
  isFrozen: false,
  setIsFrozen: (isFrozen: boolean) => { },
  saveUser: (user: Partial<User>) => { },
  constants: {
    shippingLines: [],
    containerTypes: [],
    cancellationReasons: [],
    assignedFilterStatuses: [],
    archivedTypes : []
  },
  clients: [],
  setClients: (clients) => { },
  headerFilter: {
    fromDate: "",
    toDate: "",
    searchText: ""
  },
  setHeaderFilter: (headerFilter) => { }
});

export default UserContext;

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<Partial<User> | undefined>(undefined);
  const [constants, setConstants] = useState<Constants>({
    shippingLines: [],
    containerTypes: [],
    cancellationReasons: [],
    assignedFilterStatuses: [],
    archivedTypes : []
  });

  const [headerFilter, setHeaderFilter] = useState<HeaderFilter>({
    fromDate: "",
    toDate: "",
    searchText: ""
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);

  const { getConstants } = useConstantsApiRoutes();
  const { getClients } = useClientApiRoutes();
  const owner_id = useOwnerId(user);

  const loadAppConstants = useCallback(() => {
    getConstants().then(setConstants);
  }, [getConstants]);

  useEffect(() => {
    loadAppConstants();
  }, [loadAppConstants]);

  const [clients, setClients] = useState<Client[]>([]);

  const loadClients = useCallback(() => {
    getClients(0, 100, { user_id: owner_id }).then((response) => {
      if (response && response.clients) {
        setClients([...response.clients]);
      }
    });
  }, [getClients, owner_id]);

  useEffect(() => {
    if (isLoggedIn) {
      loadClients();
    }
  }, [loadClients, isLoggedIn]);

  useEffect(() => {
    if(user && user.status === 'frozen') {
      setIsLoggedIn(false);
      setIsFrozen(true);
    } else {
      setIsFrozen(false);
    }
  }, [user , setIsLoggedIn , setIsFrozen])



  // must use useCallback becasue if saveUser is in a dependency array in another component it will keep re-rendereing!
  const saveUser = useCallback((user: Partial<User>, merge = true) => {
    // do not save sensitve info locally
    delete user.password;
    // delete user.newPassword;

    setUser((curUser) => {
      return { ...curUser, ...user };
    });

    // return mergeCurentUser(user, merge).then((mergedUser) => {
    //     setUser(mergedUser);
    //     return mergedUser;
    // });
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isFrozen,
        setIsFrozen,
        user,
        saveUser,
        constants,
        clients,
        setClients,
        headerFilter,
        setHeaderFilter
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
