import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { useCallback, useContext, useRef } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import React from 'react';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import TimePicker, { TimePickerProps } from '@mui/lab/TimePicker';
import { PropsOf } from '@emotion/react';
import UserContext from '../context/user';
import { Client } from '../../types';

type ThemedTextFieldProps = TextFieldProps & { containerClass?: string } & Required<Pick<TextFieldProps, 'name'>>;
export const ThemedTextField = (props: ThemedTextFieldProps) => {
  const { containerClass, name, InputLabelProps, ...otherProps } = props;
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();
  const error = name ? get(errors, name) : undefined;
  const defaultValue = props.select && props.SelectProps?.multiple ? [] : '';
  return name ? (
    <Box className={containerClass}>
      <TextField
        name={name}
        value={name ? get(values, name, defaultValue) : undefined}
        variant="outlined"
        InputLabelProps={{ shrink: true, ...InputLabelProps }}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        {...otherProps}
      />
      {name ? <ErrorLabel errorText={get(touched, name) && error} /> : null}
    </Box>
  ) : null;
};

export const ThemedSelect = (props: ThemedTextFieldProps & { multiple?: boolean }) => {
  const { multiple = false, SelectProps, ...otherProps } = props;
  const { values, setFieldValue } = useFormikContext();

  const handleDelete = useCallback(
    (event: any, value: any) => {
      setFieldValue(
        props.name,
        get(values, props.name).filter((v: any) => v !== value)
      );
    },
    [props.name, setFieldValue, values]
  );
  const renderChips = useCallback(
    (selected) => (
      <Box display="flex" flexWrap="wrap">
        {(selected as string[]).map((value) => (
          <Chip
            key={value}
            style={{ marginRight: 8, marginBottom: 4 }}
            label={value}
            color="primary"
            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
            onDelete={(e) => handleDelete(e, value)}
          />
        ))}
      </Box>
    ),
    [handleDelete]
  );
  return (
    <ThemedTextField
      fullWidth
      select
      SelectProps={{
        renderValue: multiple ? renderChips : undefined,
        multiple: multiple,
        // MenuProps: { getContentAnchorEl: null, anchorOrigin: { horizontal: 'left', vertical: 'bottom' } },
        MenuProps: { anchorOrigin: { horizontal: 'left', vertical: 'bottom' } },
        ...SelectProps,
      }}
      {...otherProps}
    />
  );
};

export const useGreyOutlinedStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& $notchedOutline': {
        borderWidth: 0,
      },
      '&:hover $notchedOutline': {
        borderWidth: 0,
      },
      '&$focused $notchedOutline': {
        borderWidth: 0,
      },
      backgroundColor: theme.palette.grey[200],
    },
    focused: {},
    notchedOutline: {},
  })
);

type ThemedSelectAsyncProps = Omit<PropsOf<typeof Autocomplete>, 'renderInput'> &
  Required<Pick<TextFieldProps, 'name' | 'variant'>> &
  Pick<TextFieldProps, 'label'> & { useGrey?: boolean };

export const ThemedSelectAsync = React.forwardRef((props: ThemedSelectAsyncProps, ref) => {
  const { placeholder, name, label, variant, useGrey = false, ...otherProps } = props;
  const { handleChange } = useFormikContext();
  const classes = useGreyOutlinedStyles();
  return (
    <Autocomplete
      fullWidth
      disablePortal
      onChange={handleChange}
      renderInput={({ InputProps, ...otherParams }) => (
        <ThemedTextField
          placeholder={placeholder}
          variant={variant}
          InputProps={{ classes: useGrey ? classes : {}, ...InputProps }}
          name={name}
          label={label}
          {...otherParams}
        />
      )}
      ref={ref}
      {...otherProps}
    />
  );
});

export const useSelectChange = ({
  name,
  onChange,
  getValue,
}: Pick<TextFieldProps, 'name'> &
  Pick<PropsOf<typeof Autocomplete>, 'onChange'> & { getValue: (option: any) => any }) => {
  const { handleChange } = useFormikContext();
  const handleSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => {
    const _value = getValue(value);
    handleChange({ target: { name: name, value: _value || '' } });
    if (onChange) {
      onChange(event, _value, reason, details);
    }
  };

  return handleSelectChange;
};

export type SelectAsyncWrapperProps = Omit<ThemedSelectAsyncProps, 'options' | 'variant'> &
  Pick<TextFieldProps, 'variant' | 'label'> & {
    getValue?: (client: Client) => any;
  };
export const ClientSelectAsync = ({
  name,
  variant = 'standard',
  onChange,
  getValue = (c: Client) => c?._id || '',
  getOptionLabel = (c: any) => c?.companyName || '',
  isOptionEqualToValue,
  ...otherProps
}: SelectAsyncWrapperProps) => {
  const { values } = useFormikContext();
  const { clients } = useContext(UserContext);
  const handleSelectChange = useSelectChange({ name, onChange, getValue });
  const defaultValue = useRef(
    clients.find((c) =>
      isOptionEqualToValue ? isOptionEqualToValue(c, get(values, name)) : c._id === get(values, name)
    ) || undefined
  ).current;
  const tooltipText = clients.length === 0 ? 'Add Locations by updating your Company Fineprint or Client Settings' : '';
  return (
    <Tooltip title={tooltipText} arrow>
      <ThemedSelectAsync
        defaultValue={defaultValue}
        variant={variant}
        options={clients}
        onChange={handleSelectChange}
        getOptionLabel={getOptionLabel}
        name={name}
        {...otherProps}
      />
    </Tooltip>
  );
};

export const ShippingLineSelectAsync = ({
  name,
  variant = 'standard',
  onChange,
  multiple,
  ...otherProps
}: SelectAsyncWrapperProps) => {
  const {
    constants: { shippingLines },
  } = useContext(UserContext);
  const { values } = useFormikContext();
  const getOptionLabel = (s: any) => s?.name || '';
  const getValue = (s: any) => (multiple ? s.map((o: any) => o.code) : s?.code || '');
  const handleSelectChange = useSelectChange({ name, onChange, getValue });
  const defaultValue = useRef(shippingLines.find((s) => s.code === get(values, name)) || undefined).current;
  return (
    <ThemedSelectAsync
      defaultValue={defaultValue}
      variant={variant}
      options={shippingLines}
      onChange={handleSelectChange}
      getOptionLabel={getOptionLabel}
      name={name}
      multiple={multiple}
      {...otherProps}
    />
  );
};

export const ContainerTypeSelectAsync = ({
  name,
  variant = 'standard',
  onChange,
  multiple,
  ...otherProps
}: SelectAsyncWrapperProps) => {
  const {
    constants: { containerTypes },
  } = useContext(UserContext);
  const { values } = useFormikContext();
  const getOptionLabel = (s: any) => s?.name || '';
  const getValue = (s: any) => (multiple ? s.map((o: any) => o.code) : s?.code || '');
  const handleSelectChange = useSelectChange({ name, onChange, getValue });
  const defaultValue = useRef(containerTypes.find((c) => c.code === get(values, name)) || undefined).current;
  return (
    <ThemedSelectAsync
      defaultValue={defaultValue}
      variant={variant}
      options={containerTypes}
      onChange={handleSelectChange}
      getOptionLabel={getOptionLabel}
      name={name}
      multiple={multiple}
      {...otherProps}
    />
  );
};

export const CancellationReasonsSelectAsync = ({
  name,
  variant = 'outlined',
  onChange,
  multiple,
  ...otherProps
}: SelectAsyncWrapperProps) => {
  const {
    constants: { cancellationReasons },
  } = useContext(UserContext);
  const { values } = useFormikContext();
  const getOptionLabel = (s: any) => s?.name || '';
  const getValue = (s: any) => (multiple ? s.map((o: any) => o.code) : s?.code || '');
  const handleSelectChange = useSelectChange({ name, onChange, getValue });
  const defaultValue = useRef(cancellationReasons.find((s) => s.code === get(values, name)) || undefined).current;
  return (
    <ThemedSelectAsync
      defaultValue={defaultValue}
      variant={variant}
      options={cancellationReasons}
      onChange={handleSelectChange}
      getOptionLabel={getOptionLabel}
      name={name}
      multiple={multiple}
      {...otherProps}
    />
  );
};

export const AssignedPageStatusSelect = ({
  name,
  variant = 'outlined',
  onChange,
  multiple,
  ...otherProps
}: SelectAsyncWrapperProps) => {
  const {
    constants: { assignedFilterStatuses },
  } = useContext(UserContext);
  const { values } = useFormikContext();
  const getOptionLabel = (s: any) => s?.name || '';
  const getValue = (s: any) => (multiple ? s.map((o: any) => o.code) : s?.code || '');
  const handleSelectChange = useSelectChange({ name, onChange, getValue });
  const defaultValue = useRef(assignedFilterStatuses.find((s) => s.code === get(values, name)) || undefined).current;
  return (
    <ThemedSelectAsync
      defaultValue={defaultValue}
      variant={variant}
      options={assignedFilterStatuses}
      onChange={handleSelectChange}
      getOptionLabel={getOptionLabel}
      name={name}
      multiple={multiple}
      {...otherProps}
    />
  );
};


export const ArchivedTypesSelectAsync = ({
  name,
  variant = 'standard',
  onChange,
  multiple,
  ...otherProps
}: SelectAsyncWrapperProps) => {
  const {
    constants: { archivedTypes },
  } = useContext(UserContext);
  const { values } = useFormikContext();
  const getOptionLabel = (s: any) => s?.name || '';
  const getValue = (s: any) => (multiple ? s.map((o: any) => o.code) : s?.code || '');
  const handleSelectChange = useSelectChange({ name, onChange, getValue });
  const defaultValue = useRef(archivedTypes.find((s) => s.code === get(values, name)) || undefined).current;
  return (
    <ThemedSelectAsync
      defaultValue={defaultValue}
      variant={variant}
      options={archivedTypes}
      onChange={handleSelectChange}
      getOptionLabel={getOptionLabel}
      name={name}
      multiple={multiple}
      {...otherProps}
    />
  );
};

type ThemedDatePickerProps = Omit<DatePickerProps, 'onChange' | 'value' | 'renderInput'> & {
  containerClass?: string;
  textFieldVariant?: TextFieldProps['variant'];
  fullWidth?: TextFieldProps['fullWidth'];
  textFieldClassName?: TextFieldProps['className'];
  textFieldSize?: TextFieldProps['size'];
  onChange?: (e: { target: { name: string; value: string } }) => void;
} & Required<Pick<TextFieldProps, 'name'>>;

export const ThemedDatePicker = ({
  containerClass,
  name,
  textFieldVariant = 'standard',
  fullWidth = false,
  textFieldClassName = undefined,
  textFieldSize = 'medium',
  onChange,
  ...otherProps
}: ThemedDatePickerProps) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();
  const error = name ? get(errors, name) : undefined;
  const getValue = get(values, name, new Date());
  const value = name ? getValue : new Date();
  // const value = name && getValue.length > 0 ? getValue : new Date();

  const handleDateChange = (date: any, keyboardInputValue?: string | undefined) => {
    handleChange({ target: { name: name, value: date } });
    // console.log("name : ", name);
    // console.log("value : ", value);
    // console.log("keyboardInputValue :", keyboardInputValue);
    if (onChange) {
      onChange({ target: { name: name, value: date } });
    }
  };

  return name ? (
    <Box className={containerClass}>
      <DatePicker
        inputFormat="dd/MM/yyyy"
        mask="__/__/____"
        value={value || 'Invalid Date'}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField
            disabled={true}
            variant={textFieldVariant}
            fullWidth={fullWidth}
            name={name}
            className={textFieldClassName}
            onBlur={handleBlur}
            size={textFieldSize}
            {...params}
          />
        )}
        {...otherProps}
      />
      {name ? <ErrorLabel errorText={get(touched, name) && error} /> : null}
    </Box>
  ) : null;
};

type ThemedTimePickerProps = Omit<TimePickerProps, 'onChange' | 'value' | 'renderInput'> & {
  containerClass?: string;
} & Required<Pick<TextFieldProps, 'name'>>;
export const ThemedTimePicker = (props: ThemedTimePickerProps) => {
  const { containerClass, name, ...otherProps } = props;
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();
  const error = name ? get(errors, name) : undefined;
  const getValue = get(values, name, new Date());
  const value = name ? getValue : new Date();

  const handleTimeChange = (date: any, keyboardInputValue?: string | undefined) => {
    handleChange({ target: { name: name, value: date } });
  };

  return name ? (
    <Box className={containerClass}>
      <TimePicker
        value={value}
        onChange={handleTimeChange}
        renderInput={(params) => (
          <TextField disabled={true} variant="standard" name={name} onBlur={handleBlur} {...params} />
        )}
        {...otherProps}
      />
      {name ? <ErrorLabel errorText={get(touched, name) && error} /> : null}
    </Box>
  ) : null;
};

export const ThemedRadioCheck = (props: CheckboxProps & { name: string }) => {
  const { name, ...otherProps } = props;
  const { values, handleChange, handleBlur, errors } = useFormikContext();
  const error = get(errors, name);
  const value = name ? get(values, name, false) : false;
  return (
    <>
      <Checkbox
        icon={<RadioButtonUnchecked />}
        checkedIcon={<RadioButtonChecked />}
        value={value}
        checked={value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        {...otherProps}
      />
      {error ? <ErrorLabel errorText={error} /> : null}
    </>
  );
};

export const ThemedCheckbox = (props: { label: FormControlLabelProps['label']; name: string } & CheckboxProps) => {
  const { label, name, ...otherProps } = props;
  const { values, handleChange, handleBlur, errors } = useFormikContext();
  const error = get(errors, name);
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={name ? get(values, name, false) : false}
            onChange={handleChange}
            onBlur={handleBlur}
            color="default"
            {...otherProps}
          />
        }
        label={label}
      />
      {error ? <ErrorLabel errorText={error} /> : null}
    </>
  );
};

export const ThemedSwitch = ({
  SwitchProps,
  label,
  name,
  ...otherProps
}: {
  name: string;
  label: string;
  SwitchProps?: SwitchProps;
} & Omit<FormControlLabelProps, 'control'>) => {
  const { values, handleChange, errors } = useFormikContext();
  const error = get(errors, name);
  const value = name ? get(values, name, false) : false;
  return (
    <>
      <FormControlLabel
        label={label}
        control={<Switch name={name} value={value} checked={value} onChange={handleChange} {...SwitchProps} />}
        {...otherProps}
      />
      {error ? <ErrorLabel errorText={error} /> : null}
    </>
  );
};

const errorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Oxygen',
      letterSpacing: '0.4px',
      fontWeight: 700,
      fontSize: 12,
    },
  })
);
const ErrorLabel = ({ errorText }: { errorText: string }) => {
  const classes = errorStyles();
  return (
    <Typography className={classes.root} color="error">
      {errorText}
    </Typography>
  );
};
const buttonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.black,
    },
    contained: {
      color: theme.palette.common.white,
      // '&:hover': {
      //   color: theme.palette.common.black,
      // },
    },
  })
);
export const BlackButton = (props: ButtonProps) => {
  const classes = buttonStyles();
  return <Button classes={classes} variant="contained" size="large" type="button" {...props} />;
};

export const SubmitButton = (props: ButtonProps) => {
  const { children, ...otherProps } = props;
  const { dirty, isValid, isSubmitting } = useFormikContext();

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      type="submit"
      disabled={!dirty || !isValid || isSubmitting}
      {...otherProps}
    >
      {isSubmitting ? (
        <Box mr={1} display="flex">
          <CircularProgress size={24} color="inherit" />
        </Box>
      ) : null}
      {children}
    </Button>
  );
};
