// import { createTheme } from '@material-ui/core/styles';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const white = '#FFFFFF';
const infoMain = 'rgba(0, 172, 193, 1)';
const primaryMain = '#0091EA';

declare module '@mui/material/styles' {
  interface Palette {
    snackbar: Palette['primary'];
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    snackbar?: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryMain,
      dark: '#01579B',
      light: '#00B0FF',
      contrastText: white,
    },
    secondary: {
      main: '#00BFA5',
      dark: '#00796B',
      light: '#1DE9B6',
      contrastText: white,
    },
    text: {
      primary: 'rgba(48, 48, 48, 1)',
      secondary: 'rgba(97, 97, 97, 0.9)',
      disabled: 'rgba(170, 170, 170, 0.8)',
      // hint: 'rgba(213, 213, 213, 0.7)',
    },
    info: {
      main: infoMain,
    },
    action: {
      hoverOpacity: 0.08,
      disabledOpacity: 0.12,
    },
    snackbar: {
      main: '#303030',
    },
    // background: {
    //   paper: 'rgba(0, 172, 193, 1)', // will be appiled to eth drawer paper background
    // },
  },
  typography: {
    fontFamily: 'Inter',
    h4: {
      fontSize: 34,
      fontWeight: 600,
      letterSpacing: '0.25px',
      lineHeight: '34px',
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: '23px',
    },
    body2: {
      fontFamily: 'Oxygen',
      letterSpacing: '0.4px',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        // root: {
        //   backgroundColor: grey[100],
        // },
        flexContainer: {
          height: '100%',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            backgroundColor: grey[200],
          },
          paddingLeft: '48px',
          paddingRight: '48px',
        },
        // selected: {
        //   backgroundColor: grey[200],
        // },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          textTransform: 'none',
        },
        // contained: {
        //   backgroundColor: white,
        //   color: infoMain,
        // },
        outlined: {
          backgroundColor: white,
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
          // color: white,
          // borderColor: white,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: primaryMain,
          marginBottom: '24px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  },
});
