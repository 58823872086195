import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Container } from '../../../types';
import useUtils from '../../hooks/useUtils';

export const AvailableFrom = ({ container }: { container: Container }) => {
  const theme = useTheme();
  const { getDaysAvailableText } = useUtils();
  const { datesAvailableText, daysLeftText, daysAvailable } = getDaysAvailableText(container);
  return (
    <Box>
      <Box color={daysAvailable + 1 <= 2 ? theme.palette.error.main : undefined}>
        {datesAvailableText} <br />
        {daysLeftText}
      </Box>
    </Box >
  );
};
