import { Box, Button, Divider, Link, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DescriptionIcon from '@mui/icons-material/Description';
import ModalContext from '../../context/modal';
import { useContext } from 'react';
import SnackBarContext from '../../context/snackbar';
import useUtils from '../../hooks/useUtils';
import startCase from 'lodash/startCase';
import { DisputeAction } from '../Containers/DisputeAction';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import { Action } from '../../../types';

type Props = {};

const ApprovedDisputesModal = (props: Props) => {
  const classes = useStyles();
  const { setDrawerOpen, container, dispute, setContainer } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const { getContainerTypeName, getPenaltyDollarFee } = useUtils();
  const { createContainerDisputeAction } = useContainerApiRoutes();

  const handleRefund = () => {
    setDrawerOpen(undefined);
    const penaltyDollarFee = getPenaltyDollarFee(container);
    const action: Pick<Action, 'actionType' | 'penaltyDollarFee'> = {
      actionType: 'refunded',
      penaltyDollarFee,
    };
    if (container && dispute) {
      createContainerDisputeAction(container?._id, dispute?._id, action).then((response) => {
        setContainer(response);
        showSnack('The claim has been refunded', 'success');
      });
    }
  };

  return container && dispute ? (
    <Box className={classes.root} sx={{ width: '100%' }}>
      <Box mb={2.75}>
        <Typography className={classes.colorInfoMain} lineHeight={1.235} variant="h4">
          Container Dispute
        </Typography>
        <Typography className={classes.colorInfoMain} lineHeight={1.235} variant="h4">
          # {container.containerCode}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography className={classes.body2} variant="body2">
          Type: <strong>{getContainerTypeName(container)}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2" noWrap>
          Reference #<strong>{container.referenceId}</strong>
        </Typography>
        <Typography className={classes.body2} variant="body2" noWrap>
          Dispute Claim: <strong>{startCase(dispute.disputeType)}</strong>
        </Typography>
      </Box>
      <Box mb={3} sx={{ minWidth: '370px' }}>
        <Typography className={classes.colorInfoMain} variant="subtitle1">
          DETAILS
        </Typography>
        <Typography className={classes.body2} variant="body2">
          {dispute.description}
        </Typography>
      </Box>
      {dispute.evidenceOfDispute?.map((evidenceOfDispute, index) => (
        <Link
          href={evidenceOfDispute.url}
          target="_blank"
          display="flex"
          alignItems="center"
          underline="hover"
          key={index}
        >
          <Box className={classes.iconWrapper}>
            <DescriptionIcon className={classes.icon} />
          </Box>
          <Typography variant="body2" color="textSecondary">
            {evidenceOfDispute.origFileName}
          </Typography>
        </Link>
      ))}
      <Button onClick={handleRefund} size="large" variant="outlined" color="info" sx={{ mt: 3 }}>
        Refund Account Holder
      </Button>
      <Divider className={classes.divider} />
      <Typography className={classes.colorInfoMain} variant="subtitle1" mb={2}>
        ACTIONS
      </Typography>
      {dispute.actions?.map((action, index) => (
        <DisputeAction key={index} action={action} />
      ))}
    </Box>
  ) : null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 435,
    },
    body2: {
      fontSize: 16,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
    },
    iconWrapper: {
      backgroundColor: theme.palette.info.main,
      borderRadius: theme.spacing(0.75),
      marginRight: theme.spacing(1),
      display: 'inline-flex',
      padding: theme.spacing(1),
    },
    icon: {
      color: theme.palette.common.white,
    },
    divider: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      backgroundColor: theme.palette.info.main,
    },
    colorInfoMain: {
      color: theme.palette.info.main,
    },
  })
);

export default ApprovedDisputesModal;
