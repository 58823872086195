import { Box, Grid, Theme, Typography, List, ListItem, ListItemIcon } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoughnutChart from '../../../components/super-admin/DoughnutChart';
import { DataComponentchart } from '../../../../types';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
      backgroundColor: 'white',
      borderRadius: '24px',
      boxShadow: '0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    },
    title: {
      paddingLeft: theme.spacing(2),
    },
    listIcon: {
      minWidth: theme.spacing(4),
      fontSize: '8px',
    },
  })
);

const MemberAccount = ({ dataMemberAccount }: { dataMemberAccount: DataComponentchart }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4} md={12} lg={4}>
          <DoughnutChart dataDoughnutCharts={dataMemberAccount.dataDoughnutCharts} total={dataMemberAccount.total} />
        </Grid>
        <Grid item xs={12} sm={8} md={12} lg={8}>
          <Typography variant="h6" fontWeight={600} className={classes.title}>
            Member Accounts
          </Typography>
          <List>
            {dataMemberAccount.dataDoughnutCharts.map((dataDoughnutChart, index) => (
              <ListItem key={index}>
                <ListItemIcon className={classes.listIcon}>
                  <FiberManualRecordIcon fontSize="small" sx={{ color: dataDoughnutChart.bgColor }} />
                </ListItemIcon>
                <Typography fontWeight={700} variant="caption">
                  {dataDoughnutChart.label} ({dataDoughnutChart.valuePercent}%)
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MemberAccount;
