import { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import UserContext from '../../context/user';
import Overview from '../../pages/super-admin/Overview';
import ShippingLine from '../../pages/super-admin/ShippingLine';
import HeaderBottom from './HeaderBottom';

const AppRouterSuperAdmin = () => {
  const { isLoggedIn, user } = useContext(UserContext);
  useEffect(() => {
    document.body.style.backgroundColor = "#f5f5f5"
  },[isLoggedIn])
  return (
    <Switch>
      {(isLoggedIn && user && user.roles?.includes("ADMIN"))
        ? [
          <Route key="super-admin" exact path="/super-admin">
            <HeaderBottom subtext="User Overview" />
            <Overview />
          </Route>,
          <Route key="shipping-lines" path="/super-admin/shipping-lines">
            <HeaderBottom subtext="Shipping Line Activity" />
            <ShippingLine />
          </Route>
        ] : null}
    </Switch>
  );
};

export default AppRouterSuperAdmin;
