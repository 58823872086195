import { Alert, AlertTitle, Box, Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import ModalContext from '../../context/modal';
import UserContext from '../../context/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { marginBottom: theme.spacing(2) },
  })
);

const MobileNotVerified = () => {
  const { user } = useContext(UserContext);
  const { setDrawerOpen } = useContext(ModalContext);
  const classes = useStyles();

  const openVerifyModal = () => {
    setDrawerOpen('verify-mobile');
  };

  return (
    <Box className={classes.container}>
      <Alert severity="info">
        <AlertTitle>Your Mobile Number has not been verified</AlertTitle>
        Please verify you mobile ({user?.phoneNumber}) before proceeding
        <Button onClick={openVerifyModal}>Verify Mobile</Button>
      </Alert>
    </Box>
  );
};

export default MobileNotVerified;
