import {
  Link,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  TextFieldProps,
  Theme,
  LinkProps,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
// import UserContext from '../../context/user';
import AppDrawer from './AppDrawer';
import ModalContext from '../../context/modal';
import LoginModal from './LoginModal';
import EnvSavingModal from './EnvSavingModal';
import SignUpModal from './SignUpModal';
import VerifyMobileModal from './VerifyMobileModal';
import { ThemedTextField } from '../Form';
import { useHistory } from 'react-router-dom';
import ReserveContainerModal from './ReserveContainerModal';
import ViewContainerAssignedModal from './ViewContainerAssignedModal';
import ViewContainerStackedModal from './ViewContainerStackedModal';
import EditContainerModal from './EditContainerModal';
import ClaimFutileTripModal from './ClaimFutileTripModal';
import CancelRequestContainerModal from './CancelRequestContainerModal';
import ConfirmContainerModal from './ConfirmContainerModal';
import ThankYouDisputeModal from './ThankYouDisputeModal';
import ViewContainerDispute from './ViewContainerDispute';
import MoreClientInfoModal from './MoreClientInfoModal';
import ApprovedDisputesModal from './ApprovedDisputesModal';
import PendingDisputesModal from './PendingDisputesModal';
import SignUpConfirmedModal from './SignUpConfirmedModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1.25),
      display: 'block',
    },
    formControlInline: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1.25),
      marginRight: theme.spacing(0.5),
      minWidth: theme.spacing(6),
      display: 'inline-flex',
      flexDirection: 'column',
    },
    fullWidth: {
      width: '100%',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    marginRight: {
      marginRight: theme.spacing(2.25),
    },
    whiteText: {
      color: theme.palette.common.white,
      '&.Mui-checked': {
        color: theme.palette.common.white,
      },
    },
    greyText: {
      color: theme.palette.text.secondary,
      '& + .MuiTypography-root': {
        color: theme.palette.text.primary,
      },
      '&.Mui-checked': {
        color: theme.palette.text.secondary,
      },
    },
    notchedOutline: {
      borderColor: theme.palette.common.white,
    },
    link: {
      color: theme.palette.common.white,
      fontWeight: 600,
      textDecorationColor: theme.palette.common.white,
      cursor: 'pointer',
    },
  })
);

const AppModals = () => {
  // const { isLoggedIn } = useContext(UserContext);
  const { drawerOpen, setDrawerOpen, setContainer } = useContext(ModalContext);
  const history = useHistory();

  const handleOnClose = () => {
    setDrawerOpen(undefined);
    // remove any search params so that we can close the signup drawer if this was a link for a teammate to sign up
    history.replace({ search: undefined });
  };

  const handleContainerModalClose = () => {
    setDrawerOpen(undefined);
    setContainer(undefined);
  };

  return (
    <>
      <AppDrawer modalType="transparent" open={drawerOpen === 'login'} onClose={handleOnClose}>
        <EnvSavingModal />
      </AppDrawer>
      <AppDrawer drawerOpen={drawerOpen} open={drawerOpen === 'login'} onClose={handleOnClose}>
        <LoginModal />
      </AppDrawer>
      <AppDrawer open={drawerOpen === 'signup'} onClose={handleOnClose}>
        <SignUpModal />
      </AppDrawer>
      <AppDrawer open={drawerOpen === 'signup-confirmed'} onClose={handleOnClose}>
        <SignUpConfirmedModal />
      </AppDrawer>
      <AppDrawer open={drawerOpen === 'verify-mobile'} onClose={handleOnClose}>
        <VerifyMobileModal />
      </AppDrawer>
      <AppDrawer
        modalType="secondaryMain"
        open={drawerOpen === 'reserve-container'}
        onClose={handleContainerModalClose}
      >
        <ReserveContainerModal />
      </AppDrawer>
      <AppDrawer
        modalType="secondaryMain"
        open={drawerOpen === 'claim-futile-trip'}
        onClose={handleContainerModalClose}
      >
        <ClaimFutileTripModal />
      </AppDrawer>
      <AppDrawer
        modalType="secondaryMain"
        open={drawerOpen === 'cancel-request-container'}
        onClose={handleContainerModalClose}
      >
        <CancelRequestContainerModal />
      </AppDrawer>
      <AppDrawer
        modalType="secondaryMain"
        open={drawerOpen === 'confirm-container'}
        onClose={handleContainerModalClose}
      >
        <ConfirmContainerModal />
      </AppDrawer>
      <AppDrawer
        modalType="secondaryMain"
        open={drawerOpen === 'thank-you-dispute'}
        onClose={handleContainerModalClose}
      >
        <ThankYouDisputeModal />
      </AppDrawer>
      <AppDrawer
        modalType="primaryLight"
        open={drawerOpen === 'view-container-stacked'}
        onClose={handleContainerModalClose}
      >
        <ViewContainerStackedModal />
      </AppDrawer>
      <AppDrawer
        modalType="primaryLight"
        open={drawerOpen === 'view-container-assigned'}
        onClose={handleContainerModalClose}
      >
        <ViewContainerAssignedModal />
      </AppDrawer>
      <AppDrawer modalType="primaryLight" open={drawerOpen === 'edit-container'} onClose={handleContainerModalClose}>
        <EditContainerModal />
      </AppDrawer>
      <AppDrawer modalType="infoMainInverted" open={drawerOpen === 'view-dispute'} onClose={handleContainerModalClose}>
        <ViewContainerDispute />
      </AppDrawer>
      <AppDrawer modalType="primaryLight" open={drawerOpen === 'more-client-info'} onClose={handleContainerModalClose}>
        <MoreClientInfoModal />
      </AppDrawer>
      <AppDrawer modalType="primaryLight" open={drawerOpen === 'approved-disputes'} onClose={handleContainerModalClose}>
        <ApprovedDisputesModal />
      </AppDrawer>
      <AppDrawer modalType="primaryLight" open={drawerOpen === 'pending-disputes'} onClose={handleContainerModalClose}>
        <PendingDisputesModal />
      </AppDrawer>
    </>
  );
};

export default AppModals;

export const WhiteInput = (props: { maxLength?: number } & TextFieldProps & Required<Pick<TextFieldProps, 'name'>>) => {
  const classes = useStyles();
  const { name, maxLength, inputProps, InputProps, variant, ...otherProps } = props;
  // use the ch unit to limit the width to the number of characters
  const inputStyle = { width: maxLength ? `${maxLength}ch` : undefined };
  return (
    <ThemedTextField
      name={name}
      variant="outlined"
      containerClass={maxLength ? classes.formControlInline : ''}
      classes={{ root: maxLength ? classes.formControlInline : classes.formControl }}
      inputProps={{ maxLength, style: inputStyle, ...inputProps }}
      InputProps={{
        classes: {
          root: `${classes.whiteText} ${maxLength ? classes.justifyCenter : classes.fullWidth}`,
          notchedOutline: classes.notchedOutline,
        },
        ...InputProps,
      }}
      InputLabelProps={{ classes: { root: classes.whiteText } }}
      {...otherProps}
    />
  );
};

export const GreyCheckbox = (props: { label: FormControlLabelProps['label'] } & CheckboxProps) => {
  const classes = useStyles();
  const { label, ...otherProps } = props;
  return <FormControlLabel control={<Checkbox classes={{ root: classes.greyText }} {...otherProps} />} label={label} />;
};
export const WhiteCheckbox = (props: { label: FormControlLabelProps['label'] } & CheckboxProps) => {
  const classes = useStyles();
  const { label, ...otherProps } = props;
  return (
    <FormControlLabel
      control={<Checkbox color="default" classes={{ root: classes.whiteText }} {...otherProps} />}
      label={label}
    />
  );
};

// const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
//   <Link innerRef={ref as any} {...props} />
// ));

export const WhiteLink = (props: LinkProps) => {
  const { children, ...otherProps } = props;
  const classes = useStyles();
  return (
    <Link underline="always" classes={{ root: classes.link }} {...otherProps}>
      {children}
    </Link>
  );
};
