import { useCallback, useContext } from 'react';
import { Action, Container, PointSchema } from '../../../types';
import { SortOption } from '../../components/Containers/ContainerTable';
import UserContext from '../../context/user';
import { REACT_APP_API_BASE_URL } from '../../env';
import { useProtectedRoute } from './useProtectedRoute';
import { usePublicRoute } from './usePublicRoute';

export default function useContainerApiRoutes() {
  const protectedRoute = useProtectedRoute();
  const publicRoute = usePublicRoute();
  const { isLoggedIn } = useContext(UserContext);

  const getContainer = useCallback(
    async (id: string): Promise<Container> => {
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/containers/${id}`,
      });
      return response;
    },
    [protectedRoute]
  );

  const updateContainer = useCallback(
    async (container: Partial<Container>): Promise<Container> => {
      const response = await protectedRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/containers/${container._id}`,
        data: container,
      });
      return response;
    },
    [protectedRoute]
  );

  const getContainers = useCallback(
    async (
      currentPage = 0,
      itemsPerPage = 10,
      where: {
        shippingLineCode?: { $in: string[] };
        containerTypeCode?: { $in: string[] };
        client_id?: string;
        status?: { $in: string[] };
        assigned_to_user_id?: string;
        'client.user_id'?: string;
        availableTo?: { $gt: string };
        $or?: any[];
      } = {},
      sort: SortOption[] = [],
      near?: PointSchema
    ): Promise<{ containers: Container[]; totalItems: number } | undefined> => {
      const route = isLoggedIn ? protectedRoute : publicRoute;
      const response = await route({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/containers`,
        params: { itemsPerPage, currentPage, where, sort, near },
      });
      return response;
    },
    [isLoggedIn, protectedRoute, publicRoute]
  );

  const createContainers = useCallback(
    async (containers: Partial<Container>[]): Promise<{ containers: Container[] } | undefined> => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/containers`,
        data: { containers },
      });
      return response;
    },
    [protectedRoute]
  );

  const getContainerForExport = useCallback(
    async (where: { availableFrom: { $gte: string }; availableTo: { $lte: string } }): Promise<Container[]> => {
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/containers`,
        params: { where, isUnlimited: true },
      });
      return response.containers;
    },
    [protectedRoute]
  );

  const createClaimFutileTrip = useCallback(
    async (id: string, futileTrip: any) => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/containers/${id}/claim-futile-trips`,
        data: futileTrip,
      });
      return response;
    },
    [protectedRoute]
  );

  const confirmContainer = useCallback(
    async (container: Partial<Container>): Promise<Container> => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/containers/${container._id}/confirm`,
        data: container,
      });
      return response;
    },
    [protectedRoute]
  );

  const createReserveRequest = useCallback(
    async (id: string, container: Partial<Container>): Promise<Container> => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/containers/${id}/request`,
        data: container,
      });
      return response;
    },
    [protectedRoute]
  );

  const deleteContainer = useCallback(
    async (id: string): Promise<Container> => {
      const response = await protectedRoute({
        method: 'delete',
        url: `${REACT_APP_API_BASE_URL}/containers/${id}`,
      });
      return response;
    },
    [protectedRoute]
  );

  const cancelRequestContainer = useCallback(
    async (id: string, cancelRequest: any) => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/containers/${id}/cancel-requests`,
        data: cancelRequest,
      });
      return response;
    },
    [protectedRoute]
  );

  const createContainerDisputeAction = useCallback(
    async (id: string, disputeId: string, action: Pick<Action, 'actionType' | 'penaltyDollarFee'>) => {
      const response = await protectedRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/containers/${id}/disputes/${disputeId}/actions`,
        data: { action },
      });
      return response;
    },
    [protectedRoute]
  );

  const getContainerDisputes = useCallback(
    async (userId: string, where: any) => {
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/containers/disputes/${userId}`,
        params: { ...where },
      });
      return response;
    },
    [protectedRoute]
  );

  const getAllContainers = useCallback(
    async (where?: any): Promise<Container[]> => {
      let params: any = { isUnlimited: true };
      if (where) {
        params = { ...params, where };
      }
      const response = await protectedRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/containers`,
        params,
      });
      return response.containers;
    },
    [protectedRoute]
  );

  const emergencyCancellationContainer = useCallback(
    async (container: Container): Promise<Container> => {
      const response = await protectedRoute({
        method: 'patch',
        url: `${REACT_APP_API_BASE_URL}/containers/${container._id}/emergency-cancellations`,
      });
      return response;
    },
    [protectedRoute]
  );

  return {
    createContainers,
    getContainers,
    getContainer,
    updateContainer,
    getContainerForExport,
    createClaimFutileTrip,
    createReserveRequest,
    confirmContainer,
    deleteContainer,
    cancelRequestContainer,
    getAllContainers,
    createContainerDisputeAction,
    getContainerDisputes,
    emergencyCancellationContainer,
  };
}
