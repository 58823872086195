import { Box, Grid, Theme, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useState, useRef, useContext } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { SubmitButton, ThemedDatePicker } from '../../../components/Form';
import useContainerApiRoutes from '../../../hooks/api/useContainerApiRoutes';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import UserContext from '../../../context/user';
import startCase from 'lodash/startCase';
import useUtils from '../../../hooks/useUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
  })
);

export interface MyFormValues {
  dateFrom: string;
  dateTo: string;
}

export const ReportData = () => {
  const classes = useStyles();
  const { getContainerForExport } = useContainerApiRoutes();
  const { user } = useContext(UserContext);
  const { getShippingLineName } = useUtils();
  const [dateValues, setDateValues] = useState({
    dateFrom: '',
    dateTo: '',
  });
  const headers = [
    { label: 'Container Code', key: 'containerCode' },
    { label: 'Shipping Line', key: 'shippingLine' },
    { label: 'Container Type Code', key: 'containerTypeCode' },
    { label: 'Reference #', key: 'referenceId' },
    { label: 'Available From', key: 'availableFrom' },
    { label: 'Available To', key: 'availableTo' },
    { label: 'Status', key: 'status' },
    { label: 'Assigned To Me', key: 'assignedToMe' },
    { label: 'Clean And Acessible', key: 'cleanAndAcessible' },
    { label: 'In Stack', key: 'inStack' }
  ];
  const [dataContainer, setDataContainer] = useState<any[]>([]);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const downloadFileCSV = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    setDateValues(values);
    const dateFrom = new Date(new Date(values.dateFrom).setHours(0, 0, 0, 0));
    const dateTo = new Date(new Date(values.dateTo).setHours(24, 0, 0, 0));
    const where = {
      availableFrom: { $gte: dateFrom.toString() },
      availableTo: { $lte: dateTo.toString() },
    };
    getContainerForExport(where)
      .then((containers) => {
        if (containers.length > 0) {
          const containersFormatting = containers.map((container) => {
            return {
              ...container,
              shippingLine: container.shippingLineCode ? getShippingLineName(container) : 'N/A',
              status: container.status ? startCase(container.status) : 'N/A',
              availableFrom: container.availableFrom ? format(new Date(container.availableFrom), 'd MMM yyyy') : 'N/A',
              availableTo: container.availableFrom ? format(new Date(container.availableFrom), 'd MMM yyyy') : 'N/A',
              assignedToMe: container.assigned_to_user_id === user?._id ? 'Yes' : 'No',
              cleanAndAcessible: container.cleanAndAcessible ? 'Yes' : 'No',
              inStack: container.inStack ? 'Yes' : 'No'
            };
          });
          setDataContainer(containersFormatting);
          csvLinkRef?.current?.link.click();
        }
        setSubmitting(false);
      })
      .catch(() => setSubmitting(false));
  };

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues | undefined> = {};
    if (!values.dateFrom) {
      errors.dateFrom = 'Required';
    }
    if (!values.dateTo) {
      errors.dateTo = 'Required';
    }
    if (values.dateFrom && values.dateTo) {
      const dateFrom = new Date(new Date(values.dateFrom).setHours(0, 0, 0, 0));
      const dateTo = new Date(new Date(values.dateTo).setHours(24, 0, 0, 0));
      if (dateTo < dateFrom) {
        errors.dateTo = 'The value of "To Date" must be greater than the value of "From Date."';
      }
    }
    return errors;
  };

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography variant="subtitle1" color="primary">
          REPORT DATA
        </Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
        Select a date range to download your report.
      </Typography>
      <Formik
        validate={validate}
        initialValues={{ dateFrom: `${new Date()}`, dateTo: `${new Date()}` }}
        onSubmit={downloadFileCSV}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} mb={4}>
              <Grid container spacing={4} className={classes.container}>
                <Grid item xs={4}>
                  <ThemedDatePicker
                    textFieldVariant="outlined"
                    fullWidth
                    name="dateFrom"
                    label="Date From"
                  ></ThemedDatePicker>
                </Grid>
                <Grid item xs={4}>
                  <ThemedDatePicker
                    textFieldVariant="outlined"
                    fullWidth
                    name="dateTo"
                    label="Date To"
                  ></ThemedDatePicker>
                </Grid>
              </Grid>
            </Box>
            <SubmitButton startIcon={<FileDownloadOutlinedIcon />}>Download CSV</SubmitButton>
            {dateValues.dateFrom && dateValues.dateTo ? <CSVLink
              data={dataContainer}
              headers={headers}
              filename={`ContainersReport-${user?.companyInformation?.companyName}-from-${format(new Date(dateValues.dateFrom), 'd-MM-yyyy')}-to-${format(new Date(dateValues.dateTo), 'd-MM-yyyy')}`}
              className="hidden"
              ref={csvLinkRef}
              target="_blank"
            /> : null}
          </form>
        )}
      </Formik>
    </Box>
  );
};
