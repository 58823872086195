import { Box, Button, Grid } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ModalContext, { ConfirmationServiceContext } from '../../context/modal';
import { ContainerInfo } from '../Containers/ContainerInfo';
import { InfoField } from '../Containers/InfoField';
import useContainerApiRoutes from '../../hooks/api/useContainerApiRoutes';
import useClientApiRoutes from '../../hooks/api/useClientApiRoutes';
import { Client, ContainerStatus } from '../../../types';
import { format } from 'date-fns';
import { BlackButton } from '../Form';
import SnackBarContext from '../../context/snackbar';
import useUtils from '../../hooks/useUtils';

type Props = {};

const ViewContainerStackedModal = (props: Props) => {
  const { setDrawerOpen, container, setContainer } = useContext(ModalContext);
  const { getContainer, deleteContainer, emergencyCancellationContainer } = useContainerApiRoutes();
  const { getClient } = useClientApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const [client, setClient] = useState<Client | undefined>();
  const { getContainerTypeName } = useUtils();

  const editableStatuses = useRef<ContainerStatus[]>(['available', 'assigned', 'internalUse']).current;
  const cancellableStatuses = useRef<ContainerStatus[]>(['assigned', 'internalUse']).current;

  useEffect(() => {
    if (container?._id) {
      getContainer(container._id).then((returnedContainer) => {
        setContainer(returnedContainer);
      });
    }
  }, [container?._id, getContainer, setContainer]);

  useEffect(() => {
    if (container?.client_id) {
      getClient(container.client_id).then((client) => {
        setClient(client);
      });
    }
  }, [container?.client_id, getClient]);

  const editContainer = () => {
    setDrawerOpen('edit-container');
  };

  const handleRemoveFromStack = async () => {
    if (container?._id) {
      const res = await showConfirmationModal({
        title: `Remove Container ${getContainerTypeName(container)} #${container.containerCode}?`,
        contentText:
          'Do you wish to permanently delete this container from your Stack?\nPlease remember to arrange dehire of this container if applicable',
      });

      if (res && res.confirmed === true) {
        await deleteContainer(container._id);
        showSnack('Container Removed!', 'success');
        setDrawerOpen(undefined);
        setOpenId(undefined);
      }
    }
  };

  const handleCancellation = async () => {
    if (container?._id) {
      const res = await showConfirmationModal({
        title: 'Cancel Container?',
        contentText: 'Do you wish to cancel this container and move it into the Archived tab?',
      });
      if (res && res.confirmed === true) {
        await emergencyCancellationContainer(container);
        showSnack('Container Cancelled!', 'success');
        setDrawerOpen(undefined);
        setOpenId(undefined);
      }
    }
  };

  return container ? (
    <Box>
      <ContainerInfo container={container} client={client} />
      <Grid container spacing={4} color="unset">
        <Grid item xs={4}>
          <InfoField heading="AVAILABLE FROM" detail={format(new Date(container.availableTimeFrom), 'h:mm a')} />
        </Grid>
        <Grid item xs={4}>
          {client ? <InfoField heading="LOCATION FROM" detail={client.address} /> : null}
        </Grid>
        <Grid item xs={4}>
          {container.goingTo && container.status === 'internalUse' ? (
            <InfoField heading="LOCATION TO" detail={container.goingTo?.address} />
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={4} color="unset">
        <Grid item xs={12}>
          {client ? <InfoField heading="BASIC SITE INSTRUCTIONS" detail={client.additionalSiteInstructions} /> : null}
        </Grid>
      </Grid>
      <Grid container spacing={4} color="unset">
        <Grid item xs={12}>
          {client ? (
            <InfoField
              heading="MIMIMUM HEATH AND SAFETY REQUIREMENTS"
              detail={client.minimumHealthSafetyRequirements}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={4} color="unset">
        <Grid item xs={12}>
          <Button
            size="large"
            disabled={!editableStatuses.includes(container.status)}
            variant="contained"
            color="primary"
            sx={{ mr: 2 }}
            onClick={editContainer}
          >
            Edit Container
          </Button>
          <Button
            size="large"
            disabled={container.status !== 'available'}
            variant="outlined"
            color="primary"
            sx={{ mr: 2 }}
            onClick={handleRemoveFromStack}
          >
            Remove From Stack
          </Button>
          <BlackButton disabled={!cancellableStatuses.includes(container.status)} onClick={handleCancellation}>
            Emergency Cancellation
          </BlackButton>
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

export default ViewContainerStackedModal;
